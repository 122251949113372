import {combineReducers} from '@reduxjs/toolkit'
import analysisReducer from 'features/analysis/analysisSlice'
import edfSlice from 'features/analysis/edfSlice'
import hrvNormSlice from 'features/analysis/hrvNormSlice'
import hrvReducer from 'features/analysis/hrvSlice'
import hrvSummaryReducer from 'features/analysis/hrvSummarySlice'
import eegIndividualReducer from 'features/analysis/eegIndividualSlice'
import eegMciReducer from 'features/analysis/eegMciSlice'
import individualNormReducer from 'features/analysis/eegNormSlice'
import individualSummaryReducer from 'features/analysis/eegSummarySlice'
import authReducer from 'features/auth/authSlice'
import commentReducer from 'features/comment/commentSlice'
import alertReducer from 'features/modal/alertSlice'
import refDialogSlice from 'features/modal/refDialogSlice'
import modalReducer from 'features/modal/modalSlice'
import noticeReducer from 'features/notice/noticeSlice'
import patientActivitySliceReducer from 'features/patient/patientActivitySlice'
import patientReducer from 'features/patient/patientSearchSlice'
import patientEntityReducer from 'features/patient/patientSlice'
import paymentReducer from 'features/payment/paymentSlice'
import pointReducer from 'features/point/pointSlice'
import qanReducer from 'features/qna/qnaSlice'
import salesAllOrgReducer from 'features/sales/salesAllOrgSlice'
import salesReducer from 'features/sales/salesSlice'
import treatmentSearchReducer from 'features/treatment/treatmentSearchSlice'
import orgReducer from 'features/org/orgSlice'
import orgDepartmentReducer from 'features/org/orgDepartmentSlice'
import orgPresetsReducer from 'features/org/orgPresetSlice'
import orgUserReducer from 'features/org/orgUserSlice'
import orgUserListReducer from 'features/org/orgUserListSlice'
import confirmReducer from 'features/modal/confirmSlice'
import noticeConfirmReducer from 'features/modal/noticeConfirmSlice'
import paymentProductsReducer from 'features/paymentProducts/paymentProductsSlice'
import emergencyNoticeReducer from 'features/sales/salesNoticeSlice'
import therapyEntityReducer from 'features/therapy/therapySlice'
import therapySearchReducer from 'features/therapy/therapySearchSlice'
import therapySelectReducer from 'features/therapy/therapySelectSlice'
import orgPurchaseSliceReducer from 'features/org/orgPurchaseSlice'
import nirCareLedReducer from 'features/analysis/nirCareLedSlice'
import healthCareReducer from 'features/healthCare/healthCareSlice'
import invoiceListReducer from 'features/invoice/InvoiceListSlice'
import invoiceInquiryReducer from 'features/invoice/invoiceInquirySlice'
import invoiceReducer from 'features/invoice/invoiceSlice'
import invoiceViewReducer from 'features/invoice/invoiceViewSlice'
import orgRegisterReducer from 'features/invoice/orgRegisterSlice'
import patientWhiteListReducer from 'features/patient/patientWhiteListSlice'
import healthCareWhiteListReducer from 'features/healthCare/healthCareWhiteListSlice'

const appReducer = combineReducers({
  modal: modalReducer,
  auth: authReducer,
  patientSearch: patientReducer,
  patient: patientEntityReducer,
  patientActivity: patientActivitySliceReducer,
  treatmentSearch: treatmentSearchReducer,
  comment: commentReducer,
  notice: noticeReducer,
  qna: qanReducer,
  sales: salesReducer,
  salesEmergencyNotice: emergencyNoticeReducer,
  payment: paymentReducer,
  salesAllOrg: salesAllOrgReducer,
  point: pointReducer,
  analysis: analysisReducer,
  individualNorm: individualNormReducer,
  individualSummary: individualSummaryReducer,
  eegIndividual: eegIndividualReducer,
  eegMci: eegMciReducer,
  hrv: hrvReducer,
  hrvNorm: hrvNormSlice,
  hrvSummary: hrvSummaryReducer,
  edf: edfSlice,
  refDialog: refDialogSlice,
  org: orgReducer,
  orgDepartment: orgDepartmentReducer,
  orgPresets: orgPresetsReducer,
  orgUser: orgUserReducer,
  orgUserList: orgUserListReducer,
  confirm: confirmReducer,
  noticeConfirm: noticeConfirmReducer,
  alert: alertReducer,
  paymentProducts: paymentProductsReducer,
  therapySearch: therapySearchReducer,
  therapySelect: therapySelectReducer,
  therapy: therapyEntityReducer,
  orgPurchase: orgPurchaseSliceReducer,
  nirCare: nirCareLedReducer,
  healthCare: healthCareReducer,
  invoiceList: invoiceListReducer,
  invoiceInquiry: invoiceInquiryReducer,
  invoice: invoiceReducer,
  invoiceView: invoiceViewReducer,
  orgRegister: orgRegisterReducer,
  patientWhiteList: patientWhiteListReducer,
  healthCareWhiteList: healthCareWhiteListReducer,
})

export default appReducer

// const rootReducer: Reducer<CombinedState<RootState>> = (
//   state: RootState | undefined,
//   action: AnyAction,
// ) => {
//   // 로그아웃 하면 스토어를 초기화 한다
//   if (action.type === logoutSuccess.type) {
//     console.info('[Reducer][Root!] -> logout success')
//     return appReducer(undefined, action)
//   }
//
//   // TODO: AuthRefresh Error 발생하면 리다이렉트 시켜야 한다.
//   if (action.type.endsWith('Error')) {
//     const error = action.payload
//     console.log('rootReducer:', error)
//     if (error instanceof AuthError) {
//       console.log('refresh Error:', error)
//     }
//   }
//
//   return appReducer(state, action)
// }
//
// export default rootReducer
