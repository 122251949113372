// @ts-nocheck
/* eslint-disable */
import Collapse from '@material-ui/core/Collapse'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import SettingsIcon from '@material-ui/icons/Settings'
import {Dispatch} from '@reduxjs/toolkit'
import brainlogo from 'assets/img/brainlogo.svg'
import avatar from 'assets/img/default-avatar.png'
import sidebarStyle from 'assets/tss/material-dashboard-pro-react/components/sidebarStyle'
import cx from 'classnames'
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks'
import {MY_INFO_DIALOG_TYPE} from 'constants/DialogConstant'
import {
  default as RouteConstant,
  default as RouteMap,
} from 'constants/RouteConstant'
import {logoutAction} from 'features/auth/authSlice'
import {
  openOrgDetailInfoDialog,
  openUserReadDialog,
} from 'features/modal/modalSlice'
import {isAdmin} from 'Authority'
import PerfectScrollbar from 'perfect-scrollbar'
import React, {useEffect} from 'react'
import {TFunction, withTranslation, useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {NavLink, RouteComponentProps, withRouter} from 'react-router-dom'
import {RootState} from 'store'

let ps

interface SidebarWrapperProps {
  className?: string
  user?: Record<string, any>
  headerLinks?: Record<string, any>
  links: Record<string, any>
}

interface SidebarProps extends RouteComponentProps {
  classes: Record<string, any>[]
  bgColor?: 'white' | 'black' | 'blue'
  color?: 'white' | 'red' | 'orange' | 'green' | 'blue' | 'purple' | 'rose'
  logo?: string
  logoText?: string
  image?: string
  routes?: MenuItemV2[]
  miniActive?: boolean
  open?: boolean
  handleDrawerToggle: Function
  // HOC withTranslation
  t: TFunction
  i18n: i18n
  // HOC connect mapStateToProps
  user: User
  subUser: User
  org: Organization
  // HOC connect mapDispatchToProps
  logout: VoidFunction
  openOrgInfo: (uid: string) => void
  openMyInfo: (uid: string) => void
}

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component<SidebarWrapperProps> {
  sidebarWrapper = React.createRef()

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      })
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy()
    }
  }

  render() {
    const {className, user, headerLinks, links} = this.props
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {user}
        {headerLinks}
        {links}
      </div>
    )
  }
}

class Sidebar extends React.Component<SidebarProps> {
  mainPanel = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(props.routes),
    }
  }

  componentDidUpdate(
    prevProps: Readonly<SidebarProps>,
    prevState: Readonly<{}>,
    snapshot?: any,
  ) {
    if (this.props.routes !== prevProps.routes) {
      this.setState({
        ...this.getCollapseStates(this.props.routes),
      })
    }
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {}
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.children),
          ...this.getCollapseStates(prop.children),
          ...initialState,
        }
      }
      return null
    })

    return initialState
  }

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (
        routes[i].collapse &&
        this.getCollapseInitialState(routes[i].children)
      ) {
        return true
      }
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true
      }
    }
    return false
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    // CYM: 결제 관리 active 예외 처리
    const isSubRouteMatch =
      routeName != '/' && window.location.pathname.includes(routeName)
    return window.location.pathname === routeName || isSubRouteMatch
      ? 'active'
      : ''
    // return window.location.href.indexOf(routeName) > -1 ? 'active' : ''
  }

  openCollapse(collapse) {
    const st = {}
    st[collapse] = !this.state[collapse]
    this.setState(st)
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    const {classes, color} = this.props

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null
      }
      if (prop.collapse) {
        const st = {}
        st[prop.state] = !this.state[prop.state]
        const navLinkClasses = `${classes.itemLink} ${cx({
          [` ${classes.collapseActive}`]: this.getCollapseInitialState(
            prop.children,
          ),
        })}`
        const itemText = `${classes.itemText} ${cx({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
        })}`
        const collapseItemText = `${classes.collapseItemText} ${cx({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
        })}`
        const itemIcon = classes.itemIcon
        const caret = classes.caret
        const collapseItemMini = classes.collapseItemMini

        return (
          <div>
            <ListItem
              key={key}
              className={cx(
                {[classes.item]: prop.icon !== undefined},
                {[classes.collapseItem]: prop.icon === undefined},
              )}
            >
              {prop.title === RouteMap.COMMUNITY.title && (
                <Typography align='center' className={classes.subTitle}>
                  {this.props.t('IEdition')}
                </Typography>
              )}
              {prop.title === RouteMap.MY.title && (
                <Typography align='center' className={classes.subTitle}>
                  {this.props.t('IManagement')}
                </Typography>
              )}
              <NavLink
                to='#'
                className={navLinkClasses}
                onClick={(e) => {
                  e.preventDefault()
                  this.setState(st)
                }}
              >
                {prop.icon !== undefined ? (
                  typeof prop.icon === 'string' ? (
                    <Icon className={itemIcon}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon className={itemIcon} />
                  )
                ) : (
                  <span className={collapseItemMini}>{prop.mini}</span>
                )}
                <ListItemText
                  primary={this.props.t(prop.title)}
                  secondary={
                    <b
                      className={`${caret} ${
                        this.state[prop.state] ? classes.caretActive : ''
                      }`}
                    />
                  }
                  disableTypography
                  className={cx(
                    {[itemText]: prop.icon !== undefined},
                    {[collapseItemText]: prop.icon === undefined},
                  )}
                />
              </NavLink>
              <Collapse in={this.state[prop.state]} unmountOnExit>
                <List className={`${classes.list} ${classes.collapseList}`}>
                  {this.createLinks(prop.children)}
                </List>
              </Collapse>
            </ListItem>
          </div>
        )
      }

      const innerNavLinkClasses = `${classes.collapseItemLink} ${cx({
        [` ${classes[color]}`]: this.activeRoute(prop.path),
      })}`
      const collapseItemMini = classes.collapseItemMini
      const navLinkClasses = `${classes.itemLink} ${cx({
        [` ${classes[color]}`]: this.activeRoute(prop.path),
      })}`
      const itemText = `${classes.itemText} ${cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
      })}`
      const collapseItemText = `${classes.collapseItemText} ${cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
      })}`
      const itemIcon = `${classes.itemIcon} ${cx({
        [` ${classes[color]}`]: this.activeRoute(prop.path),
      })}`

      return (
        <ListItem
          key={key}
          className={cx(
            {[classes.item]: prop.icon !== undefined},
            {[classes.collapseItem]: prop.icon === undefined},
          )}
        >
          {prop.path === RouteMap.HOME.path && (
            <Typography align='center' className={classes.subTitle}>
              {this.props.t('IAnalysis')}
            </Typography>
          )}
          <NavLink
            onClick={(event) => {
              /*
               * 사이드 메뉴에서 다이얼로그를 띄우고 싶은 메뉴가 있으면,
               * SideMenuConstant 에 메뉴 추가한 후
               * 여기에 unique title 을 기반으로 클릭 리스너 추가.
               * 기본적으로 링크 형태로 되어 있기 때문에 event.preventDefault 함수 호출을 추가해 주어야 함.
               */

              if (prop.title === RouteConstant.ORG_INFO.title) {
                this.props.openOrgInfo(this.props.user.uid)
                event.preventDefault()
                return
              }
              if (prop.title === RouteConstant.MY_INFO.title) {
                this.props.openMyInfo(this.props.user.uid)
                event.preventDefault()
                return
              }
              if (prop.title === RouteMap.LOGOUT.title) {
                this.props.logout(this.props.user.uid)
              }
            }}
            to={prop.path}
            className={cx(
              {[navLinkClasses]: prop.icon !== undefined},
              {[innerNavLinkClasses]: prop.icon === undefined},
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === 'string' ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>{prop.mini}</span>
            )}

            <ListItemText
              primary={this.props.t(prop.title)}
              disableTypography
              className={cx(
                {[itemText]: prop.icon !== undefined},
                {[collapseItemText]: prop.icon === undefined},
              )}
            />
          </NavLink>
        </ListItem>
      )
    })
  }

  render() {
    const {classes, logo, image, logoText, routes, bgColor} = this.props
    const itemText = `${classes.itemText} ${cx({
      [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
    })}`
    const collapseItemText = `${classes.collapseItemText} ${cx({
      [classes.collapseItemTextMini]:
        this.props.miniActive && this.state.miniActive,
    })}`
    const userWrapperClass = `${classes.user} ${cx({
      [classes.whiteAfter]: bgColor === 'white',
    })}`
    const caret = classes.caret
    const collapseItemMini = classes.collapseItemMini
    const photo = classes.photo

    const user = (
      <div className={userWrapperClass}>
        {/* TODO: 사용자 프로필 적용하기 */}
        <div className={classes.userInfoContainer}>
          <div className={classes.userAccountChange}>
            <NavLink
              to={this.props.routes}
              onClick={(e) => {
                this.props.openMyInfo(this.props.user.uid)
                event.preventDefault()
                return
              }}
            >
              <IconButton
                color='inherit'
                aria-label='add an alarm'
                className={classes.accountChengIcon}
              >
                <SettingsIcon />
              </IconButton>
            </NavLink>
          </div>
          <div className={classes.userInfoWrap}>
            <div className={photo}>
              <img
                src={this.props.user?.profile ?? avatar}
                className={classes.avatarImg}
                alt='avatar image'
              />
            </div>
            <div className={classes.userInfoTextWrap}>
              <Typography align='center' className={classes.userOrganization}>
                {this.props.org?.orgName ?? 'Empty organization name'}
              </Typography>
              {isAdmin(this.props.user.authority) && (
                <Typography align='center' className={classes.userName}>
                  {this.props.t('IDashboardAdminister')}
                </Typography>
              )}
              {!isAdmin(this.props.user.authority) &&
                this.props.i18n.language !== 'ko' &&
                this.props.i18n.language !== 'ja' && (
                  <Typography align='center' className={classes.userName}>
                    {this.props.user?.firstName ?? 'Empty user name'}
                    {this.props.user?.lastName ?? 'Empty user name'}
                  </Typography>
                )}
              {!isAdmin(this.props.user.authority) &&
                (this.props.i18n.language === 'ko' ||
                  this.props.i18n.language === 'ja') && (
                  <Typography align='center' className={classes.userName}>
                    {this.props.user?.lastName ?? 'Empty user name'}
                    {this.props.user?.firstName ?? 'Empty user name'}
                  </Typography>
                )}
              {/* {this.props.org?.promotionYn === 'N' && (
                <Typography
                  align='center'
                  className={classes.accountInformation}
                >
                  {this.props.org?.point ?? '-'} Point
                </Typography>
              )} */}
              {this.props.org?.promotionYn === 'Y' && (
                <Typography
                  align='center'
                  className={classes.accountInformation}
                >
                  {this.props.org?.point ?? '-'} Count
                </Typography>
              )}
              {this.props.org?.promotionYn === 'Y' && (
                <Typography
                  align='center'
                  className={classes.accountInformation}
                >
                  Promotion Date
                  {this.props.org?.startDate ?? '-'} ~
                  {this.props.org?.endDate ?? '-'}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
    )
    const links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    )

    const logoNormal = `${classes.logoNormal} ${cx({
      [classes.logoNormalSidebarMini]:
        this.props.miniActive && this.state.miniActive,
    })}`
    const logoMini = classes.logoMini
    const logoClasses = `${classes.logo} ${cx({
      [classes.whiteAfter]: bgColor === 'white',
    })}`
    const brand = (
      <div className={logoClasses}>
        <a href='/' className={logoNormal} rel='noreferrer'>
          {/* {logoText} */}

          <img
            src={brainlogo}
            alt='logo'
            className={classes.img}
            style={{
              color: 'white',
              fill: 'white',
              width: 110,
            }}
          />
        </a>
      </div>
    )
    const drawerPaper = `${classes.drawerPaper} ${cx({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
    })}`
    const sidebarWrapper = `${classes.sidebarWrapper} ${cx({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
    })}`
    return (
      <div className={classes.topMainWrap}>
        <div ref={this.mainPanel}>
          <Hidden mdUp implementation='css'>
            <Drawer
              variant='temporary'
              anchor={'left'}
              open={this.props.open}
              classes={{
                paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`,
              }}
              onClose={this.props.handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {brand}
              <SidebarWrapper
                className={sidebarWrapper}
                user={user}
                headerLinks={<AdminNavbarLinks />}
                links={links}
              />

              {image !== undefined ? (
                <div
                  className={classes.background}
                  style={{backgroundImage: `url(${image})`}}
                />
              ) : null}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation='css'>
            <Drawer
              onMouseOver={() => this.setState({miniActive: false})}
              onMouseOut={() => this.setState({miniActive: true})}
              anchor={'left'}
              variant='permanent'
              open
              classes={{
                paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`,
              }}
            >
              {brand}
              <SidebarWrapper
                className={sidebarWrapper}
                user={user}
                links={links}
              />
              {image !== undefined ? (
                <div
                  className={classes.background}
                  style={{backgroundImage: `url(${image})`}}
                />
              ) : null}
            </Drawer>
          </Hidden>
        </div>
      </div>
    )
  }
}

const StyledSidebar = withStyles(sidebarStyle)(Sidebar)

const TranslatedSidebar = withTranslation()(StyledSidebar)

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
  subUser: state.auth.subUser,
  org: state.org.organization,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: (uid: string) => dispatch(logoutAction(uid)),
  /**
   * 병원 정보 다이얼로그
   *
   * @param uid 서브 계정의 uid
   */
  openOrgInfo: (uid: string) =>
    dispatch(openOrgDetailInfoDialog({dialogType: 'ORG_INFO', uid})),

  /**
   * 내 정보 다이얼로그
   *
   * @param uid 서브 계정의 uid
   */
  openMyInfo: (uid: string) =>
    dispatch(openUserReadDialog({dialogType: MY_INFO_DIALOG_TYPE, uid})),
})

const ConnectedSidebar = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TranslatedSidebar)

export default withRouter(ConnectedSidebar)
