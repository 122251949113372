import {AnalysisResultItem, IcaAnalysisResultIndicator} from 'api/analysisApi'
import {HorizontalImageGraph} from 'components/molcules/Report/RawData/HorizontalImageGraph'
import ReportHeader from 'components/Report/ReportHeader'
import React from 'react'
import LeftChannel from 'assets/img/LeftChannel.jpeg'

interface RawDataProps {
  indicator: IcaAnalysisResultIndicator
  items: AnalysisResultItem[]
  yAxisImage?: string
}

function IcaRawData({
  indicator,
  items,
  yAxisImage = LeftChannel,
}: RawDataProps) {
  const [item] = items

  return (
    <div>
      <ReportHeader
        index={indicator.codeSort}
        title={indicator.codeName}
        description={indicator.titleDescription}
      />
      <HorizontalImageGraph
        xAxisImg={`${item.rawDataPath}?t=${Date.now()}`}
        yAxisImg={yAxisImage}
      />
    </div>
  )
}

export default IcaRawData
