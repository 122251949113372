import TranslationSelect from 'components/atoms/TranslationSelect'
import ReportFetcher from 'pages/Analysis/EEGIndividual/Report/ReportFetcher'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useParams} from 'react-router-dom'
import {number, object, SchemaOf, string} from 'yup'
import styled from 'styled-components'
import {Spacing} from 'components/common/useBaseStyle'
import {fetchIndividualEegStatus} from 'api/analysisApi'
import {navigate} from 'AppHistory'
import RouteConstant from 'constants/RouteConstant'
import useLanguageReport from 'hooks/useLanguageReport'
import IcaReportFetcher, {
  IcaProcess,
} from './ReAnalysis/IcaReport/IcaReportFetcher'
import TsrReportFetcher from './ReAnalysis/TsrReport/TsrReportFetcher'

interface PathParams {
  id: number
  version: string
}

const QueryItemScheme: SchemaOf<PathParams> = object({
  id: number()
    .typeError('IInvalidRequest')
    .required('IInvalidRequest')
    .min(1, 'IInvalidRequest'),
  version: string().required('IInvalidRequest'),
})

export const ReportWrap = styled.div`
  display: flex;
  flex-direction: column;
`
export const ReportTranslationSelectWrap = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: ${Spacing.report.reportTranslationMargin}px;
`
export const ReportFetcherWrap = styled.div`
  display: flex;
  flex-direction: row;
`

export const ReportCustomPadding = styled.div`
  padding: ${Spacing.report.reportCustomPadding}px;
`

function EEGReportPage() {
  const {t} = useTranslation()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [pathParams, setPathParams] = useState<PathParams>()
  const [icaProcessing, setIcaProcessing] = useState<IcaProcess>(
    'ica_process_success',
  )

  const {id} = useParams<{
    id?: string | undefined
  }>()
  function decodeHtmlEntity(str: string) {
    const textarea = document.createElement('textarea')
    textarea.innerHTML = str
    const result = textarea.value
    textarea.remove()
    return result
  }

  const location = useLocation()
  const param = decodeHtmlEntity(`${location.search}${location.hash}`)
  const clientNameIndex = param.indexOf('clientName=') + 'clientName='.length
  const endIndex = param.indexOf('&', clientNameIndex)
  const clientNameEncode =
    endIndex === -1
      ? param.substring(clientNameIndex)
      : param.substring(clientNameIndex, endIndex)
  const clientName = decodeURIComponent(clientNameEncode)

  const versionIndex = param.indexOf('version=') + 'version='.length
  const version = param.substring(versionIndex)

  const fetchStatus = async () => {
    try {
      if (pathParams) {
        const {data: status} = await fetchIndividualEegStatus(pathParams.id)
        switch (status) {
          case 'SUCCESS':
            setIcaProcessing('ica_process_success')
            break
          case 'ICA':
            setIcaProcessing('ica_process_ica')
            break
          case 'TIME_REJECTION':
            setIcaProcessing('ica_process_tsr')
            break
          case 'PROCESSING':
            setIcaProcessing('ica_process_loading')
            break
          case 'ERROR':
            setIcaProcessing('ica_process_failure')
            break
          default:
            break
        }
      }
    } catch (err) {
      setIcaProcessing('ica_process_failure')
    }
  }

  useEffect(() => {
    setLoading(true)
    QueryItemScheme.validate({
      id,
      version,
    })
      .then((value) => setPathParams(value))
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false))
  }, [id, version])

  useEffect(() => {
    fetchStatus()
  }, [pathParams])

  useEffect(() => {
    if (icaProcessing === 'ica_process_failure') {
      navigate(RouteConstant.ANALYSIS_EEG_19.path)
    }
  }, [icaProcessing])

  useLanguageReport()

  if (loading) return <div>Loading...</div>
  if (!loading && error !== undefined) return <div>{t(error)}</div>
  if (!loading && pathParams !== undefined)
    return (
      <ReportWrap>
        <ReportTranslationSelectWrap>
          <TranslationSelect />
        </ReportTranslationSelectWrap>
        <ReportFetcherWrap>
          <ReportCustomPadding />
          {icaProcessing === 'ica_process_success' && (
            <ReportFetcher
              id={pathParams.id}
              version={pathParams.version}
              clientName={clientName}
            />
          )}
          {icaProcessing === 'ica_process_ica' && (
            <IcaReportFetcher id={pathParams.id} />
          )}
          {icaProcessing === 'ica_process_tsr' && (
            <TsrReportFetcher id={pathParams.id} />
          )}
          <ReportCustomPadding />
        </ReportFetcherWrap>
        <ReportCustomPadding />
      </ReportWrap>
    )
  return null
}
export default EEGReportPage
