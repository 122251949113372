import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {AnalysisResultIndicator, AnalysisResultItem} from 'api/analysisApi'
import {Colors} from 'components/common/useBaseStyle'
import {HorizontalImageGraph} from 'components/molcules/Report/RawData/HorizontalImageGraph'
import ReportHeader from 'components/Report/ReportHeader'
import {Tab, TabPanel, Tabs} from 'components/Tab'
import React, {useEffect, useMemo} from 'react'
import styled from 'styled-components'
import {useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

interface RawDataCleanedProps {
  items: AnalysisResultItem[]
}

function RawDataCleaned({items}: RawDataCleanedProps) {
  const [tabIndex, setTabIndex] = React.useState(0)
  const [cleaned, badEpoch] = items

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTabChanged = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <div>
      <Tabs
        value={tabIndex}
        onChange={handleTabChanged}
        indicatorColor='secondary'
        textColor='secondary'
      >
        {cleaned && <Tab label='Cleaned data' />}
        {badEpoch && <Tab label='Cleaned data with bad epoch' />}
      </Tabs>

      {cleaned && (
        <TabPanel value={tabIndex} index={0}>
          <HorizontalImageGraph xAxisImg={cleaned.imgPath} />
        </TabPanel>
      )}
      {badEpoch && (
        <TabPanel value={tabIndex} index={cleaned ? 1 : 0}>
          <HorizontalImageGraph xAxisImg={badEpoch.imgPath} />
        </TabPanel>
      )}
    </div>
  )
}

const StyledRawData = styled.div`
  & .MuiAccordion-root {
    border: 1px solid ${Colors.border};
  }

  & .Image {
    padding: 30px;
    & img {
      margin: 0 auto;
      vertical-align: middle;
    }
  }

  & img {
    width: 100%;
    height: auto;
    margin: 0;
    border: 0;
  }
`

interface RawDataProps {
  indicator: AnalysisResultIndicator
  items: AnalysisResultItem[]
}

function RawData({indicator, items}: RawDataProps) {
  const location = useLocation()
  const {t} = useTranslation()

  useEffect(() => {
    console.log(location.pathname.includes('norm'), 'location')
  }, [])

  const webItems = useMemo(
    () => items.filter((item) => item.imgType === 'WEB'),
    [items],
  )

  const improveSignal = useMemo(() => {
    const listChannel = items.filter(
      (item) =>
        item.analysisType === '1' &&
        item.psdScaleOrder === 3 &&
        item.cleanedDataName,
    )
    let channel = ''
    if (!listChannel.length) return ''
    if (listChannel.length === 1) {
      channel = t('ISignalRestorationResultOne', {
        channel: listChannel[0].cleanedDataName,
      })
    } else {
      const channels = listChannel.slice(0, listChannel.length - 2)
      channel = t('ISignalRestorationResult', {
        channels: channels.map((item) => item.cleanedDataName).join(', '),
        channel: listChannel[listChannel.length - 1].cleanedDataName,
      })
    }
    return channel
  }, [items])

  if (webItems.length === 0) return null
  const [full, cleaned, badEpoch, reliability] = webItems

  return (
    <StyledRawData>
      <ReportHeader
        index={indicator.codeSort}
        title={indicator.codeName}
        description={indicator.titleDescription}
      />

      {full && <HorizontalImageGraph xAxisImg={full.imgPath} />}
      {(cleaned || badEpoch) && <RawDataCleaned items={[cleaned, badEpoch]} />}
      <div>{improveSignal.length ? <p>{improveSignal}</p> : ''}</div>
      {reliability && (
        <div className='Image'>
          <Accordion defaultExpanded={false} elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              * Split-half & Test-retest reliability
            </AccordionSummary>
            <AccordionDetails>
              <img
                src={reliability.imgPath}
                alt='reliability about cleaned data'
              />
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </StyledRawData>
  )
}

export default RawData
