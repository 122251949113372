import Dialog from '@material-ui/core/Dialog'
import {
  AnalysisAvailable,
  AnalysisCheckApi,
  checkEegMciApi,
  checkEegNormApi,
  checkEegSummaryNormalApi,
  checkEegSummaryPremiumApi,
} from 'api/analysisApi'
import Button from '@material-ui/core/Button'
import {ConfirmType} from 'features/analysis/analysisSlice'
import {useAnalysisConfirm} from 'features/analysis/useAnalysisConfirm'
import {isNotDefined} from 'helpers/commonHelper'
import MultiIcaCheckTable from 'pages/Analysis/EEGIndividual/ReAnalysis/MultiIcaCheckTable'
import {StyledIcaDialog} from 'pages/Analysis/EEGIndividual/ReAnalysis/StyledIcaDialog'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Header from 'components/Dialog/AnalysisConfirmDialog/Header'
import styled from 'styled-components'

interface RequestPending {
  state: 'pending'
}

interface RequestError {
  state: 'failure'
  error: string
}

interface RequestSuccess<T = Record<string, any>> {
  state: 'success'
  data: T
}

type RequestState<T = Record<string, any>> =
  | RequestPending
  | RequestError
  | RequestSuccess<T>

const BottomButtonWrap = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 25px;
  gap: 28px;
`

const isRequestPending = (state: RequestState): state is RequestPending =>
  'state' in state && state.state === 'pending'
const isRequestError = (state: RequestState): state is RequestError =>
  'state' in state && state.state === 'failure'
const isRequestSuccess = (state: RequestState): state is RequestSuccess =>
  'state' in state && state.state === 'success'

function CheckResult({detailDto}: AnalysisAvailable) {
  console.log(`dto : ${JSON.stringify(detailDto)}`)
  return (
    <div>
      <MultiIcaCheckTable
        items={detailDto.map((d) => ({
          id:
            d.requestIds != null && typeof d.requestIds !== 'undefined'
              ? d.requestIds[0]
              : d.requestId /* FIXME: 배열형태로 수정해야함 */,
          status: d.status,
          description: d.description,
        }))}
      />
    </div>
  )
}

const noop: AnalysisCheckApi = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      return resolve({
        message: '',
        success: true,
        data: {
          totalPoint: 0,
          detailDto: [],
        },
        code: 200,
      })
    }, 0)
  })
}

const getFetcher = (type?: ConfirmType): AnalysisCheckApi => {
  switch (type) {
    case 'eeg_norm':
      return checkEegNormApi
    case 'eeg_mci':
      return checkEegMciApi
    case 'eeg_summary':
      return checkEegSummaryNormalApi
    case 'eeg_summary_premium':
      return checkEegSummaryPremiumApi
    default:
      return noop
  }
}

function PremiumConfirmDialog() {
  const {t} = useTranslation()
  const [requestState, setRequestState] = useState<
    RequestState<AnalysisAvailable>
  >({state: 'pending'})

  const {
    type,
    isOpen: open,
    selected,
    onConfirm,
    onCancel,
  } = useAnalysisConfirm()

  const fetchCheckApi = async (fetcher: AnalysisCheckApi) => {
    if (selected.length === 0) {
      // 오류 팝업을 띄워야 할까?
      return
    }

    setRequestState({state: 'pending'})
    try {
      const resp = await fetcher(selected)
      setRequestState({state: 'success', data: resp.data})
    } catch (err) {
      setRequestState({state: 'failure', error: err.message})
    }
  }

  useEffect(() => {
    if (open) {
      if (isNotDefined(type)) {
        // 오류 팝업을 띄워야 할까?
        return
      }

      const fetcher = getFetcher(type)
      fetchCheckApi(fetcher)
    }
  }, [open, type])

  const handleConfirm = () => {
    if (isRequestSuccess(requestState)) {
      const {data} = requestState
      onConfirm(
        data.detailDto
          .filter((detail) => detail.status === 'SUCCESS')
          .map((detail) => {
            // SUMMARY REPORT CHECK
            if (detail.requestIds) {
              return detail.requestIds[0]
            }
            // aMCI REPORT CHECK
            if (detail.requestId) {
              return detail.requestId
            }
            // Lint CHECK
            return -1
          }),
      )
    }
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <StyledIcaDialog>
        <div>
          <Header />
        </div>

        <div className='content'>
          {isRequestPending(requestState) && <div>{t('ILoading')}</div>}
          {isRequestSuccess(requestState) && (
            <CheckResult {...requestState.data} />
          )}
          {isRequestError(requestState) && <div>{requestState.error}</div>}
        </div>
        <BottomButtonWrap>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={onCancel}
            style={{width: 222, backgroundColor: '#e9ecef', color: '#4b4b4b'}}
          >
            {t('ICancel')}
          </Button>

          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={handleConfirm}
            style={{width: 222}}
            // disabled={isRequestPending(requestState) || hasNoAvailable}
          >
            {t('IOk')}
          </Button>
        </BottomButtonWrap>
      </StyledIcaDialog>
    </Dialog>
  )
}

export default PremiumConfirmDialog
