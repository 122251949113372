import React from 'react'
import {useTranslation} from 'react-i18next'
import {getPublicFile} from 'helpers/envHelper'
import styled from 'styled-components'
import WrapElement from 'pages/HealthCenter/Report/Elements/WrapElement'
import useHealthCenterBrainFunctionMappingStyles from 'pages/HealthCenter/Report/BrainFunctionMapping/Style'
import TableFiguresByPart from 'pages/HealthCenter/Report/BrainFunctionMapping/Elements/tableFiguresByPart'

export const PageWrap = styled.div`
  background: White;
`

export const PageContent = styled.div`
  width: 1160px;
`

export const CardContent = styled.div`
  font-size: 14px;
`

export const FlexBox = styled.div`
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
`
const getHealthReportReportImage = getPublicFile('images/health_center_report')

interface BrainFunctionMappingProps {
  agingLobeScore: Record<string, any>
  agingRoiScore: Record<string, any>
}

const getNumber = (value: number | string) => {
  if (!Number.isNaN(Number(value))) return Math.floor(Number(value))
  return 0
}

const scoreToString = (score: number) => {
  if (Math.floor(score) < 30) {
    return 'IScoreBrainMapping_Lowering'
  }
  if (Math.floor(score) > 70) {
    return 'IScoreBrainMapping_Exaltation'
  }

  return 'IScoreBrainMapping_Balance'
}

const scoreToClass = (score: number) => {
  if (Math.floor(score) < 30) {
    return 'LoweringActive'
  }
  if (Math.floor(score) > 70) {
    return 'ExaltationActive'
  }

  return 'Balanced'
}

const scoreSign = (score: number | string) => {
  const parsedScore =
    typeof score === 'string' ? Number.parseFloat(score) : score

  if (parsedScore < 30) {
    return getHealthReportReportImage('down.svg')
  }
  if (parsedScore > 70) {
    return getHealthReportReportImage('up.svg')
  }

  return getHealthReportReportImage('arrow.svg')
}

const Label = ({score}: {score: number | string}) => {
  const {t} = useTranslation()
  const classes = useHealthCenterBrainFunctionMappingStyles()
  const string = scoreToString(getNumber(score))
  const className = scoreToClass(getNumber(score))
  return (
    <div className={`${classes.labelScore} ${className}`}>
      {t(string)}
      <img src={scoreSign(score)} alt='icon' />
    </div>
  )
}

const Score = ({score}: {score: number}) => {
  const classes = useHealthCenterBrainFunctionMappingStyles()
  return (
    <div className={classes.bottom16}>
      <div className={classes.scoreNumber}>{score}점</div>
      <Label score={score} />
    </div>
  )
}

function BrainFunctionMappingPage({
  agingLobeScore,
  agingRoiScore,
}: BrainFunctionMappingProps) {
  const {t} = useTranslation()
  const classes = useHealthCenterBrainFunctionMappingStyles()

  const bodyTable = [
    {
      data: [
        {
          header: [
            {
              key: 'F1.',
              content: 'Frontal Pole',
              note: t('INoteFrontalPole'),
            },
          ],
          image: getHealthReportReportImage('brainF1.png'),
        },
        [
          t('IPlanningForAction'),
          t('IEvaluationOfOwn'),
          t('IAbilityToMaintain'),
        ],
        [t('IDifficultiesInPlanning'), t('ITendencyToRepeat')],
        [getNumber(agingRoiScore?.Frontal_Pole_LH)],
        [getNumber(agingRoiScore?.Frontal_Pole_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'F2.',
              content: 'Pars Opercularis',
              note: t('IParsNoteF2'),
            },
            {
              key: 'F3.',
              content: 'Pars Orbitalis',
              note: t('IParsNoteF3'),
            },
            {
              key: 'F4.',
              content: 'Pars Triangularis',
              note: t('IParsNoteF4'),
            },
          ],
          image: getHealthReportReportImage('brainF2.png'),
        },
        [t('IParsF3'), t('IParsF4'), t('IParsF2')],
        [t('IFunctionDeclines'), t('IDifficultToExpress')],
        [
          getNumber(agingRoiScore?.Pars_Opercularis_LH),
          getNumber(agingRoiScore?.Pars_Orbitalis_LH),
          getNumber(agingRoiScore?.Pars_Triangularis_LH),
        ],
        [
          getNumber(agingRoiScore?.Pars_Opercularis_RH),
          getNumber(agingRoiScore?.Pars_Orbitalis_RH),
          getNumber(agingRoiScore?.Pars_Triangularis_RH),
        ],
      ],
      background: '#f1f1f1',
    },
    {
      data: [
        {
          header: [
            {
              key: 'F5.',
              content: 'Rostral Middle Frontal',
              note: t('IRostralNoteF5'),
            },
          ],
          image: getHealthReportReportImage('brainF5.png'),
        },
        [
          t('IResponsibleForWorking'),
          t('ILeftHemisphereResponsible'),
          t('IRightHemisphereResponsible'),
        ],
        [
          t('IDecreasedConcentration'),
          t('IPoorWorking'),
          t('ILeftSideFunctionDeclines'),
          t('IRightSideFunctionDeclines'),
        ],
        [getNumber(agingRoiScore?.Rostral_Middle_Frontal_LH)],
        [getNumber(agingRoiScore?.Rostral_Middle_Frontal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'F6.',
              content: 'Caudal Middle Frontal',
              note: t('ICaudalMiddle'),
            },
          ],
          image: getHealthReportReportImage('brainF6.png'),
        },
        [
          t('IResponsibleForWorking'),
          t('ILeftHemisphereResponsible'),
          t('IRightHemisphereResponsible'),
        ],
        [
          t('IDecreasedConcentration'),
          t('IPoorWorking'),
          t('ILeftSideFunctionDeclines'),
          t('IRightSideFunctionDeclines'),
        ],
        [getNumber(agingRoiScore?.Caudal_Middle_Frontal_LH)],
        [getNumber(agingRoiScore?.Caudal_Middle_Frontal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'F7.',
              content: 'Superior Frontal',
              note: t('IFrontalNoteF7'),
            },
          ],
          image: getHealthReportReportImage('brainF7.png'),
        },
        [
          t('IResForHigher'),
          t('IRoleSimulating'),
          t('ILeftHemispherePlays'),
          t('IRightHemispherePlays'),
        ],
        [
          t('ILeftSideFunction'),
          t('IRightSideFunction'),
          t('IDifficultyPerforming'),
        ],
        [getNumber(agingRoiScore?.Superior_Frontal_LH)],
        [getNumber(agingRoiScore?.Superior_Frontal_RH)],
      ],
      background: '#f1f1f1',
    },
  ]

  const bodyTable2 = [
    {
      data: [
        {
          header: [
            {
              key: 'F8.',
              content: 'Medial Orbitofrontal',
              note: t('IMediaFrontalF8'),
            },
          ],
          image: getHealthReportReportImage('brainF8.png'),
        },
        [t('IActiveRewardF8'), t('IResponsibleSelfF8')],
        [t('IInsensitivityRewardF8'), t('IDifficultyMakingF8')],
        [getNumber(agingRoiScore?.Medial_Orbitofrontal_LH)],
        [getNumber(agingRoiScore?.Medial_Orbitofrontal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'F9.',
              content: 'Lateral Orbitofrontal',
              note: t('ILateralF9'),
            },
          ],
          image: getHealthReportReportImage('brainF9.png'),
        },
        [t('ISituationsF9'), t('IResponsibleF9')],
        [t('IInsensitivityF9'), t('IControllingF9')],
        [getNumber(agingRoiScore?.Lateral_Orbitofrontal_LH)],
        [getNumber(agingRoiScore?.Lateral_Orbitofrontal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'F10.',
              content: 'Precentral',
              note: t('IAnteriorF10'),
            },
          ],
          image: getHealthReportReportImage('brainF10.png'),
        },
        [t('IRelatedF10')],
        [t('IDecreasedF10')],
        [getNumber(agingRoiScore?.Precentral_LH)],
        [getNumber(agingRoiScore?.Precentral_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'F11.',
              content: 'Paracentral',
              note: t('IAtrialF11'),
            },
          ],
          image: getHealthReportReportImage('brainF11.png'),
        },
        [t('IResponsibleF11'), t('IPlanningF11')],
        [t('IProblemsF11'), t('IMovingF11')],
        [getNumber(agingRoiScore?.Paracentral_LH)],
        [getNumber(agingRoiScore?.Paracentral_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'F12.',
              content: 'Rostral Anterior Cingulate',
              note: t('IAnteriorF12'),
            },
          ],
          image: getHealthReportReportImage('brainF12.png'),
        },
        [t('IRegionsF12'), t('IConnectedF12')],
        [t('IRegulatingF12'), t('IStressF12')],
        [getNumber(agingRoiScore?.Rostral_Anterior_Cingulate_LH)],
        [getNumber(agingRoiScore?.Rostral_Anterior_Cingulate_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'F13.',
              content: 'Caudal Anterior Cingulate',
              note: t('IAnteriorF13'),
            },
          ],
          image: getHealthReportReportImage('brainF13.png'),
        },
        [t('ICognitiveF13'), t('IResponsibleF13'), t('IConnectedF13')],
        [t('IDecreasedF13'), t('IRegulatingF13')],
        [getNumber(agingRoiScore?.Caudal_Anterior_Cingulate_LH)],
        [getNumber(agingRoiScore?.Caudal_Anterior_Cingulate_RH)],
      ],
      background: 'White',
    },
  ]

  const bodyTable3 = [
    {
      data: [
        {
          header: [
            {
              key: 'T1.',
              content: 'Temporal Pole',
              note: t('ITemporalPoleT1'),
            },
          ],
          image: getHealthReportReportImage('brainT1.png'),
        },
        [
          t('IRegionActivatedT1'),
          t('ILeftHemisphereT1'),
          t('IRightHemisphereT1'),
        ],
        [
          t('IDifficultiesT1'),
          t('IDifficultProcessT1'),
          t('IDifficultRespondT1'),
        ],
        [getNumber(agingRoiScore?.Temporal_Pole_LH)],
        [getNumber(agingRoiScore?.Temporal_Pole_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T2.',
              content: 'Superior Temporal',
              note: t('ISuperiorT2'),
            },
          ],
          image: getHealthReportReportImage('brainT2.png'),
        },
        [t('IResponsibleT2')],
        [t('IPossibilityT2'), t('IImpossibleT2'), t('IDifficultT2')],
        [getNumber(agingRoiScore?.Superior_Temporal_LH)],
        [getNumber(agingRoiScore?.Superior_Temporal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T3.',
              content: 'Middle Temporal',
              note: t('IMiddleT3'),
            },
          ],
          image: getHealthReportReportImage('brainT3.png'),
        },
        [t('IAreaT3'), t('ILeftHemisphereT3'), t('IRightHemisphereT3')],
        [t('IVisualT3'), t('IDifficultT3'), t('IRightSidedT3')],
        [getNumber(agingRoiScore?.Middle_Temporal_LH)],
        [getNumber(agingRoiScore?.Middle_Temporal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T4.',
              content: 'Inferior Temporal',
              note: t('IInferiorT4'),
            },
          ],
          image: getHealthReportReportImage('brainT4.png'),
        },
        [t('IInvolvedT4'), t('ILeftHemisphereT4'), t('IRightHemisphereT4')],
        [t('ILeftSidedT4'), t('IRightSidedT4')],
        [getNumber(agingRoiScore?.Inferior_Temporal_LH)],
        [getNumber(agingRoiScore?.Inferior_Temporal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T5.',
              content: 'Transverse Temporal',
              note: t('ILateralT5'),
            },
          ],
          image: getHealthReportReportImage('brainT5.png'),
        },
        [t('IAuditoryT5')],
        [t('IDifficultyT5'), t('IPossibleT5')],
        [getNumber(agingRoiScore?.Transverse_Temporal_LH)],
        [getNumber(agingRoiScore?.Transverse_Temporal_RH)],
      ],
      background: 'White',
    },
  ]

  const bodyTable4 = [
    {
      data: [
        {
          header: [
            {
              key: 'T6.',
              content: 'Banks of Superior Temporal Sulcus',
              note: t('ISuperiorT6'),
            },
          ],
          image: getHealthReportReportImage('brainT6.png'),
        },
        [t('IAreaT6'), t('ISensitiveT6')],
        [t('IDifficultyT6'), t('ILanguageT6'), t('IPossibleT6')],
        [getNumber(agingRoiScore?.Banksof_Superior_Temporal_Sulcus_LH)],
        [getNumber(agingRoiScore?.Banksof_Superior_Temporal_Sulcus_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T7.',
              content: 'Fusiform',
              note: t('INoteT7'),
            },
          ],
          image: getHealthReportReportImage('brainT7.png'),
        },
        [t('IRecognitionT7'), t('ILeftHemisphereT7'), t('IRightHemisphereT7')],
        [t('IDistinguishT7'), t('IUnderstandT7')],
        [getNumber(agingRoiScore?.Fusiform_LH)],
        [getNumber(agingRoiScore?.Fusiform_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T8.',
              content: 'Entorhinal',
              note: t('ICortexT8'),
            },
          ],
          image: getHealthReportReportImage('brainT8.png'),
        },
        [
          t('IEpisodicT8'),
          t('ICognitiveT8'),
          t('ILeftHemisphereT8'),
          t('IRightHemisphereT8'),
        ],
        [t('IEpisodicMemoryT8'), t('IRememberT8'), t('IVisualT8')],
        [getNumber(agingRoiScore?.Entorhinal_LH)],
        [getNumber(agingRoiScore?.Entorhinal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T9.',
              content: 'Parahippocampal',
              note: t('IHippocampusT9'),
            },
          ],
          image: getHealthReportReportImage('brainT9.png'),
        },
        [t('IEpisodicT9'), t('ILeftHemisphereT9'), t('IRightHemisphereT9')],
        [t('IRecallingT9'), t('IRememberT9'), t('ISpatialT9')],
        [getNumber(agingRoiScore?.Parahippocampal_LH)],
        [getNumber(agingRoiScore?.Parahippocampal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T10.',
              content: 'Insula',
              note: t('IInsulaT10'),
            },
          ],
          image: getHealthReportReportImage('brainT10.png'),
        },
        [t('IInvolvedT10'), t('IRegionT10'), t('IRecognizingT10')],
        [
          t('IDifficultyT10'),
          t('IPossibleT10'),
          t('ISlightestT10'),
          t('IFeelingPainT10'),
        ],
        [getNumber(agingRoiScore?.Insula_LH)],
        [getNumber(agingRoiScore?.Insula_RH)],
      ],
      background: 'White',
    },
  ]

  const bodyTable5 = [
    {
      data: [
        {
          header: [
            {
              key: 'P1.',
              content: 'Postcentral',
              note: t('IPostCentralLobeP1'),
            },
          ],
          image: getHealthReportReportImage('brainP1.png'),
        },
        [t('IProcessesSomatosensoryP1')],
        [t('IDifficultiesP1'), t('IInsensitivityP1')],
        [getNumber(agingRoiScore?.Postcentral_LH)],
        [getNumber(agingRoiScore?.Postcentral_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'P2.',
              content: 'Superior Parietal',
              note: t('ISuperiorParietalLobeP2'),
            },
          ],
          image: getHealthReportReportImage('brainP2.png'),
        },
        [t('IAnImportantRoleP2'), t('ILeftHemisphereP2')],
        [t('IDifficultyP2'), t('ILeftSideFunctionP2')],
        [getNumber(agingRoiScore?.Superior_Parietal_LH)],
        [getNumber(agingRoiScore?.Superior_Parietal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'P3.',
              content: 'Inferior Parietal',
              note: t('IInferiorParietalLobeP3'),
            },
          ],
          image: getHealthReportReportImage('brainP3.png'),
        },
        [
          t('IPartThatReceivesP3'),
          t('ILeftHemisphereP3'),
          t('IRightHemisphereP3'),
        ],
        [t('IDifficultP3'), t('IRightSidedDysfunctionP3')],
        [getNumber(agingRoiScore?.Inferior_Parietal_LH)],
        [getNumber(agingRoiScore?.Inferior_Parietal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'P4.',
              content: 'Supramarginal',
              note: t('IAssociationP4'),
            },
          ],
          image: getHealthReportReportImage('brainP4.png'),
        },
        [
          t('IRoleOfInterpretingP4'),
          t('ILeftHemisphereP4'),
          t('IRightHemisphereP4'),
        ],
        [t('IDecreasedP4'), t('ILeftSidedDysfunctionP4')],
        [getNumber(agingRoiScore?.Supramarginal_LH)],
        [getNumber(agingRoiScore?.Supramarginal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'P5.',
              content: 'Precuneus',
              note: t('IPreLeafletP5'),
            },
          ],
          image: getHealthReportReportImage('brainP5.png'),
        },
        [
          t('IRelatedToSelfImageP5'),
          t('ILeftHemisphereP5'),
          t('IRightHemisphereP5'),
        ],
        [
          t('IDifficultyP5'),
          t('IDifficultToIntegrateP5'),
          t('IDifficultyInSpatiotemporalP5'),
        ],
        [getNumber(agingRoiScore?.Precuneus_LH)],
        [getNumber(agingRoiScore?.Precuneus_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'P6.',
              content: 'Posterior Cingulate',
              note: t('IPosteriorCinCortexP6'),
            },
          ],
          image: getHealthReportReportImage('brainP6.png'),
        },
        [
          t('IMetaCognitionP6'),
          t('IRegionAlsoInvolvedP6'),
          t('ILeftHemisphereP6'),
          t('IRightHemisphereP6'),
        ],
        [t('ILeftSidedFunctionP6')],
        [getNumber(agingRoiScore?.Posterior_Cingulate_LH)],
        [getNumber(agingRoiScore?.Posterior_Cingulate_RH)],
      ],
      background: 'White',
    },
  ]

  const bodyTable6 = [
    {
      data: [
        {
          header: [
            {
              key: 'O1.',
              content: 'Lateral Occipital',
              note: t('ILateralOccipitalLobeO1'),
            },
          ],
          image: getHealthReportReportImage('brainO1.png'),
        },
        [t('IRegionInvolvedO1')],
        [t('IVisualCognitiveO1')],
        [getNumber(agingRoiScore?.Lateral_Occipital_LH)],
        [getNumber(agingRoiScore?.Lateral_Occipital_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'O2.',
              content: 'Cuneus',
              note: t('ISnowLeafO2'),
            },
          ],
          image: getHealthReportReportImage('brainO2.png'),
        },
        [t('IIntensityOfLightO2'), t('IResponsibleForVisualO2')],
        [t('IVisualCognitiveO2'), t('ISevereDamageO2')],
        [getNumber(agingRoiScore?.Cuneus_LH)],
        [getNumber(agingRoiScore?.Cuneus_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'O3.',
              content: 'Pericalcarine',
              note: t('IClawLobeO3'),
            },
          ],
          image: getHealthReportReportImage('brainO3.png'),
        },
        [t('IResponsibleO3'), t('IAbleToVisuallyO3')],
        [t('IVisualCognitiveO3'), t('IDifficultyRecognizingO3')],
        [getNumber(agingRoiScore?.Pericalcarine_LH)],
        [getNumber(agingRoiScore?.Pericalcarine_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'O4.',
              content: 'Lingual',
              note: t('ISnowO4'),
            },
          ],
          image: getHealthReportReportImage('brainO4.png'),
        },
        [
          t('IRecognizeTheLettersO4'),
          t('IResponsibleForConnectingO4'),
          t('IRegionAlsoInvolvedO4'),
        ],
        [t('INoDifficultyO4'), t('IPossibleVisualO4')],
        [getNumber(agingRoiScore?.Lingual_LH)],
        [getNumber(agingRoiScore?.Lingual_RH)],
      ],
      background: 'White',
    },
  ]

  const getHeadTable = (number: number) => {
    let firstColumnText = 'IFrontalLobeBrainMapping'
    if (number === 2) {
      firstColumnText = 'ITemporalLobe'
    }
    if (number === 3) {
      firstColumnText = 'IParietalLobe'
    }
    if (number === 4) {
      firstColumnText = 'IOccipitalLobe'
    }
    return [
      t(firstColumnText),
      t('IMainFunction'),
      t('IFunctionalDecline'),
      t('ILeftHemisphere'),
      t('IRightHemisphere'),
    ]
  }

  return (
    <div className={classes.brainFunctionMapping}>
      <PageWrap>
        <PageContent>
          <WrapElement number={1} text={t('IDetailedAnalysisFrontal')}>
            <CardContent>
              <FlexBox>
                <div className={classes.contentDetailedAnalysis}>
                  <div className={classes.textHeader}>
                    <p>{t('IFrontalLobeHeader')}</p>&nbsp;
                    <p className={classes.headerColorGreen}>
                      {t('IResOfHightOrder')}
                    </p>
                    &nbsp;
                    <p>{t('IMainArea')}</p>
                  </div>
                  <div className={classes.textContent}>
                    <p>{t('IGovernsHigher')}</p>
                    <p>{t('IRepresentativeExample')}</p>
                    <p>{t('IDetailedAnalysis')}</p>
                    <p>{t('IHealthLevelOfEach')}</p>
                    <p>{t('ISuggestedLowerNumber')}</p>
                  </div>
                </div>
                <div className={classes.imgDetailedAnalysis}>
                  <div className={classes.scoreSpace}>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>{t('ILeft')}</div>
                        <Score score={getNumber(agingLobeScore?.Frontal_LH)} />
                      </div>
                    </div>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>{t('IRight')}</div>
                        <Score score={getNumber(agingLobeScore?.Frontal_RH)} />
                      </div>
                    </div>
                  </div>
                  <div className={classes.imageOverviewBrain}>
                    <img src={getHealthReportReportImage('brain.svg')} alt='' />
                  </div>
                </div>
              </FlexBox>
            </CardContent>
          </WrapElement>
          <div className={classes.marginBottom60}>
            <TableFiguresByPart head={getHeadTable(1)} body={bodyTable} />
          </div>

          <div className={classes.marginBottom60}>
            <TableFiguresByPart head={getHeadTable(1)} body={bodyTable2} />
          </div>

          <WrapElement number={2} text={t('ITemporalLobeEEG')}>
            <CardContent>
              <FlexBox>
                <div className={classes.contentDetailedAnalysis}>
                  <div className={classes.textHeader}>
                    <p>{t('IHeaderTemporalLobe1')}</p>&nbsp;
                    <p className={classes.headerColorGreen}>
                      {t('IHeaderTemporalLobe2')}
                    </p>
                    &nbsp;
                    <p>{t('IHeaderTemporalLobe3')}</p>
                  </div>
                  <div className={classes.textContent}>
                    <p>{t('IGovernsHigherMental')}</p>
                    <p>{t('ITemporalLobeDisorders')}</p>
                    <p>{t('IDetailedAnalysis2')}</p>
                  </div>
                </div>
                <div className={classes.imgDetailedAnalysis}>
                  <div className={classes.scoreSpace}>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>{t('ILeft')}</div>
                        <Score score={getNumber(agingLobeScore?.Temporal_LH)} />
                      </div>
                    </div>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>{t('IRight')}</div>
                        <Score score={getNumber(agingLobeScore?.Temporal_RH)} />
                      </div>
                    </div>
                  </div>
                  <div className={classes.imageOverviewBrain}>
                    <img
                      src={getHealthReportReportImage('brainPage2.png')}
                      alt=''
                    />
                  </div>
                </div>
              </FlexBox>
            </CardContent>
          </WrapElement>

          <div className={classes.marginBottom60}>
            <TableFiguresByPart head={getHeadTable(2)} body={bodyTable3} />
            <div className={classes.textBottomTable}>
              {t('IBasedOnTheMedian')}
            </div>
          </div>

          <div className={classes.marginBottom60}>
            <TableFiguresByPart head={getHeadTable(2)} body={bodyTable4} />
          </div>

          <WrapElement number={3} text={t('IParietalLobe3DDetailed')}>
            <CardContent>
              <FlexBox>
                <div className={classes.contentDetailedAnalysis}>
                  <div className={classes.textHeader}>
                    <p>{t('IParietalLobe2')}</p>&nbsp;
                    <p className={classes.headerColorGreen}>
                      {t('IResponsible')}
                    </p>
                    &nbsp;
                    <p>{t('IMainArea2')}</p>
                  </div>
                  <div className={classes.textContent}>
                    <p>{t('IResponsibleForTheIntegration')}</p>
                    <p>{t('ISymptomsLikeThese')}</p>
                    <p>{t('IHealthLevelOfEachDetailed')}</p>
                    <p>{t('ILowerNumber')}</p>
                  </div>
                </div>
                <div className={classes.imgDetailedAnalysis}>
                  <div className={classes.scoreSpace}>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>{t('ILeft')}</div>
                        <Score score={getNumber(agingLobeScore?.Parietal_LH)} />
                      </div>
                    </div>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>{t('IRight')}</div>
                        <Score score={getNumber(agingLobeScore?.Parietal_RH)} />
                      </div>
                    </div>
                  </div>
                  <div className={classes.imageOverviewBrain}>
                    <img
                      src={getHealthReportReportImage('brainPage5.png')}
                      alt=''
                    />
                  </div>
                </div>
              </FlexBox>
            </CardContent>
          </WrapElement>

          <div className={classes.marginBottom60}>
            <TableFiguresByPart head={getHeadTable(3)} body={bodyTable5} />
          </div>

          <WrapElement number={4} text={t('IDetailedAnalysisOccipitalLobe3D')}>
            <CardContent>
              <FlexBox>
                <div className={classes.contentDetailedAnalysis}>
                  <div className={classes.textHeader}>
                    <p>{t('IOccipitalLobe2')}</p>&nbsp;
                    <p className={classes.headerColorGreen}>
                      {t('IChargeOfVision')}
                    </p>
                    &nbsp;
                    <p>{t('IVisualCenterOfVision')}</p>
                  </div>
                  <div className={classes.textContent}>
                    <p>{t('IAlsoInvolvedInRecognizing')}</p>
                    <p>{t('IHealthLevelOfEachDetailedArea')}</p>
                    <p>{t('IAssociatedSeizures')}</p>
                    <p>{t('IStandardizedRelative')}</p>
                    <p>{t('ILowerNumberV4')}</p>
                  </div>
                </div>
                <div className={classes.imgDetailedAnalysis}>
                  <div className={classes.scoreSpace}>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>{t('ILeft')}</div>
                        <Score
                          score={getNumber(agingLobeScore?.Occipital_LH)}
                        />
                      </div>
                    </div>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>{t('IRight')}</div>
                        <Score
                          score={getNumber(agingLobeScore?.Occipital_RH)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.imageOverviewBrain}>
                    <img
                      src={getHealthReportReportImage('brainPage6.png')}
                      alt=''
                    />
                  </div>
                </div>
              </FlexBox>
            </CardContent>
          </WrapElement>

          <div className={classes.marginBottom60}>
            <TableFiguresByPart head={getHeadTable(4)} body={bodyTable6} />
          </div>
        </PageContent>
      </PageWrap>
    </div>
  )
}

export default BrainFunctionMappingPage
