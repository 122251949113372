import {yupResolver} from '@hookform/resolvers/yup'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import {loginApi, updatePasswordApi} from 'api/authApi'
import {getOrInitializeDeviceId} from 'api/storageApi'
import {OrgUserUpdateRequest, updateUserDetailInfoApi} from 'api/userApi'
import classNames from 'classnames'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import Card from 'components/atoms/Card'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import SelectBoldAnalysisVersion from 'components/Dialog/BoldSelect/SelectBoldAnalysisVersion'
import SelectBoldDepartment from 'components/Dialog/BoldSelect/SelectBoldDepartment'
import SelectBoldIndustryWrapper from 'components/Dialog/BoldSelect/SelectBoldIndustry'
import {nullCheck} from 'components/Dialog/User/Read'
import useStyles from 'components/Dialog/User/Style'
import {
  ADMIN_INFO_DIALOG_TYPE,
  MY_INFO_DIALOG_TYPE,
} from 'constants/DialogConstant'
import {
  closeUserEditDialog,
  selectUserDialogType,
  selectUserDialogUid,
  selectUserEditDialogOpen,
} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import useOrganization from 'features/org/useOrganization'
import useOrgUser from 'features/org/useOrgUser'
import {selectSales} from 'features/sales/salesSlice'
import {getFBToken} from 'helpers/firebaseHelper'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {Controller, FieldErrors, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useMutation, useQueryClient} from 'react-query'
import {object, ref, string, TypeOf} from 'yup'

interface OrgUserUpdatePayload extends OrgUserUpdateRequest {
  password?: string | null
  authority?: string | null
}

const Required = ({hidden = false}: {hidden?: boolean}) => {
  const classes = useStyles()
  const requiredClasses = classNames({
    [classes.requiredMark]: !hidden,
    [classes.requiredMarkHidden]: hidden,
  })
  return <Typography className={requiredClasses}>*</Typography>
}
const patternNumber = /[0-9]/
const patternLetters = /[a-zA-Z]/
const patternCharacter = /[~!@#$%^&*()_+|<>?:{}]/
const PassWordSchema = string()
  .notRequired()
  .min(8, 'IPasswordLength')
  .max(20, 'IPasswordLength')
  .test('password-character-test', 'IPasswordInvalid', (str) => {
    if (str?.length === 0 || str === '' || str === undefined) {
      return true
    }
    if (
      patternNumber.test(str ?? '') &&
      patternLetters.test(str ?? '') &&
      patternCharacter.test(str ?? '')
    ) {
      return true
    }
    return false
  })

const TelSchema = string().matches(
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
  'ITelInvalid',
)

const EditSchema = object({
  uid: string().required('IDepartmentRequired'), // 리덕스에서 값 할당
  firstName: string()
    .required('INameRequired')
    .min(0, 'INameLength')
    .max(50, 'INameLength'),
  lastName: string()
    .required('INameRequired')
    .min(0, 'INameLength')
    .max(50, 'INameLength'),
  email: string()
    .required('IEmailRequired')
    .email('IEmailInvalid')
    .max(50, 'IEmailLength'),
  checkedEmail: string()
    // .required()
    .oneOf([ref('email'), null], 'IEmailDuplicatePlease'),
  licenseNumber: string(),
  departmentId: string().required('IDepartmentRequired'),
  userType: string().required('IDepartmentRequired'),
  authority: string(),
  tel: TelSchema,
  divisionId: string(),
  positionId: string(),
  eegVersionId: string(),
  hrvVersionId: string(),
  previousPassword: string(),
  password: PassWordSchema,
  passwordConfirm: string().oneOf(
    [ref('password'), null],
    'IPasswordSamePlease',
  ),
})

interface EditForm extends TypeOf<typeof EditSchema> {}

export default function UserEditDialog() {
  const {t} = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const {i18n} = useTranslation()
  const open = useAppSelector(selectUserEditDialogOpen)
  const uid = useAppSelector(selectUserDialogUid)
  const dialogType = useAppSelector(selectUserDialogType)
  const detailSales = useAppSelector(selectSales)
  const inputClasses = useBoldInputStyle()
  const {organization, onFetch: onOrganizationFetch} = useOrganization()
  const detailIndustry = organization?.detailIndustry
  const {user: userDetailInfo, onFetch: onUserFetch} = useOrgUser()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()

  const {
    handleSubmit,
    formState: {errors},
    control,
    reset,
    setValue,
    getValues,
  } = useForm<EditForm>({
    // @ts-ignore
    resolver: yupResolver(EditSchema),
  })

  const [pinEditable, setPinEditable] = React.useState<boolean>(false)
  const [fileSelected, setFileSelected] = React.useState<File>()

  const handleClose = () => dispatch(closeUserEditDialog())

  const handlePinLogin = async () => {
    if (pinEditable) {
      onFailureModalOpen(t('IAlreadyConfirmed'))
      return
    }
    const password = getValues('previousPassword')
    if (!password) {
      return
    }

    const token = await getFBToken()
    const email = getValues('email') ?? ''
    try {
      const Login = await loginApi({
        email: email ?? '',
        password,
        deviceId: getOrInitializeDeviceId(),
        deviceType: 'WEB',
        token,
        lang: i18n.language,
      })
      if (Login.success) {
        onSuccessModalOpen(t('IPasswordConfirmSuccess'))
        setPinEditable(true)
      }
    } catch (e) {
      onFailureModalOpen(t('IPasswordConfirmFailure'))
    }
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files
    if (!fileList) return
    const reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/

    if (!fileList[0].name.match(reg)) {
      onFailureModalOpen(t('IInvalidImageFileRequest'))
      return
    }
    setFileSelected(fileList[0])
  }

  const [divisionId, setDivisionId] = React.useState(0)
  const [positionId, setPositionId] = React.useState(0)

  const handleDivisionChanged = (industry: Industry) =>
    setDivisionId(industry.id ?? 0)
  const handlePositionChanged = (industry: Industry) =>
    setPositionId(industry.id ?? 0)

  const [departmentValue, setDepartmentValue] = React.useState('')
  const handleDepartmentValueChanged = (department: string) => {
    setValue('departmentId', department)
    setDepartmentValue(department)
  }

  // eeg & hrv 셀렉트 박스
  const [eegSelectedValue, setEegSelectedValue] = React.useState<number>()
  const [hrvSelectedValue, setHrvSelectedValue] = React.useState<number>()
  const handleEEgValueChanged = (eegVersion: DataVersion) =>
    setEegSelectedValue(eegVersion.id)
  const handleHrvValueChanged = (hrvVersion: DataVersion) =>
    setHrvSelectedValue(hrvVersion.id)

  // 전화번호 - 제거
  const replaceValue = userDetailInfo?.tel ?? ''
  const telValue = replaceValue.replace(/-/gi, '@')

  function makeHipenRemove(string: string): string {
    const telValue = string.replace(/[^0-9]/gi, '')
    return telValue
  }

  // 필터 리스트 map
  const fullArray = ['General', 'Research', 'Sales', 'Cs']
  const harfArray = ['General', 'Research']
  const [authorityList, setAuthorityList] = React.useState([''])

  function checkAuthority(group: string) {
    if (group === 'COMPANY_GROUP') {
      setAuthorityList(fullArray)
    } else {
      setAuthorityList(harfArray)
    }
  }

  function checkAuthorityView(string: string | undefined): boolean {
    if (!string) {
      return false
    }
    if (dialogType === ADMIN_INFO_DIALOG_TYPE) {
      if (string.includes('ADMIN')) {
        return false
      }
      return true
    }
    return false
  }

  const authorityListMap = authorityList.map((value) => (
    <FormControlLabel
      value={value}
      control={<Radio color='primary' />}
      label={value}
    />
  ))

  const handlePasswordSubmit = async (pinNumber: string) => {
    try {
      const currentPwd = getValues('previousPassword')
      const response = await updatePasswordApi({
        currentPwd,
        newPwd: pinNumber,
        pwdType: 'UPDATE',
        uid,
      })

      if (!response.success) {
        onFailureModalOpen(t('IProcessFail'))
      }
    } catch (err) {
      onFailureModalOpen(t('IProcessFail'))
    }
  }

  const queryClient = useQueryClient()
  const mutation = useMutation(
    (data: OrgUserUpdateRequest) => updateUserDetailInfoApi(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('fetchUsersList')
        handleClose()
        onSuccessModalOpen(t('IProcessSuccess'))
      },
      onError: (error: any) => {
        onFailureModalOpen(error.message)
      },
    },
  )

  const onSubmit = (form: EditForm) => {
    const data: OrgUserUpdatePayload = {
      uid,
      firstName: form.firstName,
      lastName: form.lastName,
      // name: form.name,
      email: form.email,
      profile: fileSelected || null,
      licenseNumber: form.licenseNumber || null,
      divisionId: divisionId !== 0 ? divisionId : null,
      positionId: positionId !== 0 ? positionId : null,
      eegVersionId: eegSelectedValue ?? null,
      hrvVersionId: hrvSelectedValue ?? null,
      userType: form.userType,
      // authority: form.authority,
      tel: form.tel || null,
      // TODO: department 수정 요청하기
      deptName: departmentValue,
    }

    if (data.authority) {
      delete data.authority
    }

    if (divisionId === 0 || divisionId === null || divisionId === undefined) {
      delete data.divisionId
    }

    if (positionId === 0 || positionId === null || positionId === undefined) {
      delete data.positionId
    }
    if (data.password) handlePasswordSubmit(data.password ?? '')
    mutation.mutate(data)
  }

  const onInvalidData = (err?: FieldErrors<EditForm>) => {
    const fields = err ? Object.values(err) : []
    for (let i = 0; i < fields.length; i += 1) {
      const field = fields[i]
      if (field?.message) {
        onFailureModalOpen(t(field?.message) || field?.message)
        break
      }
    }
  }

  useEffect(() => {
    if (open && uid) {
      onUserFetch(uid)
      onOrganizationFetch()
    }
    return () => {
      setPinEditable(false)
      reset()
      setFileSelected(undefined)
    }
  }, [open, uid])

  useEffect(() => {
    if (open && uid) {
      setValue('uid', uid)
    }
  }, [open, uid])

  useEffect(() => {
    if (nullCheck(detailSales.searchedDetailData)) {
      checkAuthority(detailSales.searchedDetailData.orgDetail.authorityGroup)
    }
  }, [open, detailSales])

  useEffect(() => {
    if (open && userDetailInfo) {
      setValue('firstName', userDetailInfo.firstName ?? '')
      setValue('lastName', userDetailInfo.lastName ?? '')
      setValue('email', userDetailInfo.email ?? '')
      setValue('checkedEmail', userDetailInfo.email ?? '')
      setValue('tel', makeHipenRemove(userDetailInfo.tel ?? ''))
      setValue('userType', userDetailInfo.userType ?? 'STAFF')
      setValue('licenseNumber', userDetailInfo.licenseNumber ?? '')
      // TODO: 사용자 목록에서 eegVersion 정보에 id 내려달라고 요청하기
      setDepartmentValue(userDetailInfo.deptName ?? '')
      setValue('departmentId', departmentValue)
      setValue('eegVersionId', String(eegSelectedValue))
      setValue('hrvVersionId', String(hrvSelectedValue))
      setDivisionId(userDetailInfo.division.id ?? '')
      setPositionId(userDetailInfo.position.id ?? '')
    }
  }, [open, userDetailInfo])

  return (
    <div>
      <Dialog open={open} fullWidth maxWidth='md'>
        <Card className={classes.cardWrap}>
          <form onSubmit={handleSubmit(onSubmit, onInvalidData)}>
            <div className={classes.root}>
              {/* 상단 타이틀 컨테이너  */}
              <div className={classes.longTitleContainer}>
                <div className={classes.containerTitle}>
                  <div className={classes.closeButtonWrap}>
                    <IconButton
                      color='secondary'
                      aria-label='favorite'
                      className={classes.closeButton}
                      onClick={handleClose}
                    >
                      <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                  </div>
                  <Typography variant='h6' className={classes.title}>
                    {dialogType === 'MY_INFO'
                      ? t('IMyInfoUpdate')
                      : t('IUserUpdate')}
                  </Typography>
                </div>
              </div>
              <Divider className={classes.titleDivider} />
              <Typography className={classes.padding} />
              <div className={classes.scroll}>
                {/* 성명 */}
                <div className={classes.inputContainer}>
                  <div className={inputClasses.labelWrapContainer}>
                    <p className={inputClasses.inputLabel}>{t('IFirstName')}</p>
                    <p className={inputClasses.require}>*</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    {i18n.language !== 'ko' && i18n.language !== 'ja' && (
                      <Controller
                        name='firstName'
                        control={control}
                        rules={{required: false}}
                        render={({field}) => (
                          <div className={classes.inputWrap}>
                            <input
                              // label='이름'
                              className={inputClasses.input}
                              placeholder={t('IFirstName')}
                              // variant='outlined'
                              // className={classes.textField}
                              defaultValue={userDetailInfo?.firstName ?? ''}
                              // error={!!errors.firstName}
                              // helperText={t(errors.firstName?.message ?? '')}
                              {...field}
                            />
                            <span className={classes.errorText}>
                              {errors.firstName && (
                                <div>{t(errors.firstName?.message ?? '')}</div>
                              )}
                            </span>
                          </div>
                        )}
                      />
                    )}
                    {i18n.language !== 'ko' && i18n.language !== 'ja' && (
                      <Typography className={classes.padding} />
                    )}
                    {i18n.language !== 'ko' && i18n.language !== 'ja' && (
                      <Controller
                        name='lastName'
                        control={control}
                        rules={{required: false}}
                        render={({field}) => (
                          <div className={classes.inputWrap}>
                            <input
                              placeholder={t('ILastName')}
                              className={inputClasses.input}
                              defaultValue={userDetailInfo?.lastName ?? ''}
                              {...field}
                            />
                            <span className={classes.errorText}>
                              {errors.lastName && (
                                <div>{t(errors.lastName?.message ?? '')}</div>
                              )}
                            </span>
                          </div>
                        )}
                      />
                    )}
                    {(i18n.language === 'ko' || i18n.language === 'ja') && (
                      <Controller
                        name='lastName'
                        control={control}
                        rules={{required: false}}
                        render={({field}) => (
                          <div className={classes.inputWrap}>
                            <input
                              // label='이름'
                              className={inputClasses.input}
                              placeholder={t('ILastName')}
                              // variant='outlined'
                              // className={classes.textField}
                              defaultValue={userDetailInfo?.lastName ?? ''}
                              // error={!!errors.firstName}
                              // helperText={t(errors.firstName?.message ?? '')}
                              {...field}
                            />
                            <span className={classes.errorText}>
                              {errors.lastName && (
                                <div>{t(errors.lastName?.message ?? '')}</div>
                              )}
                            </span>
                          </div>
                        )}
                      />
                    )}
                    {(i18n.language === 'ko' || i18n.language === 'ja') && (
                      <Typography className={classes.padding} />
                    )}
                    {(i18n.language === 'ko' || i18n.language === 'ja') && (
                      <Controller
                        name='firstName'
                        control={control}
                        rules={{required: false}}
                        render={({field}) => (
                          <div className={classes.inputWrap}>
                            <input
                              placeholder={t('IFirstName')}
                              className={inputClasses.input}
                              defaultValue={userDetailInfo?.firstName ?? ''}
                              {...field}
                            />
                            <span className={classes.errorText}>
                              {errors.firstName && (
                                <div>{t(errors.firstName?.message ?? '')}</div>
                              )}
                            </span>
                          </div>
                        )}
                      />
                    )}
                  </div>
                </div>
                {dialogType === MY_INFO_DIALOG_TYPE && (
                  <div>
                    {/* 기존핀번호 */}
                    <div className={classes.inputContainer}>
                      <div className={inputClasses.labelWrapContainer}>
                        <p className={inputClasses.inputLabel}>
                          {t('IPasswordPrevious')}
                        </p>
                        <p className={inputClasses.require}>*</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Controller
                          control={control}
                          name='previousPassword'
                          render={({field}) => (
                            <div className={classes.inputWrap}>
                              <input
                                type='password'
                                placeholder={t('IPassword')}
                                className={inputClasses.input}
                                {...field}
                              />
                              <span className={classes.errorText}>
                                {errors.previousPassword && (
                                  <div>
                                    {t(errors.previousPassword?.message ?? '')}
                                  </div>
                                )}
                              </span>
                            </div>
                          )}
                        />
                        <Typography className={classes.padding} />
                        <ActionButton
                          // variant='contained'
                          color='primary'
                          disableElevation
                          className={classes.editButton}
                          onClick={handlePinLogin}
                        >
                          {t('IEdit')}
                        </ActionButton>
                      </div>
                    </div>
                    {/* 새로 작성하는 핀번호 */}
                    <div className={classes.inputContainer}>
                      <div className={inputClasses.labelWrapContainer}>
                        <p className={inputClasses.inputLabel}>
                          {t('IPasswordNext')}
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Controller
                          control={control}
                          name='password'
                          render={({field}) => (
                            <div className={classes.inputWrap}>
                              <input
                                type='password'
                                placeholder={t('IPasswordNext')}
                                className={inputClasses.input}
                                disabled={!pinEditable}
                                {...field}
                              />
                              <span className={classes.errorText}>
                                {errors.password && (
                                  <div>{t(errors.password?.message ?? '')}</div>
                                )}
                                {errors.passwordConfirm && (
                                  <div style={{visibility: 'hidden'}}>
                                    {t(errors.passwordConfirm?.message ?? '')}
                                  </div>
                                )}
                              </span>
                            </div>
                          )}
                        />

                        <Typography className={classes.padding} />
                        <Controller
                          control={control}
                          name='passwordConfirm'
                          render={({field}) => (
                            <div className={classes.inputWrap}>
                              <input
                                type='password'
                                placeholder={t('IPasswordSamePlease')}
                                className={inputClasses.input}
                                disabled={!pinEditable}
                                {...field}
                              />
                              <span className={classes.errorText}>
                                {errors.passwordConfirm && (
                                  <div>
                                    {t(errors.passwordConfirm?.message ?? '')}
                                  </div>
                                )}
                                {errors.password && (
                                  <div style={{visibility: 'hidden'}}>
                                    {t(errors.password?.message ?? '')}
                                  </div>
                                )}
                              </span>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className={classes.inputContainer}>
                  <div className={inputClasses.labelWrapContainer}>
                    <p className={inputClasses.inputLabel}>{t('IEmail')}</p>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Controller
                      name='email'
                      control={control}
                      rules={{required: false}}
                      render={({field}) => (
                        <div className={classes.inputWrap}>
                          <input
                            disabled
                            placeholder={t('IEmail')}
                            className={inputClasses.input}
                            {...field}
                          />
                          <span className={classes.errorText}>
                            {errors.email && (
                              <div>{t(errors.email?.message ?? '')}</div>
                            )}
                          </span>
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div className={classes.inputContainer}>
                  <div className={inputClasses.labelWrapContainer}>
                    <p className={inputClasses.inputLabel}>
                      {t('IUserDepartment')}
                    </p>
                    <p className={inputClasses.require}>*</p>
                  </div>

                  <SelectBoldDepartment
                    selectedValue={userDetailInfo?.deptName ?? ''}
                    onChangeData={handleDepartmentValueChanged}
                    // {...field}
                  />
                </div>

                <div className={classes.contentContainer}>
                  <Typography className={classes.subContentText}>*</Typography>
                  <Typography className={classes.subContentText}>
                    {t('IUserDepartmentDesc')}
                  </Typography>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div className={classes.inputContainer}>
                    <div className={inputClasses.labelWrapContainer}>
                      <p className={inputClasses.inputLabel}>
                        {t('IMyProfile')}
                      </p>
                    </div>
                    <div className={classes.fileContainer}>
                      <div className={classes.fileWrap}>
                        <input
                          id='profile'
                          name='profile'
                          type='file'
                          accept='image/*'
                          onChange={handleImageChange}
                          className={classes.fileInput}
                        />
                        <label className={classes.fileLabel} htmlFor='profile'>
                          <div style={{padding: 10}}>
                            {fileSelected ? fileSelected.name : t('IFileName')}
                          </div>
                          <div className={classes.iconContainer}>
                            <SearchIcon />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <Typography className={classes.padding} />
                  <div className={classes.inputContainer}>
                    <div className={inputClasses.labelWrapContainer}>
                      <p className={inputClasses.inputLabel}>{t('ITel')}</p>
                    </div>

                    <Controller
                      name='tel'
                      control={control}
                      render={({field}) => (
                        <div className={classes.inputWrap}>
                          <input
                            placeholder={t('ITel')}
                            defaultValue={makeHipenRemove(telValue)}
                            className={inputClasses.input}
                            {...field}
                          />
                          <span className={classes.errorText}>
                            {errors.tel && (
                              <div>{t(errors.tel?.message ?? '')}</div>
                            )}
                          </span>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div className={classes.inputContainer}>
                    <div className={inputClasses.labelWrapContainer}>
                      <p className={inputClasses.inputLabel}>
                        {t('IUserDivision')}
                      </p>
                    </div>
                    <SelectBoldIndustryWrapper
                      parent={detailIndustry?.id}
                      depth={3}
                      selectedValue={t('ISelectRequired')}
                      defaultValue={divisionId}
                      onChangeData={handleDivisionChanged}
                    />
                  </div>
                  <Typography className={classes.padding} />
                  <div className={classes.inputContainer}>
                    <div className={inputClasses.labelWrapContainer}>
                      <p className={inputClasses.inputLabel}>
                        {t('IUserPosition')}
                      </p>
                    </div>
                    <SelectBoldIndustryWrapper
                      parent={divisionId}
                      depth={4}
                      selectedValue={t('ISelectRequired')}
                      onChangeData={handlePositionChanged}
                      defaultValue={positionId}
                    />
                  </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div className={classes.inputContainer}>
                    <div className={inputClasses.labelWrapContainer}>
                      <p className={inputClasses.inputLabel}>
                        NormDB EEG version
                      </p>
                    </div>
                    <SelectBoldAnalysisVersion
                      type='EEG'
                      selectedValue={
                        nullCheck(userDetailInfo?.eegVersion)
                          ? userDetailInfo?.eegVersion.version ?? ''
                          : null
                      }
                      onChangeData={handleEEgValueChanged}
                      // {...field}
                    />
                  </div>
                  <Typography className={classes.padding} />
                  <div className={classes.inputContainer}>
                    <div className={inputClasses.labelWrapContainer}>
                      <p className={inputClasses.inputLabel}>
                        NormDB HRV version
                      </p>
                    </div>

                    <SelectBoldAnalysisVersion
                      type='HRV'
                      selectedValue={
                        nullCheck(userDetailInfo?.hrvVersion)
                          ? userDetailInfo?.hrvVersion.version ?? ''
                          : null
                      }
                      onChangeData={handleHrvValueChanged}
                      // {...field}
                    />
                  </div>
                </div>

                {checkAuthorityView(userDetailInfo?.authority) && (
                  <div className={classes.contentContainer}>
                    <Required hidden />
                    <Typography className={classes.subTitle}>
                      {t('IAuthority')}
                    </Typography>
                    <Controller
                      rules={{required: false}}
                      name='authority'
                      control={control}
                      defaultValue='General'
                      render={({field}) => (
                        <RadioGroup row aria-label='userType' {...field}>
                          {authorityListMap}
                        </RadioGroup>
                      )}
                    />
                  </div>
                )}
                <Typography className={classes.padding} />
                <Typography className={classes.padding} />
              </div>
              {/* 하단 버튼 컨테이너  */}
              <div className={classes.buttonContainerBgColor}>
                <div className={classes.buttonContainer}>
                  <Button
                    variant='contained'
                    color='default'
                    disableElevation
                    onClick={handleClose}
                    className={classes.okButton}
                  >
                    {t('IDiscardEdit')}
                  </Button>
                  <Typography className={classes.smallPadding} />
                  <Button
                    variant='contained'
                    color='primary'
                    disableElevation
                    className={classes.okButtonColor}
                    type='submit'
                  >
                    {t('IOk')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Card>
      </Dialog>
    </div>
  )
}
