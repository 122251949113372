import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  executePremiumEegSummaryApi,
  finalizeEegSummary,
  SearchedIndividualSummary,
} from 'api/analysisApi'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import TableButton from 'components/atoms/Buttons/TableButton'
import {ClientName} from 'components/atoms/ClientName'
import Pagination from 'components/molcules/Pagination'
import TableCell from 'components/Table/TableCell'
import TableCellReport from 'components/Table/TableCellReport'
import TableReportSearch from 'components/Table/TableReportSearch'
import useStyles from 'components/Table/useTableStyles'
import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import {AnalysisReportType} from 'constants/AnalysisConstant'
import {getEegSummaryReportPath} from 'constants/RouteConstant'
import {useAnalysisConfirm} from 'features/analysis/useAnalysisConfirm'
import useEegSummarySearch from 'features/analysis/useEegSummarySearch'
import useFailureModal from 'features/modal/useFailureModal'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import useSuccessModal from 'features/modal/useSuccessModal'
import {statusToI18nString} from 'helpers/analysisHelper'
import {isDefined, getQueryFromUrl} from 'helpers/commonHelper'
import {
  dateToPeriodString,
  getDefaultPeriodDate,
  isoStringToDateAndTimeString,
} from 'helpers/dateHelper'
import openInNewTab from 'helpers/openInNewTab'

const isUpdatable = (searched: SearchedIndividualSummary) =>
  searched.progress === 'SUCCESS' && searched.additionalAnalysis === null

interface TableToolbarProps {
  selected: SearchedIndividualSummary[]
  clearSelected: () => void
}

function TableToolbar({selected, clearSelected}: TableToolbarProps) {
  const location = useLocation()
  const {t} = useTranslation()
  const classes = useToolbarStyles()
  const {onOpen: onAnalysisConfirm} = useAnalysisConfirm()
  const {onOpen: handleSuccessOpen} = useSuccessModal()
  const {onOpen: handleFailureOpen} = useFailureModal()
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()
  const {query, search, onSearch} = useEegSummarySearch()

  const selection = getDefaultPeriodDate()

  // Init search value  from url if exist
  useEffect(() => {
    const initValue = getQueryFromUrl(location.search)
    onSearch({
      ...query,
      period: dateToPeriodString(selection),
      paging: {page: 0, size: 10},
      ...initValue,
    })
  }, [])

  const premiumRequest = async (list: number[]) => {
    const confirmed = await onAnalysisConfirm({
      type: 'eeg_summary_premium',
      selected: list,
    })

    if (confirmed === false) return
    if (confirmed.length > 0) {
      try {
        await executePremiumEegSummaryApi(confirmed)
        await handleSuccessOpen(t('ISuccess'))
        clearSelected()
        onSearch({
          ...query,
          search,
        })
      } catch (err) {
        handleFailureOpen(err.message)
      }
    }
  }

  const handlePremiumSummary = async () => {
    const listOfId = selected.map((item) => item.id)
    if (listOfId.length === 0) {
      handleFailureOpen(t('ISelectRequired'))
      return
    }
    onNoticeDialogOpen({
      title: t('INoticeTitle'),
      message: t('INoticeConfirmDesc'),
    }).then((result) => {
      if (result.payload) premiumRequest(listOfId)
    })
  }

  return (
    <div className={classes.root}>
      <TableReportSearch
        reportType={AnalysisReportType.EEGSummary}
        isRoot={false}
        query={query}
        search={search}
        onSearch={onSearch}
      />
      <div className={classes.containerOptional}>
        <ActionButton onClick={handlePremiumSummary}>
          {t('IPremiumSummary')}
        </ActionButton>
      </div>
    </div>
  )
}

interface RenderTableRowProps {
  item: SearchedIndividualSummary
  onClick: (item: SearchedIndividualSummary) => void
  isSelected: (item: SearchedIndividualSummary) => boolean
}

function RenderTableRow({item, onClick, isSelected}: RenderTableRowProps) {
  const {t} = useTranslation()

  const checked = isSelected(item)
  const labelId = `summary-table-id-${item.id}`

  const {query, onSearch} = useEegSummarySearch()
  const {onOpen: handleFailureModalOpen} = useFailureModal()

  const handleResultClick = (event: any) => {
    event.stopPropagation()
    openInNewTab(getEegSummaryReportPath(item.id))
  }

  const handleClick = () => onClick(item)

  const handleMainReportClick = async () => {
    const {id} = item
    try {
      await finalizeEegSummary(id)
      onSearch({...query})
    } catch (err) {
      handleFailureModalOpen(err.message)
    }
  }

  let idnAnalId = ''
  if (item.ecIndividualId != null && item.eoIndividualId != null) {
    idnAnalId = `EC: ${item.ecIndividualId ?? '-'} / EO: ${
      item.eoIndividualId ?? '-'
    }`
  } else if (item.ecIndividualId != null && item.eoIndividualId == null) {
    idnAnalId = `EC: ${item.ecIndividualId ?? '-'}`
  } else if (item.ecIndividualId == null && item.eoIndividualId != null) {
    idnAnalId = `EO: ${item.eoIndividualId ?? '-'}`
  } else {
    idnAnalId = '-'
  }

  let reAnalId = ''
  if (item.ecReAnalysisId != null && item.eoReAnalysisId != null) {
    reAnalId = `EC: ${item.ecReAnalysisId ?? '-'} / EO: ${
      item.eoReAnalysisId ?? '-'
    }`
  } else if (item.ecReAnalysisId != null && item.eoReAnalysisId == null) {
    reAnalId = `EC: ${item.ecReAnalysisId ?? '-'}`
  } else if (item.ecReAnalysisId == null && item.eoReAnalysisId != null) {
    reAnalId = `EO: ${item.eoReAnalysisId ?? '-'}`
  } else {
    reAnalId = '-'
  }

  let tfileName = ''
  if (item.ecFileName != null && item.eoFileName != null) {
    tfileName = `EC: ${item.ecFileName ?? '-'} / EO: ${item.eoFileName ?? '-'}`
  } else if (item.ecFileName != null && item.eoFileName == null) {
    tfileName = `EC: ${item.ecFileName ?? '-'}`
  } else if (item.ecFileName == null && item.eoFileName != null) {
    tfileName = `EO: ${item.eoFileName ?? '-'}`
  } else {
    tfileName = '-'
  }

  const renderEyeStatus = (item: SearchedIndividualSummary) => {
    if (item.eoIndividualId && item.ecIndividualId) return t('IECEO')
    if (item.eoIndividualId) return t('IEO')
    if (item.ecIndividualId) return t('IEC')
    return '-'
  }

  const statusAdvanceReport: AnalysisStatus =
    item?.reportType === 'P2' ? 'SUCCESS' : item.additionalAnalysis

  return (
    <TableRow hover onClick={handleClick}>
      <TableCell align='center' padding='none'>
        <Checkbox
          checked={checked}
          inputProps={{'aria-labelledby': labelId}}
          size='small'
        />
      </TableCell>
      <TableCell align='center' padding='none'>
        {item.id}
      </TableCell>
      <TableCell align='center' padding='none'>
        {idnAnalId}
      </TableCell>
      <TableCell align='center' padding='none'>
        {reAnalId}
      </TableCell>
      <TableCellReport
        align='center'
        padding='none'
        reportId={item.id}
        isMainReport={
          isDefined(item.finalAnalysisYn) && item.finalAnalysisYn === 'Y'
        }
        onClick={handleMainReportClick}
      >
        {item.report}
      </TableCellReport>
      <TableCell align='center' padding='none'>
        <ClientName
          firstName={item.firstName}
          lastName={item.lastName}
          birth={item.birth}
          gender={item.gender}
        />
      </TableCell>
      <TableCell align='center' padding='none'>
        {renderEyeStatus(item)}
      </TableCell>
      <TableCell align='center' padding='none'>
        {tfileName}
      </TableCell>
      <TableCell align='center' padding='none'>
        {isoStringToDateAndTimeString(String(item.measureDate))}
      </TableCell>
      <TableCell align='center' padding='none'>
        {item.normdbVersion || '-'}
      </TableCell>
      <TableCell align='center' padding='none'>
        {t(statusToI18nString(item.progress as AnalysisStatus))}
      </TableCell>
      <TableCell align='center' padding='none'>
        {t(statusToI18nString(statusAdvanceReport))}
      </TableCell>
      <TableCell align='center' padding='none'>
        {item.progress === 'SUCCESS' && (
          <TableButton onClick={handleResultClick}>{t('IRead')}</TableButton>
          // <TableButton onClick={handleResultClick}>{item.summaryType}</TableButton>
        )}
      </TableCell>
    </TableRow>
  )
}

function PageTable() {
  const {t} = useTranslation()
  const classes = useStyles()

  const {loading, query, paging, pagingInfo, items, onSearch} =
    useEegSummarySearch()

  // const [sortedItems, setSortedItems] = useState<SearchedIndividualSummary[]>(
  //   [],
  // )

  // useEffect(() => {
  //   setSortedItems(sortByParentIndividualId(items))
  // }, [items])

  const {onOpen: onFailureModalOpen} = useFailureModal()

  const [selected, setSelected] = useState<SearchedIndividualSummary[]>([])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(items.filter(isUpdatable))
      return
    }
    setSelected([])
  }

  const handleRowClick = (item: SearchedIndividualSummary) => {
    if (!isUpdatable(item)) {
      onFailureModalOpen(t('IAnalysisUnselectable_notUpdatable'))
      return
    }

    const selectedIndex = selected.indexOf(item)
    let newSelected: SearchedIndividualSummary[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const isRowSelected = (item: SearchedIndividualSummary) =>
    selected.indexOf(item) !== -1

  const selectableItems = items.filter(isUpdatable)

  const emptyRows = items === null ? 10 : 10 - items.length
  //
  const setPageIndex = (page: number) =>
    onSearch({
      ...query,
      paging: {
        page,
        size: paging.size,
      },
    })

  const onSizeChanged = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const size = parseInt(event.target.value as string, 10)
    onSearch({
      ...query,
      paging: {
        page: 0,
        size,
      },
    })
  }

  return (
    <TableContainer>
      <TableToolbar selected={selected} clearSelected={() => setSelected([])} />
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell align='center' padding='none'>
              <Checkbox
                indeterminate={
                  selected.length > 0 &&
                  selected.length < selectableItems.length
                }
                checked={
                  selectableItems.length > 0 &&
                  selected.length === selectableItems.length
                }
                onChange={handleSelectAllClick}
                size='small'
                inputProps={{'aria-label': 'select all eeg summary'}}
              />
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IAnalysisNo')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IEEGIndividualIndex')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IReAnalysis_no')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IMainReport')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IPatientName')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IECEO')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IFileName')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IMeasureDate')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IVersion')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IProgress')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IPremium')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IAnalysisReport')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <RenderTableRow
              key={item.id}
              item={item}
              onClick={handleRowClick}
              isSelected={isRowSelected}
            />
          ))}
          {emptyRows > 0 && (
            <TableRow
              style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
            >
              <TableCell colSpan={14} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        totalPageCount={pagingInfo.totalPages}
        currentPageIndex={query.paging.page ?? 0}
        itemCountPerPage={query.paging.size ?? 10}
        setCurrentPageIndex={setPageIndex}
        loading={loading}
        onItemCountPerPageChanged={onSizeChanged}
      />
    </TableContainer>
  )
}

export default PageTable
