import React from 'react'
import CardWithTitle from 'components/atoms/CardWithTitle'
import PageTable from 'pages/HealthCenter/PageTable'
import PageTableWhiteList from 'pages/HealthCenter/PageTableWhiteList'
import {getFeatures} from 'api/storageApi'
import {TYPE_WHITE_LIST} from 'constants/CommonConstant'

const HealthCenter = () => {
  const typeWhiteList = JSON.parse(getFeatures() ?? '[]')

  return (
    <CardWithTitle>
      {TYPE_WHITE_LIST.every((item) => typeWhiteList.includes(item)) ? (
        <PageTableWhiteList />
      ) : (
        <PageTable />
      )}
    </CardWithTitle>
  )
}

export default HealthCenter
