import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import PersonIcon from '@material-ui/icons/Person'
import SubTitle from 'components/Text/SubTitle'
import {HandValue, toGenderString, toHandString} from 'constants/CommonConstant'
import {useReadDialog} from 'features/patient/usePatientDialog'
import {PatientActivityInfo} from 'pages/Home/PatientActivityInfo'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const VDivider = styled.div`
  display: inline-block;
  width: 1px;
  height: 120px;
  min-height: 1.438rem;
  background-color: #e4e7eb;
`

const StyledCustomerCard = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;

  & .Left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;

    & .Info {
      display: flex;
      background-color: #f4f4f7;
      gap: 30px;
      padding: 30px;
      min-width: 337px;
      position: relative;

      & .Avatar {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;

        & .Image {
          width: 70px;
          height: 70px;

          & .MuiSvgIcon-root {
            font-size: 3rem;
          }
        }

        & .Label {
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          color: #808080;
        }
      }

      & .InfoItems {
        flex: 3;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-self: center;
      }
    }
  }

  & .Right {
    flex: 1;

    & .Activity {
      min-width: 395px;
    }
  }
`

export interface PatientCardProps {
  uuid: string
  chartNo: string
  birth: string
  gender: string
  hand: string
  firstName: string
  lastName: string
}

export const PatientCard = ({
  uuid,
  chartNo,
  birth,
  gender,
  hand,
  firstName,
  lastName,
}: PatientCardProps) => {
  const {t} = useTranslation()
  const {onOpen} = useReadDialog()
  return (
    <StyledCustomerCard>
      <div className='Left'>
        <div>
          <div className='Info'>
            <div className='Avatar'>
              <Avatar className='Image'>
                <PersonIcon style={{fontSize: '3rem'}} />
              </Avatar>
              <span className='Label'>{chartNo}</span>
            </div>
            <VDivider />
            <div className='InfoItems'>
              <div>
                <span>{t('IBirthday')}:</span> {birth}
              </div>
              <div>
                <span>{t('IGender')}:</span>{' '}
                {t(toGenderString(gender as Gender))}
              </div>
              <div>
                <span>{t('IHandedness')}:</span>{' '}
                {t(toHandString(hand as HandValue))}
              </div>
            </div>
            <div style={{position: 'absolute', right: 20, top: 20}}>
              <Button
                variant='outlined'
                size='small'
                className='RowButton'
                onClick={(event) => {
                  event.stopPropagation()
                  onOpen(uuid)
                }}
              >
                {t('IDetailInfo')}
              </Button>
            </div>
          </div>
        </div>
        <div>
          <SubTitle>{t('IDiseaseCode')}</SubTitle>
          <div className='Activity'>{t('INotYetImplemented')}</div>
        </div>
      </div>
      <div className='Right'>
        <SubTitle>{t('IRecentActivity')}</SubTitle>
        <PatientActivityInfo
          className='Activity'
          uuid={uuid}
          clientName={t('IGetFullName', {firstName, lastName})}
        />
      </div>
    </StyledCustomerCard>
  )
}
