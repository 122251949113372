import request, {fileRequest} from 'api/index'

export const fetchMeApi = () =>
  request<ModelResponse<User>>({
    method: 'get',
    path: '/api/org/user/v1/me',
  })

export interface UserInfoRequest {
  uid: string
}

export const fetchUserDetailInfoApi = (query: UserInfoRequest) =>
  request<ModelResponse<UserDetailInfo>>({
    method: 'get',
    path: '/api/org/user/v1/detail',
    params: query,
  })

const industryToArray = (data: Industry) =>
  `${data.id},${data.depth},${data.title},${data.parent}`

export interface OrgUserCreateRequest {
  firstName: string
  lastName: string
  email: string
  licenseNumber?: string | null
  deptId: number
  userType: string
  profile?: File | null
  tel?: string | null
  division?: Industry | null
  position?: Industry | null
  eegVersionId?: number | null
  hrvVersionId?: number | null
  deptName?: string | null
}

export const createOrgUserApi = (request: OrgUserCreateRequest) => {
  const {profile, ...params} = request
  const newParams: Record<string, any> = {...params}
  if (newParams.division) {
    const {division} = newParams
    newParams.division = industryToArray(division)
  }
  if (newParams.position) {
    const {position} = newParams
    newParams.position = industryToArray(position)
  }

  return fileRequest<CommonResponse>({
    method: 'post',
    path: '/api/org/user/v1/create',
    data: {
      profile,
    },
    params: newParams,
  })
}

export interface OrgUserUpdateRequest
  extends Partial<Omit<OrgUserCreateRequest, 'division' | 'position'>> {
  uid: string
  divisionId?: number | null
  positionId?: number | null
  authority?: string | null
}

export const updateUserDetailInfoApi = (data: OrgUserUpdateRequest) => {
  const {profile, ...params} = data

  return fileRequest<CommonResponse>({
    method: 'post',
    path: '/api/org/user/v1/update',
    data: {
      profile,
    },
    params,
  })
}

export interface OrgUsersDeleteRequest {
  uid: string
  uidList: string[]
  password: string
}

export const deleteOrgUserApi = (data: OrgUsersDeleteRequest) =>
  request<ModelsResponse<OrgUsersDeleteRequest>>({
    method: 'post',
    path: '/api/org/user/v1/delete',
    data,
  })

interface UserSearch {
  searchName?: UserSearchKind | null
  searchValue?: string | null
}

interface UserSearchRequest extends PagingRequest, OptionalPeriodRequest {
  search: UserSearch
}

export interface OrgUserSearchRequest extends UserSearchRequest {
  uid?: string
}

export const searchUserApi = ({
  uid,
  paging,
  period,
  search,
}: OrgUserSearchRequest) =>
  request<PageResponse<SearchedUser>>({
    method: 'get',
    path: '/api/org/user/v1/list',
    params: {
      uid,
      ...paging,
      ...period,
      ...search,
    },
  })

export const fetchOrgUsersApi = () =>
  request<ModelsResponse<OrgSimpleUser>>({
    method: 'get',
    path: '/api/org/user/v1/simple-list',
  }).then((response) => {
    return {
      ...response,
      list: response.list.map((user) => ({...user})),
    }
  })
