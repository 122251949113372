import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import {
  FetchMenuList,
  fetchPageManagementListApi,
  Menu,
  PageManagementRequest,
  updatePageManagementApi,
} from 'api/salesApi'
import TableButton from 'components/atoms/Buttons/TableButton'
import CardWithTitle from 'components/atoms/CardWithTitle'
import SalesOrganizationEditDialog from 'components/Dialog/Sales/Organization/Edit'
import SalesOrganizationReadDialog from 'components/Dialog/Sales/Organization/Read'
import SearchBar from 'components/Forms/SearchBar'
import Pagination from 'components/molcules/Pagination'
import {openSalesOrgReadDialog} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import React, {useEffect} from 'react'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import {useTranslation} from 'react-i18next'
import {useQuery, useQueryClient} from 'react-query'
import {useDispatch} from 'react-redux'

const useTableStyles = makeStyles(() =>
  createStyles({
    container: {
      marginBottom: 15,
    },
    table: {
      minWidth: 750,
      minHeight: 500,

      '& td': {
        padding: 12,
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableHeader: {
      height: 50,
      '& th': {
        padding: 12,
        '&:nth-child(2)': {
          maxWidth: 100,
        },
        '&:last-child': {
          maxWidth: 100,
        },
      },
    },
    tableCell: {width: 150},
    hover: {
      height: 50,
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    rowButton: {
      minWidth: 65,
      alignSelf: 'center',
    },
    search: {
      width: 300,
      marginBottom: 10,
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
    },
    searchTitle: {
      fontSize: 13,
      color: '#666a6c',
      marginRight: 10,
    },
    checkbox: {
      '& label': {
        border: '1px solid #666a6c',
        width: 14,
        height: 14,
        boxSizing: 'border-box',
        borderRadius: 3,
        background: 'white',
        display: 'flex',
        margin: 'auto',
      },
      '& input[type="checkbox"]': {
        display: 'none',
      },
      '& input[type="checkbox"]:checked + label': {
        borderColor: '#0cc7c7',
        background: '#0cc7c7',
      },
      '& label:disabled': {
        border: '1px solid #666a6c',
        width: 14,
        height: 14,
        boxSizing: 'border-box',
        borderRadius: 3,
        background: 'white',
        display: 'flex',
        margin: 'auto',
      },

      '& .arrowIcon': {
        width: 13,
        height: 13,
        marginBottom: 3,
        color: 'white',
        border: 1,
      },
    },
  }),
)

interface HeadCell {
  id: string
  label: string
}
const headCells: HeadCell[] = [
  {id: 'orgName', label: 'IOrgName'},
  {id: 'detail', label: '상세보기'},
  {id: 'edf', label: 'IEdf'},
  {id: 'eeg', label: 'EEG'},
  {id: 'eegIndv', label: 'EEG indv'},
  {id: 'eegSummary', label: 'EEG요약지'},
  {id: 'eegNorm', label: 'EEGNorm'},
  {id: 'eegMCI', label: 'MCI'},
  {id: 'hrv', label: 'HRV'},
  {id: 'hrvIndv', label: 'HRV indv'},
  {id: 'hrvSummary', label: 'HRV 결과지'},
  {id: 'therapy', label: '테라피 관리'},
  {id: 'therapyLed', label: 'LED 테라피'},
  {id: 'change', label: '변경'},
]

const TableHeader = () => {
  const classes = useTableStyles()
  const {t} = useTranslation()
  return (
    <>
      <TableHead>
        <TableRow className={classes.tableHeader}>
          {headCells.map((headCell) => (
            <TableCell key={headCell.id} align='center' style={{width: 140}}>
              {t(headCell.label)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  )
}

interface TableRowProps {
  oid: string
  item: FetchMenuList
  menuList: Menu[]
  setOid: (oid: string) => void
}

const PageTableRow = ({oid, item, menuList, setOid}: TableRowProps) => {
  const classes = useTableStyles()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const queryClient = useQueryClient()

  const {onOpen: successAlertOpen} = useSuccessModal()
  const {onOpen: failureAlertOpen} = useFailureModal()

  const findChecked = (menuList: Menu[], keyName: string): boolean => {
    if (menuList) {
      const result = menuList.filter(
        (item) => item.name === keyName && item.useYn === 'Y',
      )
      if (result.length > 0) return true
    }
    return false
  }

  const [eegOpen, setEegOpen] = React.useState(findChecked(menuList, 'EEG'))
  const [hrvOpen, setHrvOpen] = React.useState(findChecked(menuList, 'HRV'))
  const [therapyOpen, setTherapyOpen] = React.useState(
    findChecked(menuList, 'THERAPY'),
  )

  const [isCheckedList, setCheckedList] = React.useState({
    EDF: findChecked(menuList, 'EDF'),
    EEG: findChecked(menuList, 'EEG'),
    HRV: findChecked(menuList, 'HRV'),
    EEG_I: findChecked(menuList, 'EEG_I'),
    EEG_S: findChecked(menuList, 'EEG_S'),
    EEG_N: findChecked(menuList, 'EEG_N'),
    EEG_M: findChecked(menuList, 'EEG_M'),
    HRV_I: findChecked(menuList, 'HRV_I'),
    HRV_S: findChecked(menuList, 'HRV_S'),
    THERAPY: findChecked(menuList, 'THERAPY'),
    THERAPY_L: findChecked(menuList, 'THERAPY_L'),
  })

  const handleDetail = () => dispatch(openSalesOrgReadDialog())

  const updateMenu = async (menuList: Menu[]) => {
    const result = await updatePageManagementApi({
      oid,
      menuList,
    })
    if (result.success) {
      successAlertOpen(t('IProcessSuccess'))
      queryClient.invalidateQueries('fetchMenuManagementList')
    }
    if (!result.success) failureAlertOpen(t('IProcessFail'))
  }
  const array: Menu[] = []

  const setArrayValue = (key: string, isChecked: boolean) => {
    menuList.forEach((item) => {
      if (item.name === key) {
        array.push({...item, useYn: isChecked ? 'Y' : 'N'})
      }
    })
    return array
  }
  const handleClick = () => {
    Object.entries(isCheckedList).forEach(([key, value]) =>
      setArrayValue(key, value),
    )
    updateMenu(array)
  }

  useEffect(() => {
    if (eegOpen === false) {
      setCheckedList({
        ...isCheckedList,
        EEG_I: false,
        EEG_S: false,
        EEG_N: false,
        EEG_M: false,
      })
    }
  }, [eegOpen])
  useEffect(() => {
    if (hrvOpen === false) {
      setCheckedList({
        ...isCheckedList,
        HRV_I: false,
        HRV_S: false,
      })
    }
  }, [hrvOpen])
  useEffect(() => {
    if (therapyOpen === false) {
      setCheckedList({
        ...isCheckedList,
        THERAPY: false,
        THERAPY_L: false,
      })
    }
  }, [therapyOpen])

  useEffect(() => {
    setCheckedList({
      EDF: findChecked(menuList, 'EDF'),
      EEG: findChecked(menuList, 'EEG'),
      HRV: findChecked(menuList, 'HRV'),
      EEG_I: findChecked(menuList, 'EEG_I'),
      EEG_S: findChecked(menuList, 'EEG_S'),
      EEG_N: findChecked(menuList, 'EEG_N'),
      EEG_M: findChecked(menuList, 'EEG_M'),
      HRV_I: findChecked(menuList, 'HRV_I'),
      HRV_S: findChecked(menuList, 'HRV_S'),
      THERAPY: findChecked(menuList, 'THERAPY'),
      THERAPY_L: findChecked(menuList, 'THERAPY_L'),
    })
  }, [menuList])

  return (
    <>
      <TableRow
        className={classes.tableHeader}
        onClick={() => {
          setOid(oid)
        }}
      >
        <TableCell align='center'>{item.orgName ?? '-'}</TableCell>
        <TableCell align='center'>
          <TableButton
            onClick={() => {
              handleDetail()
            }}
          >
            {t('IDetail')}
          </TableButton>
        </TableCell>
        <TableCell align='center' className={classes.checkbox}>
          <input
            type='checkbox'
            id={`${oid}Edf`}
            checked={isCheckedList.EDF}
            onClick={() => {
              setCheckedList({...isCheckedList, EDF: !isCheckedList.EDF})
            }}
          />
          {/* eslint-disable-next-line */}
          <label className='label' htmlFor={`${oid}Edf`}>
            <CheckIcon className='arrowIcon' />
          </label>
        </TableCell>
        <TableCell align='center' className={classes.checkbox}>
          <input
            type='checkbox'
            id={`${oid}Eeg`}
            checked={isCheckedList.EEG}
            onClick={() => {
              setCheckedList({...isCheckedList, EEG: !isCheckedList.EEG})
              setEegOpen(!isCheckedList.EEG)
            }}
          />
          {/* eslint-disable-next-line */}
          <label className='label' htmlFor={`${oid}Eeg`}>
            <CheckIcon className='arrowIcon' />
          </label>
        </TableCell>
        <TableCell align='center' className={classes.checkbox}>
          <input
            type='checkbox'
            id={`${oid}EegIndi`}
            checked={eegOpen ? isCheckedList.EEG_I : false}
            disabled={!eegOpen}
            onClick={() => {
              if (eegOpen) {
                setCheckedList({...isCheckedList, EEG_I: !isCheckedList.EEG_I})
              }
            }}
          />
          {/* eslint-disable-next-line */}
          <label className='label' htmlFor={`${oid}EegIndi`}>
            <CheckIcon className='arrowIcon' />
          </label>
        </TableCell>

        <TableCell align='center' className={classes.checkbox}>
          <input
            type='checkbox'
            id={`${oid}EegSummary`}
            checked={eegOpen ? isCheckedList.EEG_S : false}
            disabled={!eegOpen}
            onClick={() => {
              if (eegOpen) {
                setCheckedList({...isCheckedList, EEG_S: !isCheckedList.EEG_S})
              }
            }}
          />
          {/* eslint-disable-next-line */}
          <label className='label' htmlFor={`${oid}EegSummary`}>
            <CheckIcon className='arrowIcon' />
          </label>
        </TableCell>
        <TableCell align='center' className={classes.checkbox}>
          <input type='checkbox' id={`${oid}Summary`} disabled />
          <input
            type='checkbox'
            id={`${oid}EegNorm`}
            checked={eegOpen ? isCheckedList.EEG_N : false}
            disabled={!eegOpen}
            onClick={() => {
              if (eegOpen) {
                setCheckedList({...isCheckedList, EEG_N: !isCheckedList.EEG_N})
              }
            }}
          />
          {/* eslint-disable-next-line */}
          <label className='label' htmlFor={`${oid}EegNorm`}>
            <CheckIcon className='arrowIcon' />
          </label>
        </TableCell>
        <TableCell align='center' className={classes.checkbox}>
          <input type='checkbox' id={`${oid}Summary`} disabled />
          <input
            type='checkbox'
            id={`${oid}EegMci`}
            checked={eegOpen ? isCheckedList.EEG_M : false}
            disabled={!eegOpen}
            onClick={() => {
              if (eegOpen) {
                setCheckedList({...isCheckedList, EEG_M: !isCheckedList.EEG_M})
              }
            }}
          />
          {/* eslint-disable-next-line */}
          <label className='label' htmlFor={`${oid}EegMci`}>
            <CheckIcon className='arrowIcon' />
          </label>
        </TableCell>
        <TableCell align='center' className={classes.checkbox}>
          <input
            type='checkbox'
            id={`${oid}Hrv`}
            checked={isCheckedList.HRV}
            onClick={() => {
              setCheckedList({...isCheckedList, HRV: !isCheckedList.HRV})
              setHrvOpen(!isCheckedList.HRV)
            }}
          />
          {/* eslint-disable-next-line */}
          <label className='label' htmlFor={`${oid}Hrv`}>
            <CheckIcon className='arrowIcon' />
          </label>
        </TableCell>
        <TableCell align='center' className={classes.checkbox}>
          <input
            type='checkbox'
            id={`${oid}HrvIndi`}
            checked={hrvOpen ? isCheckedList.HRV_I : false}
            disabled={!hrvOpen}
            onClick={() => {
              setCheckedList({...isCheckedList, HRV_I: !isCheckedList.HRV_I})
            }}
          />
          {/* eslint-disable-next-line */}
          <label className='label' htmlFor={`${oid}HrvIndi`}>
            <CheckIcon className='arrowIcon' />
          </label>
        </TableCell>
        <TableCell align='center' className={classes.checkbox}>
          {/* <input type='checkbox' id={`${oid}Summary`} disabled /> */}
          <input
            type='checkbox'
            id={`${oid}HrvSummary`}
            checked={hrvOpen ? isCheckedList.HRV_S : false}
            disabled={!hrvOpen}
            onClick={() => {
              setCheckedList({...isCheckedList, HRV_S: !isCheckedList.HRV_S})
            }}
          />
          {/* eslint-disable-next-line */}
          <label className='label' htmlFor={`${oid}HrvSummary`}>
            <CheckIcon className='arrowIcon' />
          </label>
        </TableCell>
        <TableCell align='center' className={classes.checkbox}>
          {/* <input type='checkbox' id={`${oid}Summary`} disabled /> */}
          <input
            type='checkbox'
            id={`${oid}Therapy`}
            checked={therapyOpen ? isCheckedList.THERAPY : false}
            onClick={() => {
              setCheckedList({
                ...isCheckedList,
                THERAPY: !isCheckedList.THERAPY,
              })
              setTherapyOpen(!isCheckedList.THERAPY)
            }}
          />
          {/* eslint-disable-next-line */}
          <label className='label' htmlFor={`${oid}Therapy`}>
            <CheckIcon className='arrowIcon' />
          </label>
        </TableCell>
        <TableCell align='center' className={classes.checkbox}>
          {/* <input type='checkbox' id={`${oid}Summary`} disabled /> */}
          <input
            type='checkbox'
            id={`${oid}therapyLed`}
            checked={therapyOpen ? isCheckedList.THERAPY_L : false}
            disabled={!therapyOpen}
            onClick={() => {
              setCheckedList({
                ...isCheckedList,
                THERAPY_L: !isCheckedList.THERAPY_L,
              })
            }}
          />
          {/* eslint-disable-next-line */}
          <label className='label' htmlFor={`${oid}therapyLed`}>
            <CheckIcon className='arrowIcon' />
          </label>
        </TableCell>
        <TableCell align='center'>
          <TableButton onClick={handleClick}>{t('IOk')}</TableButton>
        </TableCell>
      </TableRow>
    </>
  )
}

export default function SalesPageManagementPage() {
  const tableClasses = useTableStyles()
  const [searchValue, setSearchValue] = React.useState('')
  const handleValue = (value: any) => setSearchValue(value)
  const queryClient = useQueryClient()

  const [query, setQuery] = React.useState<PageManagementRequest>({
    page: 0,
    size: 10,
  })
  const [oid, setOid] = React.useState('')
  const {isLoading, data} = useQuery(['fetchMenuManagementList', query], () =>
    fetchPageManagementListApi(query),
  )
  const setPageIndex = (page: number) => {
    queryClient.invalidateQueries('fetchMenuManagementList')
    setQuery({...query, page})
  }

  const handleSearch = () => {
    queryClient.invalidateQueries('fetchMenuManagementList')
    setQuery({...query, page: 0, searchName: 'ORG', searchValue})
  }

  return (
    <div>
      <CardWithTitle>
        <div className={tableClasses.search}>
          <div className={tableClasses.searchTitle}>기관명</div>
          <SearchBar
            onChangeValue={handleValue}
            onSearchEvent={handleSearch}
            disabledValue={false}
          />
        </div>

        <Table stickyHeader className={tableClasses.table}>
          <TableContainer>
            <TableHeader />
            <TableBody>
              {data?.data?.content &&
                data.data.content.map((item) => {
                  return (
                    <PageTableRow
                      oid={item.oid}
                      item={item}
                      setOid={setOid}
                      menuList={item.menuList}
                    />
                  )
                })}
            </TableBody>
            <Pagination
              currentPageIndex={data?.data.pageable.pageNumber ?? 0}
              itemCountPerPage={data?.data.size ?? 10}
              totalPageCount={data?.data.totalPages ?? 0}
              setCurrentPageIndex={setPageIndex}
              loading={isLoading}
              onItemCountPerPageChanged={(event) => {
                setQuery({
                  ...query,
                  size: parseInt(event.target.value as string, 10),
                })
              }}
            />
          </TableContainer>
        </Table>
      </CardWithTitle>
      <SalesOrganizationEditDialog oid={oid} />
      <SalesOrganizationReadDialog oid={oid} />
    </div>
  )
}
