import {AllUserRoles} from 'Authority'
import RouteConstant from 'constants/RouteConstant'
import CommonLayout from 'layouts/Common'
import InternalErrorPage from 'pages/ErrorPages/InternalErrorPage'
import MyPurchasePage from 'pages/MyPages/MyPurchasePage'
import React from 'react'
import {Route, Switch} from 'react-router-dom'
import RouteIf from 'routes/RouteIf'

/**
 * 마이페이지 관련 라우트
 */
const MyRoute = () => {
  return (
    <CommonLayout>
      <Switch>
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.MY_PURCHASE.path}
          exact
          component={MyPurchasePage}
        />
        <Route
          path={RouteConstant.ERROR_NOT_FOUND.path}
          component={InternalErrorPage}
        />
      </Switch>
    </CommonLayout>
  )
}

export default MyRoute
