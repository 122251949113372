import {
  AnalysisResultIndicator,
  AnalysisResultItem,
  IcaAnalysisResultIndicator,
} from 'api/analysisApi'
import classNames from 'classnames'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import useStyles from 'components/molcules/Report/ICAComponent.style'
import ReportHeader from 'components/Report/ReportHeader'
import {CodeType} from 'constants/AnalysisConstant'
import {
  getAllComponentImagesUrl,
  getAllTimeSeriesUrl,
} from 'constants/RouteConstant'
import useFailureModal from 'features/modal/useFailureModal'
import React, {
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import {useTranslation} from 'react-i18next'
import Carousel from 'react-material-ui-carousel'
import NewWindow, {NewWindowRef, WindowFeatures} from 'components/NewWindow'

interface CarouselButtonProps {
  index: number
  currentIndex: number
  onClick: VoidFunction
  children: ReactNode
}

function CarouselButton({
  index,
  currentIndex,
  onClick,
  children,
}: CarouselButtonProps) {
  const classes = useStyles()
  const buttonClasses = classNames({
    [classes.carouselButton]: true,
    [classes.carouselButtonActive]: index === currentIndex,
  })

  return (
    <button className={buttonClasses} onClick={onClick} type='button'>
      {children}
    </button>
  )
}

interface ArtifactComponentProps {
  items: number[]
}

function ArtifactComponent({items}: ArtifactComponentProps) {
  const classes = useStyles()
  return (
    <div className={classes.artifactRoot}>
      <div className={classes.artifactTitle}>Artifact Component No.</div>
      <div className={classes.artifactComponentsRoot}>
        {items.map((item) => (
          <div className={classes.artifactComponent} key={item}>
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}

interface ICAComponentProps {
  indicator: AnalysisResultIndicator | IcaAnalysisResultIndicator
  items: AnalysisResultItem[]
  codeType: CodeType
  componentList: number[]
  hideDescription?: boolean
  hideAllComponentTimeSeries?: boolean
}

function ICAComponent({
  indicator,
  items,
  codeType,
  componentList,
  hideDescription = false,
  hideAllComponentTimeSeries = false,
}: ICAComponentProps) {
  const {t} = useTranslation()
  const classes = useStyles()

  const {onOpen: onFailureModalOpen} = useFailureModal()

  const [carouselIndex, setCarouselIndex] = useState(0)

  // 처음 한개는 pdf용. 스킵한다.
  const components = useMemo(() => items.slice(1), [items])

  const first = useMemo(() => items[0], [items])

  const getFirstId = useCallback(() => {
    if (codeType === 'I') return first.requestId ?? -1
    if (codeType === 'N') return first.normativeId ?? -1
    return -1
  }, [first, codeType])

  const onPopupBlocked = () => onFailureModalOpen(t('IAllowPopupPlease'))
  const Features: WindowFeatures = {
    width: 1500,
    height: 800,
  }

  const timeSeriesRef = useRef<NewWindowRef>()
  const [isAllTimeSeriesOpen, setIsAllTimeSeriesOpen] = useState(false)
  const onTimeSeriesUnload = () => setIsAllTimeSeriesOpen(false)

  const allImagesRef = useRef<NewWindowRef>()
  const [isAllImagesOpen, setIsAllImagesOpen] = useState(false)
  const onAllImagesUnload = () => setIsAllImagesOpen(false)

  const onAllImagesClick = () => {
    setIsAllImagesOpen(true)
    // @ts-ignore
    allImagesRef.current?.window?.focus()
  }

  const onTimeSeriesClick = () => {
    setIsAllTimeSeriesOpen(true)
    // @ts-ignore
    timeSeriesRef.current?.window?.focus()
  }

  return (
    <div className={classes.root}>
      <ReportHeader
        index={indicator.codeSort}
        title={indicator.codeName}
        description={indicator.titleDescription}
        hideDescription={hideDescription}
        buttons={
          <Fragment>
            {!hideAllComponentTimeSeries && (
              <ActionButton onClick={onTimeSeriesClick}>
                {t('IAllComponentsTimeSeries')}
              </ActionButton>
            )}
            <ActionButton onClick={onAllImagesClick}>
              {t('IAllImages')}
            </ActionButton>
          </Fragment>
        }
      />

      {componentList.length > 0 && <ArtifactComponent items={componentList} />}

      <Carousel
        index={carouselIndex}
        autoPlay={false}
        animation='slide'
        indicators={false}
        timeout={200}
        cycleNavigation={false}
        navButtonsAlwaysVisible
        onChange={(value: number) => {
          if (carouselIndex === value) return

          setCarouselIndex(value)
        }}
      >
        {components.map((item) => (
          <div className={classes.carouselWrapper} key={item.imgPath}>
            <img src={item.imgPath} alt={item.imgPath} />
          </div>
        ))}
      </Carousel>
      <div className={classes.carouselButtonContainer}>
        {components.map((item) => (
          <CarouselButton
            index={item.componentType - 1}
            key={item.componentType}
            currentIndex={carouselIndex}
            onClick={() => setCarouselIndex(item.componentType - 1)}
          >
            {item.componentType}
          </CarouselButton>
        ))}
      </div>

      {isAllTimeSeriesOpen && (
        <NewWindow
          title='All time series'
          name='TimeSeries'
          url={getAllTimeSeriesUrl(getFirstId(), codeType)}
          onUnload={onTimeSeriesUnload}
          onBlock={onPopupBlocked}
          features={Features}
          // @ts-ignore
          ref={timeSeriesRef}
        />
      )}
      {isAllImagesOpen && (
        <NewWindow
          title='All component images'
          name='AllImages'
          url={getAllComponentImagesUrl(getFirstId(), codeType)}
          onUnload={onAllImagesUnload}
          onBlock={onPopupBlocked}
          features={Features}
          // @ts-ignore
          ref={allImagesRef}
        />
      )}
    </div>
  )
}

export default ICAComponent
