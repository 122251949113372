import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import useHealthCenterStyles from 'pages/HealthCenter/Report/HealthCenterMajorIndicators/Style'
import down from 'pages/HealthCenter/Report/HealthCenterMajorIndicators/down.svg'

interface Props {
  headerName: string
  percentage: number
  index: number
  top: string
  middle: string
  bottom: string
  showIndex?: boolean
}

function PercentBarCardV2({
  headerName,
  percentage,
  index,
  top,
  middle,
  bottom,
  showIndex,
}: Props) {
  const classes = useHealthCenterStyles()
  const {t} = useTranslation()
  const getTextInRound = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return top
    }
    if (Math.floor(percentage) > 70) {
      return bottom
    }
    return middle
  }, [percentage])

  const getColorInRound = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return '#FF0404'
    }
    if (Math.floor(percentage) > 70) {
      return '#008BC9'
    }
    return '#B4B4B4'
  }, [percentage])

  const getBackGroundColor = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return 'rgba(255, 4, 4, 0.05)'
    }
    if (Math.floor(percentage) > 70) {
      return 'rgba(32, 162, 220, 0.05)'
    }
    return 'rgba(127, 136, 146, 0.05)'
  }, [percentage])

  const getBackGroundIndex = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return '#FF6666'
    }
    if (Math.floor(percentage) > 70) {
      return '#20A2DC'
    }
    return ''
  }, [percentage])

  const getColorText = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return '#FF0404'
    }
    if (Math.floor(percentage) > 70) {
      return '#008BC9'
    }
    return ''
  }, [percentage])

  const getBorderTop = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return '5px solid #FF0404'
    }
    if (Math.floor(percentage) > 70) {
      return '5px solid #008BC9'
    }
    return ''
  }, [percentage])

  const getBorderBottom = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return '1px solid #FF0404'
    }
    if (Math.floor(percentage) > 70) {
      return '1px solid #008BC9'
    }
    return ''
  }, [percentage])

  return (
    <div
      className={classes.percentBarV2}
      style={{
        background: getBackGroundColor,
        borderTop: getBorderTop,
        borderBottom: getBorderBottom,
      }}
    >
      <div className={classes.percentBarV2Top}>
        <div className={classes.topScore}>
          {!showIndex && (
            <div
              className={classes.topScoreIndex}
              style={{background: getBackGroundIndex}}
            >
              {index}
            </div>
          )}

          <div className={classes.topScoreDetail}>
            <span
              className={classes.topScorePosition}
              style={{color: getColorText}}
            >
              {headerName}
            </span>
            <div
              className={classes.topScorePercent}
              style={{color: getColorText}}
            >
              <span
                className={classes.topScorePercentScore}
                style={{color: getColorText}}
              >
                {percentage}
              </span>
              {t('IPoints')}
            </div>
          </div>
        </div>
        <div
          className={classes.topStatus}
          style={{background: getColorInRound}}
        >
          {getTextInRound}
        </div>
      </div>
      <div className={classes.percentBarV2Bot}>
        <img
          className={classes.percentBarV2Arrow}
          style={{marginLeft: `${percentage - 1}%`}}
          src={down}
          alt='icon'
        />
        <div className={classes.botBar}>
          <div>
            <div className={classes.marker3} style={{left: '30%'}}>
              <span />
              <p>{top}</p>
            </div>
            <div className={classes.marker3} style={{left: '50%'}}>
              <div />
              <p>{middle}</p>
            </div>
            <div className={classes.marker3} style={{left: '70%'}}>
              <span />
              <p>{bottom}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PercentBarCardV2
