import {OrgDetailInfoEditRequest} from 'api/organizationApi'
import {
  fetchOrgReadAction,
  fetchOrgUpdateAction,
  selectData,
  selectError,
  selectLoading,
} from 'features/org/orgSlice'
import {useAppSelector} from 'hooks'
import {useAppDispatch} from 'store'

export default function useOrganization() {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading)
  const organization = useAppSelector(selectData)
  const error = useAppSelector(selectError)

  const onFetch = () => dispatch(fetchOrgReadAction())

  const onUpdate = (payload: OrgDetailInfoEditRequest) =>
    dispatch(fetchOrgUpdateAction(payload))

  return {
    loading,
    organization,
    error,
    onFetch,
    onUpdate,
  }
}
