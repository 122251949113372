import RouteConstant from 'constants/RouteConstant'
import {isNotDefined} from 'helpers/commonHelper'
import {generateDeviceId} from 'helpers/firebaseHelper'
import crypto from 'crypto-es'

const ACCESS_TOKEN = 'access_token'

export const setAccessToken = (token: string) => {
  const aesToken = crypto.AES.encrypt(token, 'secret key').toString()
  localStorage.setItem(ACCESS_TOKEN, aesToken)
}

export const getAccessToken = (): string | null => {
  const token = localStorage.getItem(ACCESS_TOKEN)
  if (token !== null) {
    const decryptToken = crypto.AES.decrypt(token, 'secret key')
    // @ts-ignore
    return decryptToken.toString(crypto.enc.Utf8)
  }
  return null
}

export const removeAccessToken = () => localStorage.removeItem(ACCESS_TOKEN)

const REFRESH_TOKEN = 'refresh_token'

export const setRefreshToken = (token: string) => {
  const aesToken = crypto.AES.encrypt(token, 'secret refresh key').toString()
  localStorage.setItem(REFRESH_TOKEN, aesToken)
}

export const getRefreshToken = (): string | null => {
  const token = localStorage.getItem(REFRESH_TOKEN)
  if (token !== null) {
    const decryptToken = crypto.AES.decrypt(token, 'secret refresh key')
    // @ts-ignore
    return decryptToken.toString(crypto.enc.Utf8)
  }
  return null
}

export const removeRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN)

const REDIRECT_FROM = 'redirect_from'

export const setRedirectFrom = (from: string) =>
  localStorage.setItem(REDIRECT_FROM, from)

export const getRedirectFrom = (): string | null =>
  localStorage.getItem(REDIRECT_FROM)

export const removeRedirectFrom = () => localStorage.removeItem(REDIRECT_FROM)

const LANGUAGE = 'lang'

const isLanguageValid = (lang: Nullable<string>): lang is LanguageCode => {
  if (isNotDefined(lang)) return false

  return lang === 'ko' || lang === 'en' || lang === 'ja'
}

export const setLanguage = (lang: Nullable<string>) => {
  if (isLanguageValid(lang)) {
    localStorage.setItem(LANGUAGE, lang)
  } else {
    console.log('잘못된 Language가 넘어왔습니다. 값을 저장하지 않습니다.')
  }
}

export const getLanguage = (): LanguageCode => {
  const lang = localStorage.getItem(LANGUAGE)
  if (isLanguageValid(lang)) {
    return lang
  }
  return 'ko'
}

const ADMIN_UID = 'admin_uid'

export const setAdminUid = (uid: string) => localStorage.setItem(ADMIN_UID, uid)

export const getAdminUid = () => localStorage.getItem(ADMIN_UID)

export const removeAdminUid = () => localStorage.removeItem(ADMIN_UID)

const USER_UID = 'user_uid'

export const setUserUid = (uid: string) => localStorage.setItem(USER_UID, uid)

export const getUserUid = () => localStorage.getItem(USER_UID)

export const removeUserUid = () => localStorage.removeItem(USER_UID)

const DEVICE_ID = 'device_id'

export const setDeviceId = (deviceId: string) =>
  localStorage.setItem(DEVICE_ID, deviceId ?? generateDeviceId(8))

export const getDeviceId = () => localStorage.getItem(DEVICE_ID)

export const getOrInitializeDeviceId = () => {
  const deviceId = getDeviceId()
  if (isNotDefined(deviceId)) {
    const newDeviceId = generateDeviceId()
    setDeviceId(newDeviceId)
    return newDeviceId
  }
  return deviceId
}

const ORGANIZATION_TYPE = 'organization_type'
const FEATURES = 'features'
const TWO_FACTOR_TYPE = 'two_factor_type'
const INPUT_TIMEOUT = 'input_timeout'

export const setOrganizationType = (uid: string) =>
  localStorage.setItem(ORGANIZATION_TYPE, uid)

export const getOrganizationType = () => localStorage.getItem(ORGANIZATION_TYPE)
export const getTwoFactorType = () => localStorage.getItem(TWO_FACTOR_TYPE)
export const getInputTimeout = () => localStorage.getItem(INPUT_TIMEOUT)

export const removeOrganizationType = () =>
  localStorage.removeItem(ORGANIZATION_TYPE)

export const removeTwoFactorType = () =>
  localStorage.removeItem(TWO_FACTOR_TYPE)

export const removeInputTimeout = () => localStorage.removeItem(INPUT_TIMEOUT)

const SELECTED_LANGUAGE = 'selected_language'

export const setLangSession = (data: string) =>
  sessionStorage.setItem(SELECTED_LANGUAGE, data)

export const getLangSession = () => sessionStorage.getItem(SELECTED_LANGUAGE)

export const removeLangSession = () =>
  sessionStorage.removeItem(SELECTED_LANGUAGE)

export const getFeatures = () => localStorage.getItem(FEATURES)
export const setFeatures = (feature: string) =>
  localStorage.setItem(FEATURES, feature)
export const setTwoFactorType = (type: string) =>
  localStorage.setItem(TWO_FACTOR_TYPE, type)

export const setInputTimeout = (time: string) =>
  localStorage.setItem(INPUT_TIMEOUT, time)
