import {ReportIcaAnalysisTypeMap} from 'constants/AnalysisConstant'
import IcaRawData from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaRawData'
import {RenderingItem} from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaReportFetcher'
import React from 'react'

export interface IcaReportItemProps {
  renderingItem: RenderingItem
}

export function TsrReportItem({renderingItem}: IcaReportItemProps) {
  const [indicator, items] = renderingItem

  return (
    <div>
      {indicator.codeId === ReportIcaAnalysisTypeMap.CleanedData && (
        <IcaRawData indicator={indicator} items={items} />
      )}
    </div>
  )
}
