import React, {MouseEventHandler} from 'react'
import {useTranslation} from 'react-i18next'
import Checkbox from '@material-ui/core/Checkbox'
import TableRow from '@material-ui/core/TableRow'
import TableButton from 'components/atoms/Buttons/TableButton'
import TableCell from 'components/Table/TableCell'
import TableCellReport from 'components/Table/TableCellReport'
import {SearchedHealthCare} from 'api/healthCareApi'
import {isDefined} from 'helpers/commonHelper'
import {RenderComponent} from 'pages/HealthCenter/PageTable'

interface RenderTableRowProps {
  item: SearchedHealthCare
  onClick: (item: SearchedHealthCare) => void
  onClickView: (item: SearchedHealthCare) => void
  onClickMainReport: (item: SearchedHealthCare) => void
  isSelected: (item: SearchedHealthCare) => boolean
  tableHeader: any[]
  getHdAnalysisTypes: (ids: number[]) => string
  status: string
}

function RenderTableRow({
  item,
  onClick,
  onClickView,
  onClickMainReport,
  isSelected,
  tableHeader,
  getHdAnalysisTypes,
  status,
}: RenderTableRowProps) {
  const checked = isSelected(item)
  const {t} = useTranslation()
  const listStatus = ['PARTIALLY_COMPLETED', 'SUCCESS']
  const checkStatus = (hcStatus: string, status: string) => {
    const isValidStatus = listStatus.includes(status)
    if (isValidStatus && hcStatus) {
      return false
    }
    return true
  }
  const handleClick = () => {
    if (checkStatus(item.hcStatus, status)) return
    onClick(item)
  }

  const handleClickView: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    onClickView(item)
  }

  const handleClickMainReport = () => onClickMainReport(item)

  const getAnalysisNumber = (item: SearchedHealthCare) => {
    if (item.ecId && item.eoId) return `EC: ${item.ecId}/ EO: ${item.eoId}`
    if (item.ecId) return `EC: ${item.ecId}`
    if (item.eoId) return `EO: ${item.eoId}`
    return '-'
  }

  return (
    <TableRow hover onClick={handleClick}>
      {tableHeader.map(({value, render}) => {
        const tempItem: any = {...item}
        if (value) {
          return <TableCell align='center'>{tempItem[value] || '-'}</TableCell>
        }
        if (!value && render) {
          if (typeof render === 'string') {
            if (render === RenderComponent.checkbox)
              return (
                <TableCell align='center' padding='checkbox'>
                  <Checkbox
                    disabled={checkStatus(item.hcStatus, status)}
                    checked={
                      !checkStatus(item.hcStatus, status) ? checked : false
                    }
                    inputProps={{
                      'aria-labelledby': `enhanced-table-checkbox-${item.hcId}`,
                    }}
                  />
                </TableCell>
              )

            if (render === RenderComponent.analysisNumber)
              return (
                <TableCell align='center'>{getAnalysisNumber(item)}</TableCell>
              )
            if (render === RenderComponent.downloadButton)
              return (
                <TableCell align='center'>
                  {(status === 'SUCCESS' ||
                    status === 'PARTIALLY_COMPLETED') && (
                    <TableButton onClick={handleClickView}>
                      {t('IDetail')}
                    </TableButton>
                  )}
                </TableCell>
              )
            if (render === RenderComponent.mainReport)
              return (
                <TableCellReport
                  align='center'
                  reportId={item.hcId}
                  isMainReport={isDefined(item.choice) && item.choice === 'Y'}
                  onClick={handleClickMainReport}
                >
                  {item.reportUrl}
                </TableCellReport>
              )
            if (render === RenderComponent.hcAnalysisTypes)
              return (
                <TableCell align='center'>
                  {getHdAnalysisTypes(item.hcAnalysisTypes)}
                </TableCell>
              )
          }
          return (
            <TableCell align='center' padding='checkbox'>
              {render(tempItem)}
            </TableCell>
          )
        }
        return <TableCell align='center'>-</TableCell>
      })}
    </TableRow>
  )
}

export default RenderTableRow
