import {makeStyles} from '@material-ui/core/styles'
import {Colors} from 'components/common/useBaseStyle'

const useHealthCenterBrainFunctionMappingStyles = makeStyles(() => ({
  root: {
    justifyContent: 'center',
  },
  brainFunctionMapping: {
    marginBottom: 68,
    width: 1280,
    padding: '40px 60px 80px 60px',
    background: '#fff',
    '@media print': {
      marginBottom: 0,
      paddingBottom: 0,
    },
  },
  contentDetailedAnalysis: {
    width: 670,
  },
  imgDetailedAnalysis: {
    width: 414,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textHeader: {
    fontFamily: '"Noto Sans KR"',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%',
    letterSpacing: '-0.6px',
    display: 'flex',
  },
  headerColorGreen: {
    color: '#00A29C',
  },
  headerColorBlack: {
    color: '#000',
  },
  textContent: {
    color: '#333',
    fontFamily: '"Noto Sans KR"',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '148%',
    letterSpacing: '-0.72px',
    paddingTop: 16,
  },
  scoreNumber: {
    color: '#000',
    fontFamily: '"Noto Sans KR"',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '100%',
    marginBottom: 8,
    textAlign: 'center',
  },
  scoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  scoreSpace: {
    padding: '12px 20px',
    height: 228,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  labelScore: {
    padding: '7px 20px',
    height: 30,
    width: 'max-content',
    textAlign: 'center',
    borderRadius: 30,
    color: '#FFF',
    fontFamily: '"Noto Sans KR"',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    '&.LoweringActive': {
      backgroundColor: '#EE3D3B',
      color: Colors.white,
    },
    '&.Balanced': {
      backgroundColor: '#B4B4B4',
      color: Colors.white,
    },
    '&.ExaltationActive': {
      backgroundColor: '#20A2DC',
      color: Colors.white,
    },
  },
  headerScore: {
    color: '#00A29C',
    fontFamily: '"Noto Sans KR"',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '122%',
    letterSpacing: '-0.54px',
    marginBottom: 8,
  },
  tableFiguresByPart: {
    width: '100%',
    marginBottom: 13,
    padding: '0 15px',
    '& thead tr th': {
      width: '116px',
      paddingTop: '8px',
      height: 32,
      background: '#7F8892',
      color: '#FFF',
      fontFamily: '"Noto Sans KR"',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '100%',
      letterSpacing: '-0.45px',
      borderLeft: '1px solid #fff',
      borderRight: '1px solid #fff',
    },
    '& tbody tr': {
      borderBottom: '1px solid #D9D9D9',
    },
    '& tbody tr td': {
      height: 119,
      padding: '16px 0',
    },

    '& tbody tr td:first-child p': {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#000',
      fontFamily: '"Noto Sans KR"',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '100%',
    },
  },
  secondCol: {
    width: 692,
    '& ul': {
      listStyleType: 'disc',
      padding: '0px 35px',
      color: '#333',
      fontFamily: '"Noto Sans KR"',
      fontSize: '17px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '148%',
      letterSpacing: '-0.68px',
    },
  },
  scoreCol: {
    width: 116,
    verticalAlign: 'middle',
  },
  flexColScore: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
  },
  widthMaxContent: {
    width: 'max-content',
  },
  firstCol: {
    width: 200,
    verticalAlign: 'middle',
  },

  textRow1: {
    fontFamily: 'Noto Sans KR',
    display: 'flex',
    padding: '0px 12px  12px  12px',
  },
  keyRow1: {
    color: '#00A29C',
    fontFamily: '"Noto Sans KR"',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '122%',
    letterSpacing: '-0.51px',
  },
  headerRow1: {
    color: '#000',
  },
  headerRow1Content: {
    color: '#000',
    fontFamily: '"Noto Sans KR"',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '122%',
    letterSpacing: '-0.51px',
  },
  headerRow1Note: {
    color: '#000',
    fontFamily: '"Noto Sans KR"',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '126%',
    letterSpacing: '-0.48px',
  },
  imgBrain: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    '& img': {
      width: 140,
      height: 105,
    },
    '@media print': {
      '& img': {
        width: 140,
        height: 105,
      },
    },
  },
  bottom16: {
    paddingBottom: 16,
  },
  firstColFlex: {
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
  },
  width100: {
    width: '100%',
  },
  marginBottom60: {
    marginBottom: 60,
  },
  imageOverviewBrain: {
    '& img': {
      width: 270,
      height: 228,
    },
  },
  textBottomTable: {
    color: '#333',
    fontFamily: '"Noto Sans KR"',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '148%',
    letterSpacing: '-0.72px',
    paddingLeft: 13,
  },
}))

export default useHealthCenterBrainFunctionMappingStyles
