import {AnalysisResultIndicator, AnalysisResultItem} from 'api/analysisApi'
import BandPowerTopoMap from 'components/molcules/Report/BandPowerTopoMap'
import ICAComponent from 'components/molcules/Report/ICAComponent'
import OccipitalAlphaPeak from 'components/molcules/Report/OccipitalAlphaPeak'
import PowerRatio from 'components/molcules/Report/PowerRatio'
import PowerSpectrum from 'components/molcules/Report/PowerSpectrum'
import RawData from 'components/molcules/Report/RawData'
import SourceROIPower from 'components/molcules/Report/SourceROIPower'
import ThreeDBanner from 'components/molcules/Report/ThreeDBanner'
import {
  AnalysisDivision,
  CodeType,
  ReportAnalysisTypeMap,
} from 'constants/AnalysisConstant'
import React from 'react'

export type RenderingItem = [AnalysisResultIndicator, AnalysisResultItem[]]

interface ReportItemProps {
  renderingItem: RenderingItem
  requestId: number
  codeType: CodeType
  codeTypeDivision: AnalysisDivision
  componentList: number[]
}

export default function ReportItem({
  renderingItem,
  requestId,
  codeType,
  codeTypeDivision,
  componentList,
}: ReportItemProps) {
  const [indicator, items] = renderingItem

  if (
    indicator.codeId !== ReportAnalysisTypeMap.ThreeDView &&
    items.length === 0
  )
    return null

  return (
    <div>
      {indicator.codeId === ReportAnalysisTypeMap.RawData && (
        <RawData indicator={indicator} items={items} />
      )}
      {indicator.codeId === ReportAnalysisTypeMap.ICAComponents && (
        <ICAComponent
          indicator={indicator}
          items={items}
          codeType={codeType}
          componentList={componentList}
        />
      )}
      {indicator.codeId === ReportAnalysisTypeMap.BandPower && (
        <BandPowerTopoMap
          indicator={indicator}
          items={items}
          requestId={requestId}
          codeType={codeType}
          codeTypeDivision={codeTypeDivision}
          codeId={indicator.codeId}
        />
      )}
      {indicator.codeId === ReportAnalysisTypeMap.PowerSpectrum && (
        <PowerSpectrum indicator={indicator} items={items} />
      )}
      {indicator.codeId === ReportAnalysisTypeMap.OccipitalAlphaPeak && (
        <OccipitalAlphaPeak indicator={indicator} items={items} />
      )}
      {indicator.codeId === ReportAnalysisTypeMap.PowerRatio && (
        <PowerRatio
          indicator={indicator}
          items={items}
          requestId={requestId}
          codeType={codeType}
          codeTypeDivision={codeTypeDivision}
          codeId={indicator.codeId}
        />
      )}
      {indicator.codeId === ReportAnalysisTypeMap.SourceROIPower && (
        <SourceROIPower indicator={indicator} items={items} />
      )}
      {indicator.codeId === ReportAnalysisTypeMap.ThreeDView && (
        <ThreeDBanner
          indicator={indicator}
          requestId={requestId}
          codeType={codeType}
        />
      )}
    </div>
  )
}
