import {isProd} from 'helpers/envHelper'
import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import {
  addPatientApi,
  editPatientApi,
  fetchPatientApi,
  PatientAddRequest,
  PatientEditRequest,
} from 'api/patientApi'
import {getHdAnalysisTypesApi, HdAnalysisTypes} from 'api/healthCareApi'
import {
  openFailureModalAction,
  openSuccessModalAction,
} from 'features/modal/alertSlice'
import {
  fetchPatientSearch,
  selectQuery,
} from 'features/patient/patientSearchSlice'
import i18n from 'i18n'
import {RootState} from 'store'

export const fetchPatientRead = createAsyncThunk(
  'api/patient/read',
  async (uuid: string, {rejectWithValue}) => {
    try {
      const response = await fetchPatientApi({uuid})
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const fetchHdAnalysisTypes = createAsyncThunk(
  'api/org/v1/healthcare-product',
  async (orgId: string, {rejectWithValue}) => {
    try {
      const response = await getHdAnalysisTypesApi(orgId)
      return response.list
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const createPatient = createAsyncThunk(
  'patient/create',
  async (payload: PatientAddRequest, {dispatch, rejectWithValue}) => {
    try {
      if (isProd()) {
        const result = await addPatientApi(payload)
        await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
        return result
      }
      if (!isProd()) {
        const result = await addPatientApi(payload)
        await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
        return result
      }
      return ''
    } catch (err) {
      openFailureModalAction(err.message)
      return rejectWithValue(err)
    }
  },
)

export const updatePatient = createAsyncThunk(
  'patient/update',
  async (payload: PatientEditRequest, {dispatch, getState}) => {
    try {
      const result = await editPatientApi(payload)
      console.log(result, 'resutl?')
      if (result.success) {
        await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
        const state = getState() as RootState
        const query = selectQuery(state)
        await dispatch(fetchPatientRead(payload.uuid))
        await dispatch(fetchPatientSearch(query))
      }
      console.log(result, 'resutl')
      return result
    } catch (err) {
      openFailureModalAction(err.message)
      return err
    }
  },
)

export const openReadDialog = createAction<string>('patient_dialog/read/open')
export const closeReadDialog = createAction('patient_dialog/read/close')

export const openUpdateDialog = createAction<string>(
  'patient_dialog/update/open',
)
export const closeUpdateDialog = createAction('patient_dialog/update/close')

export const openCreateDialog = createAction('patient_dialog/create/open')
export const closeCreateDialog = createAction('patient_dialog/create/close')

interface PatientState {
  uuid?: string
  entity?: Patient
  loading: boolean
  error: unknown
  readDialogOpen: boolean
  createDialogOpen: boolean
  updateDialogOpen: boolean
  listProducts: HdAnalysisTypes[]
}

export const initialState: PatientState = {
  readDialogOpen: false,
  createDialogOpen: false,
  updateDialogOpen: false,
  loading: false,
  error: null,
  listProducts: [],
}

export const patientSlice = createSlice({
  name: 'patientSlice',
  initialState,
  reducers: {
    openReadDialog(state, action: PayloadAction<string>) {
      state.readDialogOpen = true
      state.uuid = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(openReadDialog, (state, action) => {
        state.readDialogOpen = true
        state.uuid = action.payload
      })
      .addCase(closeReadDialog, (state) => {
        state.entity = {} as Patient
        state.readDialogOpen = false
      })
      .addCase(openUpdateDialog, (state, action) => {
        state.updateDialogOpen = true
        state.uuid = action.payload
      })
      .addCase(closeUpdateDialog, (state) => {
        state.entity = {} as Patient
        state.updateDialogOpen = false
      })
      .addCase(openCreateDialog, (state) => {
        state.createDialogOpen = true
      })
      .addCase(closeCreateDialog, (state) => {
        state.createDialogOpen = false
      })
      .addCase(fetchPatientRead.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchPatientRead.fulfilled, (state, action) => {
        state.loading = false
        state.entity = action.payload
      })
      .addCase(fetchPatientRead.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(fetchHdAnalysisTypes.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchHdAnalysisTypes.fulfilled, (state, action) => {
        state.loading = false
        state.listProducts = action.payload
      })
      .addCase(fetchHdAnalysisTypes.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  },
})

export const selectEntity = (state: RootState) => ({
  entity: state.patient.entity,
  loading: state.patient.loading,
  error: state.patient.error,
})

export const selectReadDialog = (state: RootState) => ({
  entity: state.patient.entity,
  open: state.patient.readDialogOpen,
  uuid: state.patient.uuid,
  listProducts: state.patient.listProducts,
})

export const selectUpdateDialog = (state: RootState) => ({
  entity: state.patient.entity,
  open: state.patient.updateDialogOpen,
  uuid: state.patient.uuid,
  listProducts: state.patient.listProducts,
})

export const selectCreateDialog = (state: RootState) => ({
  open: state.patient.createDialogOpen,
  listProducts: state.patient.listProducts,
})

export const selectProducts = (state: RootState) => ({
  listProducts: state.patient.listProducts,
  loading: state.patient.loading,
  error: state.patient.error,
})

export default patientSlice.reducer
