import {
  IconBigSmallErrorButtonStyles,
  IconBigSmallPrimaryButtonStyles,
} from '../components/V2Buttons/IconBigSmallButton/Styles'
import i18n from '../i18n'

/**
 * color hex to rgba
 * @param input
 * @param control: add number if you want a little bit changing color
 * @param opacity: number only 0~1
 * @returns
 */
export const hexToRgba = (
  input: number | string,
  control?: number,
  transparency?: number,
) => {
  const hex6 = String(input).trim().replace('#', '')

  const rgb =
    hex6.length === 3 ? hex6.match(/[a-f\d]/gi) : hex6.match(/[a-f\d]{2}/gi)

  if (rgb) {
    rgb.forEach((str, x, arr) => {
      // 헥사값이 한자리일 경우 두자리로 변경
      if (str.length === 1) str += str

      // 10진수로 변환
      arr[x] = String(parseInt(str, 16))

      if (control) {
        arr[x] =
          Number(arr[x]) + control > 255
            ? '255'
            : String(Number(arr[x]) + control)
      }
    })
  }

  const alpha: number = transparency ?? 1

  return `${rgb?.join(', ') ?? '0, 0, 0'}, ${alpha > 1 ? 1 : alpha}`
}

/**
 * - open이 토글되는 특정 엘리먼트(셀렉트 박스 등)를 열고 다른 영역에서 마우스 다운하면 닫는 함수
 * - 참고: V2Select - SpreadableSelectBox.tsx
 * @param e : event
 * @param ref : want close element
 * @param setState : want change boolean state: true to be false
 */
export const handleClickOutside = (
  e: MouseEvent | TouchEvent,
  ref: React.RefObject<any>,
  setState: (value: React.SetStateAction<boolean>) => void,
) => {
  // when ref opened and other side mousedown
  if (ref.current !== null && !ref.current.contains(e.target as Node)) {
    setState(false)
  }
}

export const getIconButtonTheme = (progress: string | boolean) => {
  // if (progress === 'Success') return IconBigSmallPrimaryButtonStyles
  // if (progress === 'ICA') return IconBigSmallPrimaryButtonStyles
  // if (progress === 'Post processing') return IconBigSmallPrimaryButtonStyles
  // if (progress === 'Completed') return IconBigSmallPrimaryButtonStyles
  // if (progress === 'Error') return IconBigSmallErrorButtonStyles
  // return IconBigSmallErrorButtonStyles
  if (progress === i18n.t('ISuccess')) return IconBigSmallPrimaryButtonStyles
  if (progress === i18n.t('IStatus_ica')) return IconBigSmallPrimaryButtonStyles
  if (progress === i18n.t('IPostProcessing'))
    return IconBigSmallPrimaryButtonStyles
  if (progress === i18n.t('IStatus_success'))
    return IconBigSmallPrimaryButtonStyles
  if (progress === i18n.t('IError')) return IconBigSmallErrorButtonStyles

  if (progress === true) return IconBigSmallPrimaryButtonStyles
  return IconBigSmallErrorButtonStyles
  // return IconBigSmallDisabledButtonStyles
}

/*
  - handleClickOutside 추가할 컴포넌트에서

  // ref 생성
  const myRef = React.useRef<HTMLDivElement>(null)

  // boolean state 생성
  const [isOpen, setIsOpen] = React.useState(false)

  // mousedown 이벤트 추가, isOpen 바뀔 때 마다 실행
  useEffect(() => {
    // ref가 open 상태일 때 실행
    if (isOpen) {
      window.addEventListener('mousedown', (e) =>
        handleClickOutside(e, myRef, setIsOpen),
      )
    }

    // 중첩 실행 방지
    return () => {
      window.removeEventListener('mousedown', (e) =>
        handleClickOutside(e, myRef, setIsOpen),
      )
    }
  }, [isOpen])
*/
