import React, {MouseEventHandler, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  executeEegMciAnalysisApi,
  executeIndividualAdditionalAnalysisApi,
  executeNormalEegSummaryApi,
  SearchedIndividualEEG,
} from 'api/analysisApi'
import * as storage from 'api/storageApi'
import {Roles, isOrganizationHealth} from 'Authority'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import TableButton from 'components/atoms/Buttons/TableButton'
import {ClientName} from 'components/atoms/ClientName'
import AnalysisSearch from 'components/molcules/AnalysisSearch'
import CompanySearch from 'components/molcules/CompanySearch'
import Pagination from 'components/molcules/Pagination'
import PeriodSearch from 'components/molcules/PeriodSearch'
import TableCell from 'components/Table/TableCell'
import TableCellShortStatus from 'components/Table/TableCellShortStatus'
import TableReportSearch from 'components/Table/TableReportSearch'
import useStyles from 'components/Table/useTableStyles'
import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import {AnalysisReportType} from 'constants/AnalysisConstant'
import {getEegIndividualReportPath} from 'constants/RouteConstant'
import {useAnalysisConfirm} from 'features/analysis/useAnalysisConfirm'
import {useIcaSelectDialog} from 'features/analysis/useEegIndividualIcaDialog'
import useEegIndividualNormDialog from 'features/analysis/useEegIndividualNormDialog'
import useEegIndividualSearch from 'features/analysis/useEegIndividualSearch'
import useAuth from 'features/auth/useAuth'
import useFailureModal from 'features/modal/useFailureModal'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import useSuccessModal from 'features/modal/useSuccessModal'
import {
  getEyeStatus,
  isReadable,
  statusToI18nString,
} from 'helpers/analysisHelper'
import {
  dateToPeriodString,
  getDefaultPeriodDate,
  isoStringToDateAndTimeString,
} from 'helpers/dateHelper'
import {getQueryFromUrl} from 'helpers/commonHelper'
import {openInNewTab} from 'helpers/openInNewTab'
import {
  addPushReceiveHandler,
  removePushReceiveHandler,
} from 'helpers/pushHelper'
import {
  HealthCareReanalysisRequest,
  reanalysisHealthCareApi,
} from 'api/healthCareApi'

interface TableToolBarProps {
  selectedItems: SearchedIndividualEEG[]
}

interface AnyObject {
  [key: string]: any
}

function TableToolbar({selectedItems}: TableToolBarProps) {
  const {t} = useTranslation()
  const location = useLocation()
  const classes = useToolbarStyles()
  const {query, onSearch} = useEegIndividualSearch()
  const {onOpen: onAnalysisConfirm, onCancel: onAnalysisCancel} =
    useAnalysisConfirm()
  const {onOpen: onNormDialogOpen} = useEegIndividualNormDialog()
  const {onOpen: onIcaSelectDialogOpen} = useIcaSelectDialog()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()

  const [selection] = React.useState<PeriodDate>(getDefaultPeriodDate())

  const isSucceeded = (selectedItems: SearchedIndividualEEG[]): boolean => {
    const select = selectedItems.filter(
      (selectedItem) => selectedItem.progress !== 'SUCCESS',
    )

    if (select.length > 0) return false

    return true
  }

  function getWesternAge(birthday: string) {
    const today = new Date()
    const birthDay = new Date(birthday)
    let age = today.getFullYear() - birthDay.getFullYear()

    const todayMonth = today.getMonth() + 1
    const birthMonth = birthDay.getMonth() + 1

    if (
      birthMonth > todayMonth ||
      (birthMonth === todayMonth && birthDay.getDate() >= today.getDate())
    ) {
      age -= 1
    }
    return age
  }

  const isEcStatus = (selectedItems: SearchedIndividualEEG[]): boolean => {
    const currentAge = getWesternAge(new Date(selectedItems[0].birth))
    console.log(`current age : ${currentAge}`)

    if (currentAge < 20) {
      return true
    }

    const eceoCheck = selectedItems.filter(
      (selectedItem) => selectedItem.eceo === 'EO',
    )
    if (eceoCheck.length > 0) return false

    return true
  }

  const {user: currentUser} = useAuth()
  const userAuthority = currentUser?.authority ?? ''
  const isMaster = userAuthority === Roles.ROLE_COMPANY_MASTER
  const handleOrganizationSearch = () => {}

  const normalizeObject = (obj: AnyObject): AnyObject => {
    const keys = Object.keys(obj).sort()
    const sortedObj: AnyObject = {}
    keys.forEach((key) => {
      sortedObj[key] = obj[key]
    })
    return sortedObj
  }

  const handleHealthCareClick = async () => {
    try {
      if (selectedItems.length === 0) {
        onFailureModalOpen(t('ISelectRequired'))
        return
      }

      if (!isSucceeded(selectedItems)) {
        onFailureModalOpen(t('IAnalysisUnselectable'))
        return
      }

      const dataTemp: HealthCareReanalysisRequest[] = []
      selectedItems.forEach((item) => {
        const dataItem: HealthCareReanalysisRequest = {}
        if (item.eceo === 'EC') {
          dataItem.ecId = item.id
          const itemEO = selectedItems.find(
            (value) =>
              item.measureId === value.measureId && value.id !== item.id,
          )
          if (itemEO) {
            dataItem.eoId = itemEO.id
          }
        } else if (item.eceo === 'EO') {
          dataItem.eoId = item.id
          const itemEC = selectedItems.find(
            (value) =>
              item.measureId === value.measureId && value.id !== item.id,
          )
          if (itemEC) {
            dataItem.ecId = itemEC.id
          }
        }
        dataTemp.push(dataItem)
      })

      const dataUnix: HealthCareReanalysisRequest[] = Array.from(
        new Set(dataTemp.map((item) => JSON.stringify(normalizeObject(item)))),
      ).map((item) => JSON.parse(item))

      const isHaveOnlyEO = dataUnix.find((item) => item.eoId && !item.ecId)
      if (isHaveOnlyEO) {
        onFailureModalOpen(t('IAnalysisOnlyEO'))
        return
      }

      const isConfirmPrice = await onNoticeDialogOpen({
        title: t('INoticeTitle'),
        message: t('INoticeConfirmDesc'),
      })
      if (!isConfirmPrice.payload) return
      const ids = selectedItems.map((item) => item.id)
      const isConfirmPerform = await onNoticeDialogOpen({
        title: t('INoticeTitle'),
        message: t('IHealthCenterConfirmMessage', {ids: ids.join(', ')}),
      })
      if (!isConfirmPerform.payload) return
      const promises = dataUnix.map(async (item) => {
        return reanalysisHealthCareApi(item)
      })
      await Promise.all(promises)
      onSuccessModalOpen(t('IProcessSuccess'))
    } catch (error) {
      onFailureModalOpen(t('IProcessFail'))
    }
  }

  const handleNormClick = () => {
    if (selectedItems.length === 0) {
      onFailureModalOpen(t('ISelectRequired'))
      return
    }

    if (!isSucceeded(selectedItems)) {
      onFailureModalOpen(t('IAnalysisUnselectable'))
      return
    }

    onNoticeDialogOpen({
      title: t('INoticeTitle'),
      message: t('INoticeConfirmDesc'),
    }).then((result) => {
      if (result.payload) {
        onNormDialogOpen(selectedItems)
      }
    })
  }

  const mciRequest = async () => {
    const confirmed = await onAnalysisConfirm({
      type: 'eeg_mci',
      selected: selectedItems.map((item) => item.id),
    })

    if (!confirmed) {
      onAnalysisCancel()
      return
    }

    if (isSucceeded(selectedItems) && isEcStatus(selectedItems)) {
      try {
        const response = await executeEegMciAnalysisApi({
          individualRequestIds: selectedItems.map((item) => item.id),
        })

        if (response.isError) {
          onFailureModalOpen(response.error)
        } else {
          onSuccessModalOpen(t('IProcessSuccess'))
        }
      } catch (error) {
        onFailureModalOpen(t('IErrorInternal'))
      }
    }
  }

  const handleMCIClick = () => {
    if (selectedItems.length === 0) {
      onFailureModalOpen(t('ISelectRequired'))
      return
    }

    if (!isSucceeded(selectedItems)) {
      onFailureModalOpen(t('IAnalysisUnselectable'))
      return
    }

    if (!isEcStatus(selectedItems)) {
      onFailureModalOpen(t('INotEcStatus'))
      return
    }

    onNoticeDialogOpen({
      title: t('INoticeTitle'),
      message: t('INoticeConfirmDesc'),
    }).then((result) => {
      if (result.payload) mciRequest()
    })
  }

  // eslint-disable-next-line consistent-return
  const summaryRequest = async () => {
    const confirmed = await onAnalysisConfirm({
      type: 'eeg_summary',
      selected: selectedItems.map((item) => item.id),
    })
    if (confirmed === false) {
      onAnalysisCancel()
      return
    }

    if (!isEcStatus(selectedItems)) {
      onFailureModalOpen(t('INotEcStatus'))
      return
    }
    if (isSucceeded(selectedItems) && isEcStatus(selectedItems)) {
      try {
        await executeNormalEegSummaryApi(selectedItems.map((item) => item.id))
        onSuccessModalOpen(t('IProcessSuccess'))
      } catch (err) {
        console.log(err.message)
        // onFailureModalOpen(err.message)
      }
    }
  }

  const handleSummaryClick = async () => {
    if (selectedItems.length === 0) {
      onFailureModalOpen(t('ISelectRequired'))
      return
    }
    if (!isSucceeded(selectedItems)) {
      onFailureModalOpen(t('IAnalysisUnselectable'))
    }

    onNoticeDialogOpen({
      title: t('INoticeTitle'),
      message: t('INoticeConfirmDesc'),
    }).then((result) => {
      if (result.payload) summaryRequest()
    })
  }

  const additionalAnalysisRequest = async () => {
    if (isSucceeded(selectedItems)) {
      try {
        const response = await executeIndividualAdditionalAnalysisApi({
          individualRequestIds: selectedItems.map((item) => item.id),
          codeType: 'I',
          codeTypeDivision: 'EEG',
          codeVersion: '3.0',
        })
        if (response.success) {
          onSuccessModalOpen(t('IProcessSuccess'))
        }
      } catch (err) {
        onFailureModalOpen(t('I3DReportFailed') || err.message)
      }
    }
  }

  const handleAdditionalAnalysisClick = async () => {
    /// Question: 1. 추가분석이 진행중인 녀석은 어떻게 해야하는지
    /// Question: 1-1. 리스트에서 필터링 하고 서버로 요청
    /// Question: 1-2. 경고창을 띄워 선택을 해제하도록 유도
    /// Question: 2. 추가분석 결과가 오류로 완료된 녀석은 다시 분석요청이 가능해야 하는지?
    /// Question: 3. 추가분석이 완료된 녀석이 포함되어 있으면 어떻게 해야하는지?
    /// Question: 3-1. 리스트에서 필터링 하고 서버로 요청
    /// Question: 3-2. 경고창을 띄워 선택을 해제하도록 유도
    if (selectedItems.length === 0) {
      onFailureModalOpen(t('ISelectRequired'))
      return
    }

    onNoticeDialogOpen({
      title: t('INoticeTitle'),
      message: t('INoticeConfirmDesc'),
    }).then((result) => {
      if (result.payload) additionalAnalysisRequest()
    })

    if (!isSucceeded(selectedItems)) {
      onFailureModalOpen(t('IAnalysisUnselectable'))
    }
  }

  const handleReAnalysisClick = () => {
    if (selectedItems.length === 0) {
      onFailureModalOpen(t('ISelectRequired'))
      return
    }
    if (!isSucceeded(selectedItems)) {
      onFailureModalOpen(t('IAnalysisUnselectable'))
      return
    }
    onNoticeDialogOpen({
      title: t('INoticeTitle'),
      message: t('INoticeConfirmDesc'),
    }).then((result) => {
      if (result.payload)
        if (isSucceeded(selectedItems)) {
          if (selectedItems.length === 0) {
            onFailureModalOpen(t('ISelectRequired'))
          } else {
            onIcaSelectDialogOpen(selectedItems)
          }
        }
    })
  }

  const organizationType = storage.getOrganizationType()

  /** Set default value for search query if it is stored in url */
  useEffect(() => {
    const initValue = getQueryFromUrl(location.search) || {}
    onSearch({
      ...query,
      search: {searchName: 'NAME', searchValue: ''},
      period: dateToPeriodString(selection),
      paging: {page: 0, size: 10},
      ...initValue,
    })
  }, [])
  return (
    <div className={classes.root}>
      <TableReportSearch
        reportType={AnalysisReportType.EEGIndividual}
        isRoot={false}
        query={query}
        onSearch={onSearch}
      >
        {isMaster && <CompanySearch onSearch={handleOrganizationSearch} />}
      </TableReportSearch>
      <div className={classes.containerOptional}>
        {isOrganizationHealth(organizationType) ? (
          <ActionButton onClick={handleHealthCareClick}>
            {t('IExaminationCenter')}
          </ActionButton>
        ) : (
          ''
        )}
        <ActionButton onClick={handleNormClick}>{t('INormDB')}</ActionButton>
        <ActionButton onClick={handleMCIClick}>{t('IAMci')}</ActionButton>
        <ActionButton onClick={handleSummaryClick}>
          {t('ISummaryRun')}
        </ActionButton>
        <ActionButton onClick={handleAdditionalAnalysisClick}>
          {t('IView3D')}
        </ActionButton>
        <ActionButton onClick={handleReAnalysisClick}>
          {t('IReAnalysis')}
        </ActionButton>
      </div>
    </div>
  )
}

interface RenderTableRowProps {
  item: SearchedIndividualEEG
  onClick: (item: SearchedIndividualEEG) => void
  isSelected: (item: SearchedIndividualEEG) => boolean
}

const getAnalysisReportPath = (
  status: AnalysisStatus,
  id: number,
  version?: string,
  clientName?: string,
) => {
  if (status === 'SUCCESS')
    return getEegIndividualReportPath(id, version, clientName)
  if (status === 'ICA')
    return getEegIndividualReportPath(id, undefined, clientName)
  if (status === 'TIME_REJECTION')
    return getEegIndividualReportPath(id, undefined, clientName)
  return '/'
}

function RenderTableRow({item, onClick, isSelected}: RenderTableRowProps) {
  const {t} = useTranslation()
  const handleResultClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    const reportPath = getAnalysisReportPath(
      item.progress,
      item.id,
      item.normdbVersion,
      t('IGetFullName', {
        firstName: item.firstName ?? '',
        lastName: item.lastName ?? '',
      }).trim(),
    )
    openInNewTab(reportPath)
  }

  const labelId = `enhanced-table-checkbox-${item.id}`

  const checked = isSelected(item)

  const isReportReadable = isReadable(item.progress)

  const handleClick = () => onClick(item)

  const getStatus = () => {
    if (item.reAnalysisId === '-') {
      return t(statusToI18nString(item.progress))
    }

    if (item.progress === 'ICA' || item.progress === 'TIME_REJECTION') {
      return t(statusToI18nString(item.progress))
    }

    return `${t(statusToI18nString(item.progress))}`
  }

  return (
    <TableRow hover onClick={handleClick}>
      <TableCell padding='checkbox'>
        <Checkbox checked={checked} inputProps={{'aria-labelledby': labelId}} />
      </TableCell>
      <TableCell align='center'>{item.id}</TableCell>
      <TableCell align='center'>{item.reAnalysisId}</TableCell>
      <TableCell align='center'>
        <ClientName
          firstName={item.firstName}
          lastName={item.lastName}
          birth={item.birth}
          gender={item.gender}
        />
      </TableCell>
      <TableCell align='center'>{getEyeStatus(item.eceo)}</TableCell>
      <TableCell align='center'>{item.fileName}</TableCell>
      <TableCell align='center'>
        {isoStringToDateAndTimeString(item.createdAt)}
      </TableCell>
      <TableCell align='center'>{item.normdbVersion || '-'}</TableCell>
      <TableCell align='center'>{getStatus()}</TableCell>
      <TableCellShortStatus align='center' status={item.normStatus}>
        {item.normStatus}
      </TableCellShortStatus>
      <TableCellShortStatus align='center' status={item.mciStatus}>
        {item.mciStatus}
      </TableCellShortStatus>
      <TableCellShortStatus align='center' status={item.summaryStatus}>
        {item.summaryStatus}
      </TableCellShortStatus>
      <TableCell align='center'>
        {t(statusToI18nString(item.additionalAnalysis))}
      </TableCell>
      <TableCell align='center'>
        <TableButton onClick={handleResultClick} disabled={!isReportReadable}>
          {t('IRead')}
        </TableButton>
      </TableCell>
    </TableRow>
  )
}

interface PageTableProps {
  selectedOld: SearchedIndividualEEG[]
  setSelectedOld: (
    data: SearchedIndividualEEG[],
    items: SearchedIndividualEEG[],
  ) => void
}

function PageTable(props: PageTableProps) {
  const {selectedOld, setSelectedOld} = props
  const {t} = useTranslation()
  const classes = useStyles()

  const {loading, query, paging, pagingInfo, items, onSearch} =
    useEegIndividualSearch()
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const [selected, setSelected] = useState<SearchedIndividualEEG[]>([])

  const selectedId = useMemo(() => selected.map((item) => item.id), [selected])

  const setPageIndex = (page: number) => {
    setSelectedOld(selected, items)
    onSearch({
      ...query,
      paging: {
        page,
        size: paging.size,
      },
    })
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(items.filter((item) => item.progress === 'SUCCESS'))
      return
    }
    setSelected([])
  }

  const handleRowClick = (item: SearchedIndividualEEG) => {
    if (!isReadable(item.progress)) {
      onFailureModalOpen(t('IAnalysisUnselectable'))
      return
    }

    const selectedIndex = selectedId.indexOf(item.id)

    let newSelected: SearchedIndividualEEG[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const isRowSelected = (item: SearchedIndividualEEG) => {
    return selectedId.indexOf(item.id) !== -1
  }

  const successItems = items.filter((item) => item.progress === 'SUCCESS')
  const emptyRows = items === null ? 10 : 10 - items.length

  const onSizeChange = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const size = parseInt(event.target.value as string, 10)
    onSearch({...query, paging: {page: 0, size}})
  }

  useEffect(() => {
    addPushReceiveHandler((event: any) => {
      if (event.detail.data.status) {
        onSearch({...query, paging: {page: 0, size: 10}})
      }
    })
    return () => removePushReceiveHandler(() => {})
  }, [])

  useEffect(() => {
    const itemIds = items.map((item) => item.id)
    const selectedOldNew = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < selectedOld.length; i++) {
      if (itemIds.indexOf(selectedOld[i].id) !== -1) {
        selectedOldNew.push(selectedOld[i])
      }
    }

    setSelected(selectedOldNew)
  }, [selectedOld, items])

  return (
    <div>
      <TableContainer>
        <TableToolbar selectedItems={selected} />
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell align='center' padding='none'>
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < successItems.length
                  }
                  checked={
                    successItems.length > 0 &&
                    selected.length === successItems.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{'aria-label': 'select all desserts'}}
                />
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('IEEGIndividualIndex')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('IReAnalysis_no')}
              </TableCell>
              <TableCell
                align='center'
                padding='none'
                style={{minWidth: '100px'}}
              >
                {t('IPatientName')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('IECEO')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('IFileName')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('IMeasureDate')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('IVersion')}
              </TableCell>
              <TableCell
                align='center'
                padding='none'
                style={{minWidth: '100px'}}
              >
                {t('IProgress')}
              </TableCell>
              <TableCell
                align='center'
                padding='none'
                style={{minWidth: '100px'}}
              >
                {t('INormDB')}
              </TableCell>
              <TableCell
                align='center'
                padding='none'
                style={{minWidth: '100px'}}
              >
                {t('IAMci')}
              </TableCell>
              <TableCell
                align='center'
                padding='none'
                style={{minWidth: '100px'}}
              >
                {t('ISummaryRun')}
              </TableCell>
              <TableCell
                align='center'
                padding='none'
                style={{minWidth: '100px'}}
              >
                {t('IView3D')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('IAnalysisReport')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <RenderTableRow
                key={item.id}
                item={item}
                onClick={handleRowClick}
                isSelected={isRowSelected}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow
                style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
              >
                <TableCell colSpan={14} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          totalPageCount={pagingInfo.totalPages}
          currentPageIndex={query.paging.page ?? 0}
          itemCountPerPage={query.paging.size ?? 10}
          setCurrentPageIndex={setPageIndex}
          loading={loading}
          onItemCountPerPageChanged={onSizeChange}
        />
      </TableContainer>
    </div>
  )
}

export default PageTable
