import {CircularProgress} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import {
  AnalysisResultInfo,
  AnalysisResultItem,
  fetchIndividualEEGResult,
  FetchIndividualEEGResultRequest,
  fetchIndividualEegStatus,
  IcaAnalysisResultIndicator,
} from 'api/analysisApi'
import appHistory from 'AppHistory'
import CardWithTitle from 'components/atoms/CardWithTitle'
import ReportInfo from 'components/molcules/Report/ReportInfo'
import {Settings} from 'components/Settings'
import {
  AnalysisDivision,
  CodeType,
  CodeTypeMap,
  ReportIcaAnalysisTypeMap,
} from 'constants/AnalysisConstant'
import {getEegIndividualReportPath} from 'constants/RouteConstant'
import useFailureModal from 'features/modal/useFailureModal'
import {addPushReceiveHandler} from 'helpers/pushHelper'
import useInterval from 'hooks/useInterval'
import useQuery from 'hooks/useQuery'
import {IcaReportItem} from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaReportItem'
import IcaSettings from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaSettings'
import ReportLoading from 'pages/Analysis/EEGIndividual/ReAnalysis/ReportLoading'
import React, {useEffect, useState} from 'react'
import {useAsync} from 'react-async-hook'
import {useTranslation} from 'react-i18next'

export type RenderingItem = [IcaAnalysisResultIndicator, AnalysisResultItem[]]

export type IcaProcess =
  | 'ica_process_idle'
  | 'ica_process_ica'
  | 'ica_process_tsr'
  | 'ica_process_loading'
  | 'ica_process_success'
  | 'ica_process_failure'

interface ReportProps {
  id: number
}

function IcaReportFetcher({id}: ReportProps) {
  const {t, i18n} = useTranslation()

  const codeTypeDivision: AnalysisDivision = 'EEG'
  const codeType: CodeType = CodeTypeMap.Individual
  const locale = i18n.language

  const params: FetchIndividualEEGResultRequest = {
    individualRequestIds: [id],
    codeTypeDivision,
    codeType,
    codeVersion: '3.0',
    locale,
  }
  const asyncResult = useAsync(fetchIndividualEEGResult, [params], {
    executeOnMount: true,
    executeOnUpdate: false,
  })
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const [renderingItems, setRenderingItems] = useState<RenderingItem[]>()
  const [resultInfo, setResultInfo] = useState<AnalysisResultInfo>()
  const [componentList, setComponentList] = useState<number[]>([])
  const clientName = useQuery().get('clientName')

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (asyncResult.result?.list) {
      const [result] = asyncResult.result.list

      const {resultDetailList, resultInfo, componentList} = result
      setComponentList(componentList)

      const rawData: RenderingItem = [
        {
          codeId: ReportIcaAnalysisTypeMap.RawData,
          codeName: 'Raw data(Bandpass filtered)',
          codePrintYn: 'Y',
          codeSort: 1,
          titleDescription: '',
        },
        resultDetailList
          .filter((i) => i.analysisType === '1')
          .filter((i) => i.subType === 0)
          .filter((i) => i.bandType === 0)
          .filter((i) => i.componentType === 0)
          .filter((i) => i.psdScaleOrder === 2),
      ]
      const componentTimeSeries: RenderingItem = [
        {
          codeId: ReportIcaAnalysisTypeMap.ComponentDataTimeSeries,
          codeName: 'Component Data Time series',
          codePrintYn: 'Y',
          codeSort: 2,
          titleDescription: '',
        },
        resultDetailList
          .filter((i) => i.analysisType === '12')
          .filter((i) => i.subType === 0)
          .filter((i) => i.bandType === 0)
          .filter((i) => i.componentType === 0),
      ]
      const componentData: RenderingItem = [
        {
          codeId: ReportIcaAnalysisTypeMap.ComponentData,
          codeName: 'Component Data',
          codePrintYn: 'Y',
          codeSort: 3,
          titleDescription: '',
        },
        resultDetailList.filter((i) => i.analysisType === '12'),
      ]
      const cleanedData: RenderingItem = [
        {
          codeId: ReportIcaAnalysisTypeMap.CleanedData,
          codeName: 'Cleaned data(Common average reference)',
          codePrintYn: 'Y',
          codeSort: 4,
          titleDescription: '',
        },
        resultDetailList
          .filter((i) => i.analysisType === '1')
          .filter((i) => i.subType === 0)
          .filter((i) => i.bandType === 0)
          .filter((i) => i.componentType === 0)
          .filter((i) => i.psdScaleOrder === 3),
      ]
      setRenderingItems([
        rawData,
        componentTimeSeries,
        componentData,
        cleanedData,
      ])
      setResultInfo(resultInfo)
    }
  }, [asyncResult.result])

  const [icaProcessing, setIcaProcessing] =
    useState<IcaProcess>('ica_process_ica')

  // const fetchStatus = async () => {
  //   try {
  //     const {data: status} = await fetchIndividualEegStatus(id)
  //     switch (status) {
  //       case 'ICA':
  //         setIcaProcessing('ica_process_idle')
  //         break
  //       case 'TIME_REJECTION':
  //         setIcaProcessing('ica_process_success')
  //         break
  //       case 'PROCESSING':
  //         setIcaProcessing('ica_process_loading')
  //         break
  //       case 'ERROR':
  //         setIcaProcessing('ica_process_failure')
  //         break
  //       default:
  //         break
  //     }
  //   } catch (err) {
  //     setIcaProcessing('ica_process_failure')
  //   }
  // }

  useEffect(() => {
    addPushReceiveHandler((event: any) => {
      if (event.detail.data.status === 'ICA') {
        setIcaProcessing('ica_process_idle')
      }
      if (event.detail.data.status === 'PROCESSING') {
        setIcaProcessing('ica_process_loading')
      }
      if (event.detail.data.status === 'TIME_REJECTION') {
        setIcaProcessing('ica_process_loading')
      }
      if (event.detail.data.status === 'ERROR') {
        setIcaProcessing('ica_process_failure')
      }
    })
  }, [])

  useInterval(
    () => {
      if (icaProcessing === 'ica_process_loading') {
        fetchIndividualEegStatus(id)
          .then((res) => {
            const {data: status} = res
            switch (status) {
              case 'ICA':
                setIcaProcessing('ica_process_ica')
                break
              case 'TIME_REJECTION':
                setIcaProcessing('ica_process_success')
                break
              case 'ERROR':
                setIcaProcessing('ica_process_failure')
                break
              default:
                break
            }
          })
          .catch(() => setIcaProcessing('ica_process_failure'))
      }
    },
    icaProcessing === 'ica_process_ica' ||
      icaProcessing === 'ica_process_loading'
      ? 3000
      : undefined,
  )

  useEffect(() => {
    if (icaProcessing === 'ica_process_success') {
      appHistory.push(
        getEegIndividualReportPath(id, undefined, clientName || ''),
      )
      window.location.reload()
      return
    }

    if (icaProcessing === 'ica_process_failure') {
      onFailureModalOpen(t('IRetry'))
    }
  }, [icaProcessing])

  return (
    <CardWithTitle style={{width: '100%'}} title='ICA'>
      {/* height - header - footer - CardHeader & border - margin */}
      <div
        style={{
          minHeight: 'calc(100vh - 91px - 77px - 77px - 15px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        {asyncResult.loading && (
          <CircularProgress size='3rem' color='primary' />
        )}
        {asyncResult.error && (
          <div>
            {asyncResult.error.message}
            <IconButton
              onClick={() => asyncResult.execute(params)}
              size='small'
            >
              <ReplayIcon />
            </IconButton>
          </div>
        )}
        <div style={{width: '100%'}}>
          {resultInfo !== undefined && (
            <ReportInfo {...resultInfo} clientName={clientName} />
          )}
          {renderingItems !== undefined &&
            renderingItems.map((item, index) => (
              <IcaReportItem
                key={index}
                renderingItem={item}
                codeType={codeType}
              />
            ))}
        </div>
      </div>
      {icaProcessing === 'ica_process_loading' && <ReportLoading />}
      <Settings open={open} onOpen={() => setOpen((o) => !o)}>
        <IcaSettings
          components={componentList}
          requestId={id}
          onProcess={() => setIcaProcessing('ica_process_loading')}
        />
      </Settings>
    </CardWithTitle>
  )
}

export default IcaReportFetcher
