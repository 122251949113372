import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  createOrgUserApi,
  fetchUserDetailInfoApi,
  OrgUserCreateRequest,
  OrgUserUpdateRequest,
  updateUserDetailInfoApi,
} from 'api/userApi'
import {fetchMeAction} from 'features/auth/authSlice'
import {
  openFailureModalAction,
  openSuccessModalAction,
} from 'features/modal/alertSlice'
import i18n from 'i18n'
import {RootState} from 'store'

export const readUser = createAsyncThunk(
  'api/org/user/read',
  async (payload: string, {rejectWithValue}) => {
    try {
      const response = await fetchUserDetailInfoApi({uid: payload})
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const createUser = createAsyncThunk(
  'org/user/create',
  async (payload: OrgUserCreateRequest, {dispatch, rejectWithValue}) => {
    try {
      await createOrgUserApi(payload)
      dispatch(openSuccessModalAction(i18n.t('IAddSuccess')))
      return true
    } catch (err) {
      dispatch(openFailureModalAction(err.message))
      return rejectWithValue(err.message)
    }
  },
)

export const updateUser = createAsyncThunk(
  'org/user/update',
  async (
    payload: OrgUserUpdateRequest,
    {dispatch, rejectWithValue, getState},
  ) => {
    try {
      await updateUserDetailInfoApi(payload)
      await dispatch(openSuccessModalAction(i18n.t('IUpdateSuccess')))

      if ((getState() as RootState).auth.user?.uid === payload.uid) {
        await dispatch(fetchMeAction())
      }
      return await dispatch(readUser(payload.uid)).unwrap()
    } catch (err) {
      dispatch(openFailureModalAction(err.message))
      return rejectWithValue(err.message)
    }
  },
)

interface OrgUserState {
  user: UserDetailInfo | null
  error: unknown
  loading: boolean
}

const initialState: OrgUserState = {
  user: null,
  error: null,
  loading: false,
}

export const orgUserSlice = createSlice({
  name: 'orgUserDetailSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(readUser.pending, (state) => {
      state.loading = true
      state.error = null
      state.user = null
    })
    builder.addCase(readUser.fulfilled, (state, action) => {
      state.loading = false
      state.user = action.payload
    })
    builder.addCase(readUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.loading = false
      state.error = null
      state.user = action.payload // Q: user 업데이트 쳐줘야 하나?
    })
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload // Q: error 업데이트 쳐줘야 하나?
    })
    builder.addCase(createUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createUser.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(createUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default orgUserSlice.reducer

export const selectLoading = (state: RootState) => state.orgUser.loading
export const selectData = (state: RootState) => state.orgUser.user
export const selectError = (state: RootState) => state.orgUser.error
