import {Theme} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'
import headerBackground from 'pages/HealthCenter/Report/Elements/header.png'
import {Colors} from 'components/common/useBaseStyle'

const HealthCenterReportStyle = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
    topRoot: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
      },
      width: 1920,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    topNav: {
      width: 1920,
      height: 72,
      borderBottom: '1px solid #CECECE',
      boxShadow: '0px 2px 8px 0px #00000014',
      display: 'flex',
      justifyContent: 'space-between',
      background: '#fff',
      marginBottom: 65,
    },
    topNavLeft: {display: 'flex', alignItems: 'center'},
    logo: {marginRight: 20},
    tabs: {display: 'flex', alignItems: 'center', fontFamily: 'Noto Sans KR'},
    tab: {
      height: 72,
      fontWeight: 400,
      fontSize: 16,
      color: '#333333',
      display: 'flex',
      alignItems: 'center',
      marginRight: 10,
      padding: '0 18px',
      cursor: 'pointer',
    },
    tabActive: {
      color: '#00A29C',
      borderBottom: '3px solid #00A29C',
      fontWeight: 700,
    },
    topNavRight: {
      display: 'flex',
      alignItems: 'center',
    },
    searchSelect: {
      minWidth: 123,
      height: 72,
      borderRadius: 0,
      '& .MuiSelect-select': {
        padding: '0 20px',
      },
      '& fieldset': {
        borderTop: '0 !important',
        borderBottom: '0 !important',
        borderRight: '1px solid #E0E0E0 !important',
        borderLeft: '1px solid #E0E0E0 !important',
      },
      '& svg': {
        right: 12,
      },
    },
    header: {
      height: 112,
      width: 1280,
      backgroundImage: `url(${headerBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      '@media print': {
        width: 1320,
      },
    },
    leftHeader: {
      display: 'flex',
      paddingTop: '22px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
      alignSelf: 'stretch',
      marginLeft: 40,
    },
    rightHeader: {
      display: 'flex',
      width: '794.5px',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '40px',
      flexShrink: 0,
      alignSelf: 'stretch',
      marginRight: 30,
    },
    leftHeaderTitle: {
      fontWeight: 700,
      fontSize: 32,
      color: '#FFF',
      fontStyle: 'normal',
      lineHeight: '120%',
      letterSpacing: '-0.96px',
    },
    leftHeaderContent: {
      color: '#FFF',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '120%',
      letterSpacing: '-0.48px',
    },
    table: {
      display: 'flex',
      gap: 64,
    },
    row: {display: 'flex', flexDirection: 'column'},
    headCell: {
      color: '#FFF',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '120%',
      letterSpacing: '-0.48px',
      marginBottom: 5,
    },
    bodyCell: {
      color: '#FFF',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '120%',
      letterSpacing: '-0.54px !important',
    },
    qrCode: {
      width: 66,
      height: 66,
      background: '#fff',
      borderRadius: 2,
    },
    wrapElement: {
      marginBottom: 60,
      '@media print': {
        marginBottom: 20,
      },
    },
    headElement: {
      width: '100%',
      display: 'flex',
      marginBottom: 40,
    },
    numberElement: {
      display: 'flex',
      width: '38px',
      height: '42px',
      padding: '7px 10px 0px 10px',
      justifyContent: 'center',
      alignItems: 'flex-start',
      gap: '10px',
      flexShrink: 0,
      borderRadius: '0px 10px',
      background: '#2E4D6F',
      '& p': {
        color: '#FFF',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '100%',
      },
    },
    textElement: {
      display: 'flex',
      height: '42px',
      padding: '7px 0px 0px 12px',
      alignItems: 'flex-start',
      gap: '10px',
      flex: '1 0 0',
      borderBottom: '2px solid #2E4D6F',
      color: '#2E4D6F',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '100%',
      letterSpacing: '-0.66px',
    },
    brainBasic: {
      width: 1280,
      padding: '60px',
      background: '#fff',
      marginBottom: 68,
      '@media print': {
        marginBottom: 0,
        paddingBottom: 0,
      },
    },
    groupImage: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 28,
      padding: '0 15px',
    },
    image: {
      height: 'auto',
      width: 545,
      objectFit: 'contain',
      '@media print': {
        width: 500,
      },
    },
    groupText: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 15px',
    },
    textDescription: {
      color: '#666',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '148%',
      letterSpacing: '-0.48px',
      '& span': {
        width: 8,
        color: '#666',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '148%',
        letterSpacing: '-0.48px',
      },
    },
    tableFiguresByPart: {
      width: '100%',
      marginBottom: 13,
      padding: '0 15px',
      '& thead tr th': {
        width: '116px',
        paddingTop: '8px',
        height: 32,
        background: '#7F8892',
        color: '#FFF',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '100%',
        letterSpacing: '-0.45px',
        borderLeft: '1px solid #fff',
        borderRight: '1px solid #fff',
      },
      '& tbody tr': {
        borderBottom: '1px solid #D9D9D9',
      },
      '& tbody tr td': {
        height: 119,
        padding: '16px 0',
      },

      '& tbody tr td:first-child p': {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#000',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '100%',
      },
    },
    firstCol: {
      width: 200,
      verticalAlign: 'middle',
    },
    secondCol: {
      width: 692,
    },
    scoreCol: {
      width: 116,
      verticalAlign: 'middle',
    },
    scoreContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 8,
      '& .Score': {
        color: '#000',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '100%',
      },
    },
    Label: {
      padding: '7px 20px',
      height: 30,
      width: 'max-content',
      textAlign: 'center',
      borderRadius: 30,
      color: '#FFF',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '100%', // 15px
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      '&.OverActive': {
        backgroundColor: '#20A2DC',
        color: Colors.white,
      },
      '&.Balanced': {
        backgroundColor: '#B4B4B4',
        color: Colors.white,
      },
      '&.Underactive': {
        backgroundColor: '#EE3D3B',
        color: Colors.white,
      },
    },
    symptoms: {
      '& p:first-child': {
        color: '#000',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '148%', // 26.64px
        letterSpacing: '-0.54px !important',
      },
      '& p': {
        color: '#333',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '148%', // 26.64px
        letterSpacing: '-0.54px !important',
      },
      '& p span': {
        color: '#000',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '148%', // 26.64px
        letterSpacing: '-0.54px !important',
      },
    },
    brainBasicDescription: {
      marginTop: 40,
      gap: 40,
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 15px',
      '& p': {
        color: '#333',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '156%', // 31.2px
        letterSpacing: '-0.6px',
        padding: '16px 0',
      },
      '& ul': {
        marginLeft: 25,
      },
      '& ul li': {
        color: '#333',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '148%', // 26.64px
        letterSpacing: '-0.54px !important',
        paddingBottom: 16,
        listStyleType: 'disc',
      },
    },
    brainConnectivityTitle: {
      color: '#000',

      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '120%', // 26.4px
      letterSpacing: '-0.66px',
      marginBottom: 24,
      '& span': {
        color: '#00A29C',
      },
    },
    brainConnectivityDes: {
      marginBottom: 40,
      '& p': {
        color: '#333',

        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '156%', // 28.08px
        letterSpacing: '-0.54px !important',
      },
      '& p:last-child': {
        marginTop: 16,
      },
      '& p:first-child': {
        marginBottom: 16,
      },
      '& ul': {
        display: 'flex',
        padding: '24px 48px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '6px',
        alignSelf: 'stretch',
        border: '1px solid #E0E0E0',
        background: '#FAFAFA',
      },
      '& ul li': {
        color: '#333',

        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '148%', // 26.64px
        letterSpacing: '-0.54px !important',
        listStyleType: 'disc',
      },
    },
    contentCard: {
      padding: '0 15px',
      display: 'flex',
      justifyContent: 'space-between',
      gap: 40,
    },
    cardLeftContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 60,
      minWidth: 545,
      paddingBottom: '20px 0',
    },
    cardLeftContentAdditional: {
      position: 'absolute',
      width: '100%',
    },
    cardLeftContentAdditionalV1: {
      paddingBottom: '20px 0',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '77px',
    },
    cardLeftImg: {
      minWidth: 320,
      minHeight: 388,
      height: 'auto',
      objectFit: 'contain',
    },
    cardRightContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: 40,
      minWidth: 545,
    },
    percentChangeBar: {
      borderTop: '5px solid #000',
    },
    styleAnalysis: {display: 'flex', flexDirection: 'column'},
    greenLabel: {
      borderBottom: '1px solid #00A29C',
      '& span': {
        display: 'flex',
        padding: '10px 24px 12px 24px',
        alignItems: 'flex-start',
        gap: '10px',
        borderRadius: '10px 10px 0px 0px',
        background: '#00A29C',
        color: '#FFF',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '100%', // 18px
        width: 'max-content',
      },
    },
    contentAnalysis: {
      background: 'rgba(127, 136, 146, 0.05)',
    },
    connectivityAnalysis: {
      display: 'flex',
      padding: '24px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '12px',
      alignSelf: 'stretch',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '100%',
      '& ul': {
        display: 'flex',
        paddingTop: 30,
        marginTop: 20,
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        background: '#FAFAFA',
        borderTop: '1px solid #D4D4D4',
        gap: 16,
        paddingLeft: 30,
        paddingBottom: 8,
      },
      '& ul li': {
        color: '#333',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '148%', // 26.64px
        letterSpacing: '-0.54px !important',
        listStyleType: 'disc',
      },
    },
    textBold: {
      color: '#444',

      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '156%', // 28.08px
      letterSpacing: '-0.54px !important',
    },
    textBlack: {
      color: '#000',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '156%', // 28.08px
      letterSpacing: '-0.54px !important',
    },
    textP: {
      color: '#444',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '156%', // 28.08px
      letterSpacing: '-0.54px !important',
    },
    connectivityBrain: {
      padding: '20px 24px',
    },
    analysisTotal: {
      margin: '40px 0 60px 0',
      display: 'flex',
      justifyContent: 'space-between',
      gap: 16,
    },
    mciResultImage: {
      width: 1160,
      height: 'auto',
      objectFit: 'contain',
      '@media print': {
        width: 1120,
      },
    },
    mciResultDes: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 6px',
    },
    mciResultDesTitle: {
      fontSize: 22,
      color: '#333',
      fontWeight: 700,
      letterSpacing: '-0.54px !important',
      lineHeight: '34.32px',
      marginBottom: 20,
    },
    mciResultDesContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      padding: 24,
      border: '1px solid #E0E0E0',
      background: '#FAFAFA',
    },
    mciResultDesContentList: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
    mciResultDesContentText: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 18,
      color: '#333',
      fontWeight: 400,
      letterSpacing: '-0.54px !important',
      '& span': {
        width: 24,
        height: 24,
        background: '#666666',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 5,
        color: '#FFFFFF',
        fontWeight: 700,
        fontSize: 12,
        lightHeight: 12,
      },
    },
    AIDementiaPredictionGuide: {
      width: 1130,
      height: 113,
      display: 'flex',
      justifyContent: 'space-between',
      gap: 20,
      marginBottom: 12,
    },
    AIDementiaPredictionGuideTitle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 4,
      width: 240,
      paddingLeft: 32,
    },
    AIDementiaPredictionGuideTitleNum: {
      fontWeight: 700,
      fontSize: 16,
      letterSpacing: '-0.54px !important',
      lineHeight: '19.2px',
    },
    AIDementiaPredictionGuideTitleText: {
      fontWeight: 700,
      fontSize: 18,
      letterSpacing: '-0.54px !important',
      lineHeight: '26.64px',
      whiteSpace: 'pre-line',
    },
    AIDementiaPredictionGuideDes: {
      width: 730,
      display: 'flex',
      alignItems: 'center',
      '& ul': {
        marginLeft: 30,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 8,
      },
      '& ul li': {
        color: '#333',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '148%', // 26.64px
        letterSpacing: '-0.54px !important',
        listStyleType: 'disc',
      },
    },
    AIDementiaPredictionGuideCheck: {
      width: 120,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    AIDementiaPredictionDetail: {
      marginTop: 60,
      paddingBottom: 40,
      borderBottom: '1px solid #D9D9D9',
      marginBottom: 12,
    },
    mciResultNote: {
      width: 1160,
    },
    mciResultNoteElm: {
      display: 'flex',
      gap: 8,
      color: '#666666',
      letterSpacing: '-0.54px !important',
      lineHeight: '23.68px',
      fontSize: 16,
      fontWeight: 400,
      whiteSpace: 'pre-line',
      '& span': {
        paddingRight: 4,
      },
    },
    hrvError: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 24,
      background: '#FAFAFA',
      minHeight: 200,
      width: '100%',
      fontSize: 18,
      fontWeight: 700,
    },
    brainConnectivityBrainRegionTabs3: {
      position: 'relative',
      height: '520px',
      padding: '0 5px 0 18px',
    },
    percentBarCar: {
      position: 'relative',
      width: '100%',
      height: '545px',
    },
    percentBarCardV3: {
      position: 'relative',
      width: '100%',
      height: '640px',
    },
    brainConnectivityBrainRegionTabs2: {
      position: 'relative',
      height: '640px',
    },
    cardRightContentAdditional: {
      minWidth: 550,
      marginTop: '100px',
    },
  })

export default HealthCenterReportStyle
