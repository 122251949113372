import React, {useEffect, useMemo, useRef} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Checkbox, IconButton, Radio, RadioGroup} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {SchemaOf} from 'yup'
import {isOrganizationHealth} from 'Authority'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import useStyles from 'components/Dialog/User/Style'
import {TabPanel, Tabs} from 'components/Tab'
import {
  Gender,
  GenderSelectItems,
  Hand,
  HandSelectItems,
} from 'constants/CommonConstant'
import * as storage from 'api/storageApi'
import {
  createPatientChartNoApi,
  doubleCheckPatientChartNoApi,
  PatientAddForm,
} from 'api/patientApi'
import useAuth from 'features/auth/useAuth'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import useOrgUserList from 'features/org/useOrgUserList'
import {useCreateDialog} from 'features/patient/usePatientDialog'
import useProductList from 'features/patient/useProductList'
import {isNotDefined} from 'helpers/commonHelper'
import {dateToDashString} from 'helpers/dateHelper'
import SelectBoldCountryNumber from '../BoldSelect/SelectBoldCountryNumber'
import SelectBoldDoctor from '../BoldSelect/SelectBoldDoctor'
import SelectBoldYear from '../BoldSelect/SelectBoldYear'
import CheckUpItems from './fields/checkupItems'

export function calculateAge(birthDate: Date) {
  const today = new Date()
  const age = today.getFullYear() - birthDate.getFullYear()
  const month = today.getMonth() - birthDate.getMonth()
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1
  }
  return age
}

const PatientAddDialog = () => {
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()
  const organizationType = storage.getOrganizationType()
  const isHealthOrg = isOrganizationHealth(organizationType)
  const {t, i18n} = useTranslation()
  const classes = useStyles()
  const inputClasses = useBoldInputStyle()
  const {user: currentUser} = useAuth()
  const {userList: users} = useOrgUserList()
  const [docNo, setDocNo] = React.useState('')
  const [hasChartNo, setHasChartNo] = React.useState('')

  const {
    open: isOpen,
    listProducts,
    onClose: handleClose,
    onCreate,
    onGetProducts,
  } = useCreateDialog()
  const {idOver20Product} = useProductList()

  const [chartNo, setChartNo] = React.useState(false)
  const handleChange = () => {
    setChartNo(!chartNo)
    setHasChartNo('')
  }

  const [usedEmail, setUsedEmail] = React.useState(false)

  const [changeBirth, setBirth] = React.useState<Date>()
  const handleBirthData = (data: Date) => {
    setBirth(data)
  }

  const isAdult = useMemo(() => {
    if (!changeBirth || !changeBirth.getMonth) {
      return false
    }
    const Age = calculateAge(changeBirth)
    return Number.isNaN(Age) ? false : Age >= 20
  }, [changeBirth])

  const AddSchema: SchemaOf<PatientAddForm> = yup
    .object()
    .shape({
      birth: yup.date().typeError(t('IDateInvalid')),
      chartNo: yup.string().required(t('IChartNoRequired')),
      countryCode: yup.string().required(t('ICountryRequired')),
      docNo: yup.string().required(t('IDoctorRequired')),
      gender: yup.string().required(t('IGenderRequired')),
      hand: yup.string().required(t('IHandednessRequired')),
      firstName: yup.string().required(t('INameRequired')),
      lastName: yup.string().required(t('INameRequired')),
      phone: yup
        .string()
        .required(t('ITelRequired'))
        .matches(
          /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
          t('ITelInvalid'),
        ),
      terms: yup.string().optional(),
      healthProductId: isHealthOrg
        ? yup.number().nullable().required(t('IMessageRequiredProduct'))
        : yup.number().nullable().notRequired(),
    })
    .defined()

  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const handleTabChanged = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: number,
  ) => {
    setTabIndex(newValue)
  }

  const {
    control,
    handleSubmit,
    formState: {errors},
    reset,
    setValue,
    setError,
  } = useForm<PatientAddForm>({
    // @ts-ignore
    resolver: yupResolver(AddSchema),
  })

  const handleCleanClose = () => {
    setUsedEmail(false)
    reset()
    handleClose()
    setHasChartNo('')
  }
  const contentRef = useRef<HTMLDivElement>(null)
  const dividers = true
  const onSubmit = async (data: PatientAddForm) => {
    setHasChartNo('')
    const checkChartNo = await doubleCheckPatientChartNoApi(data.chartNo)
    if (checkChartNo.data) {
      setHasChartNo('IExistChartNo')
      if (contentRef.current) {
        contentRef.current.scrollTop = 0
      }
      return
    }

    const productId =
      listProducts.find((item) => item.productId === data.healthProductId)
        ?.id || null

    if (!isAdult && idOver20Product.includes(data.healthProductId || 0)) {
      setError('healthProductId', {
        type: 'manual',
        message: t('IErrorMessageAIDementiaPrediction'),
      })
      setValue('healthProductId', null)
      return
    }

    const {docNo} = data

    if (isNotDefined(docNo)) {
      console.log('의사를 선택해야 함')
      return
    }

    const doctor = users.find((user) => user.id === parseInt(docNo, 10))
    if (isNotDefined(doctor)) {
      console.log('의사를 선택하긴 했는데, 의사를 찾을 수 없음')
      return
    }

    try {
      const result = await onCreate({
        ...data,
        docNo: String(doctor.id),
        email: usedEmail ? '' : data.email,
        birth: dateToDashString(changeBirth ?? new Date()),
        healthProductId: productId,
      })

      if (result && result.payload) {
        const codeResult = result.payload as any

        if (
          codeResult.message.includes('중복된') ||
          codeResult.message.includes('same')
        ) {
          setError('chartNo', {
            type: 'custom',
            message: `${t('IDuplicateChartNumber')}`,
          })
        }
      }
      handleCleanClose()
    } catch (err) {
      console.log(err)
    }
  }

  const fetchChartNo = async () => {
    try {
      const response = await createPatientChartNoApi(currentUser?.uid ?? '')

      if (response) {
        console.log(response.data)
      }

      setValue('chartNo', response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (chartNo) {
      fetchChartNo()
    }
  }, [chartNo])

  useEffect(() => {
    return () => {
      setChartNo(false)
      setHasChartNo('')
      reset()
    }
  }, [isOpen])

  useEffect(() => {
    if (users) {
      setValue('docNo', String(users[0]?.id))
      setDocNo(String(users[0]?.id))
    }
  }, [users])

  useEffect(() => {
    if (currentUser?.orgId) {
      onGetProducts(currentUser?.orgId?.toString())
    }
  }, [currentUser])

  return (
    <Dialog open={isOpen} onClose={handleClose} className={classes.container}>
      <DialogContent
        dividers={dividers}
        ref={contentRef}
        style={{overflowY: 'auto'}}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.root}>
            {/* 상단 타이틀 컨테이너  */}
            <div className={classes.longTitleContainer}>
              <div className={classes.containerTitle}>
                <div className={classes.closeButtonWrap}>
                  <IconButton
                    color='secondary'
                    aria-label='favorite'
                    className={classes.closeButton}
                    onClick={() => {
                      onNoticeDialogOpen({
                        title: '',
                        message: t('ICancleConfirm'),
                      }).then((result) => {
                        if (result.payload) {
                          handleCleanClose()
                        }
                      })
                    }}
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                </div>
                <Typography variant='h6' className={classes.title}>
                  {t('ICustomerCreate')}
                </Typography>
              </div>
            </div>
            <Divider className={classes.titleDivider} />
            <DialogContent className={classes.contentWrap}>
              <Tabs
                value={tabIndex}
                indicatorColor='primary'
                textColor='primary'
                onChange={handleTabChanged}
                aria-label='disabled tabs example'
              >
                <Tab label={t('IBaseInfo')} />
                <Tab label={t('IDiseaseInfo')} />
              </Tabs>
              <TabPanel value={tabIndex} index={0}>
                <div className={classes.infoContainer}>
                  <Typography className={inputClasses.inputLabel}>
                    {t('IChartNo')}
                  </Typography>
                  <Typography className={classes.smallPadding} />
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Controller
                      name='chartNo'
                      control={control}
                      render={({field}) => (
                        <input
                          className={inputClasses.input}
                          placeholder={t('IChartNo')}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e)
                            setHasChartNo('')
                          }}
                        />
                      )}
                    />
                    <Typography className={classes.padding} />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={chartNo}
                          onChange={handleChange}
                          name={t('IChartNoCreate')}
                          color='primary'
                        />
                      }
                      label={
                        <Typography
                          style={{
                            color: '#5a6268',
                            fontSize: '17px',
                            paddingBottom: 3,
                          }}
                        >
                          {t('IChartNoCreate')}
                        </Typography>
                      }
                    />
                  </div>
                  <span className={classes.errorText}>
                    {errors.chartNo && t(errors.chartNo.message ?? '')}
                  </span>
                  <span className={classes.errorText}>
                    {t(hasChartNo ?? '')}
                  </span>
                </div>

                <div className={classes.infoContainer}>
                  <Typography className={inputClasses.inputLabel}>
                    {t('IName')}
                  </Typography>
                  <Typography className={classes.smallPadding} />
                  {(i18n.language === 'ko' || i18n.language === 'ja') && (
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <Controller
                        name='lastName'
                        control={control}
                        render={({field}) => (
                          <input
                            className={inputClasses.input}
                            placeholder={t('ILastName')}
                            {...field}
                          />
                        )}
                      />
                      <Typography className={classes.smallPadding} />
                      <Controller
                        name='firstName'
                        control={control}
                        render={({field}) => (
                          <input
                            className={inputClasses.input}
                            placeholder={t('IFirstName')}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  )}
                  {i18n.language !== 'ko' && i18n.language !== 'ja' && (
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <Controller
                        name='firstName'
                        control={control}
                        render={({field}) => (
                          <input
                            className={inputClasses.input}
                            placeholder={t('IFirstName')}
                            {...field}
                          />
                        )}
                      />
                      <Typography className={classes.smallPadding} />
                      <Controller
                        name='lastName'
                        control={control}
                        render={({field}) => (
                          <input
                            className={inputClasses.input}
                            placeholder={t('ILastName')}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  )}
                  {/* <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Controller
                    name='firstName'
                    control={control}
                    render={({field}) => (
                      <input
                        className={inputClasses.input}
                        placeholder={t('IFirstName')}
                        // error={!!errors.firstName}
                        // helperText={errors.firstName?.message}
                        {...field}
                      />
                    )}
                  />
                  <Typography className={classes.padding} />
                  <Controller
                    name='lastName'
                    control={control}
                    render={({field}) => (
                      <input
                        className={inputClasses.input}
                        placeholder={t('ILastName')}
                        // error={!!errors.firstName}
                        // helperText={errors.firstName?.message}
                        {...field}
                      />
                    )}
                  />
                </div> */}
                  <span className={classes.errorText}>
                    {errors.firstName &&
                      errors.lastName &&
                      t(errors.firstName.message ?? '')}
                    {errors.firstName &&
                      !errors.lastName &&
                      t(errors.firstName.message ?? '')}
                    {errors.lastName &&
                      !errors.firstName &&
                      t(errors.lastName.message ?? '')}
                  </span>
                </div>
                <div className={classes.infoContainer}>
                  <Typography className={inputClasses.inputLabel}>
                    {t('ITel')}
                  </Typography>
                  <Typography className={classes.smallPadding} />
                  <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
                    <Controller
                      name='countryCode'
                      control={control}
                      defaultValue='KR'
                      render={({field}) => (
                        <SelectBoldCountryNumber {...field} />
                      )}
                    />
                    <Controller
                      name='phone'
                      control={control}
                      render={({field}) => (
                        <input
                          // error={!!errors.phone}
                          // helperText={errors.phone?.message}
                          placeholder={t('IPhone')}
                          className={inputClasses.input}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <span className={classes.errorText}>
                    {errors.phone && t(errors.phone.message ?? '')}
                  </span>
                </div>

                <div className={classes.infoContainer}>
                  <Typography className={inputClasses.inputLabel}>
                    {t('IBirthday')}
                  </Typography>
                  <Typography className={classes.smallPadding} />
                  <SelectBoldYear
                    onDateChange={(e) => handleBirthData(e)}
                    oldDate={new Date()}
                  />
                </div>

                <div className={classes.infoContainer}>
                  <Typography className={inputClasses.inputLabel}>
                    {t('IGender')}
                  </Typography>
                  <Typography className={classes.smallPadding} />

                  <Controller
                    name='gender'
                    control={control}
                    defaultValue={Gender.MALE}
                    render={({field}) => (
                      <RadioGroup
                        row
                        className={classes.radioContainer}
                        {...field}
                      >
                        {GenderSelectItems.map((gender) => (
                          <FormControlLabel
                            control={<Radio color='primary' />}
                            label={
                              <p style={{marginRight: '30px'}}>
                                {t(gender.label)}
                              </p>
                            }
                            value={gender.value}
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />
                </div>
                <div className={classes.infoContainer}>
                  <Typography className={inputClasses.inputLabel}>
                    {t('IFrequentlyUsedHands')}
                  </Typography>
                  <Typography className={classes.smallPadding} />

                  <Controller
                    name='hand'
                    control={control}
                    defaultValue={Hand.UNKNOWN}
                    render={({field}) => (
                      <RadioGroup
                        row
                        className={classes.radioContainer}
                        {...field}
                      >
                        {HandSelectItems.map((hand) => (
                          <FormControlLabel
                            control={<Radio color='primary' />}
                            label={
                              <p style={{marginRight: '30px'}}>
                                {t(hand.label)}
                              </p>
                            }
                            value={hand.value}
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />
                </div>

                <div className={classes.infoContainer}>
                  <Typography className={inputClasses.inputLabel}>
                    {t('IEmail')}
                  </Typography>
                  <Typography className={classes.smallPadding} />
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Controller
                      name='email'
                      control={control}
                      render={({field}) => (
                        <input
                          className={inputClasses.input}
                          placeholder={t('IEmailRequired')}
                          disabled={usedEmail}
                          autoComplete='off'
                          // error={!!errors.chartNo}
                          // helperText={errors.chartNo?.message}
                          {...field}
                        />
                      )}
                    />
                    <Typography className={classes.padding} />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={usedEmail}
                          onChange={() => {
                            setUsedEmail(!usedEmail)
                          }}
                          name={t('IEmail')}
                          color='primary'
                        />
                      }
                      label={
                        <Typography
                          style={{
                            color: '#5a6268',
                            fontSize: '17px',
                            paddingBottom: 3,
                          }}
                        >
                          {t('INoEmail')}
                        </Typography>
                      }
                    />
                  </div>
                </div>

                <div className={classes.infoContainer}>
                  <Typography className={inputClasses.inputLabel}>
                    {t('ICareDoctor')}
                  </Typography>
                  <Typography className={classes.smallPadding} />

                  <Controller
                    name='docNo'
                    control={control}
                    defaultValue={docNo}
                    render={({field}) => (
                      <SelectBoldDoctor error={!!errors.docNo} {...field} />
                    )}
                  />
                  <span className={classes.errorText}>
                    {errors.docNo && t(errors.docNo.message ?? '')}
                  </span>
                </div>

                {isHealthOrg && (
                  <CheckUpItems
                    control={control}
                    products={listProducts}
                    errors={errors}
                  />
                )}
                {/* <Controller
                name='terms'
                control={control}
                defaultValue='https://isyncme.s3.ap-northeast-2.amazonaws.com'
                render={({field}) => <TextField type='hidden' {...field} />}
              /> */}
                {/* 고객 동의 활용  */}
                {/* <p className={classes.privacyText}>
                * {t('IAgreeBeforeRegister')}
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Button className={classes.privacyButton}>
                  {t('IPrivacyUsage')}
                </Button>
              </div> */}
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                {t('INotYetImplemented')}
              </TabPanel>
              <Typography className={classes.smallPadding} />
            </DialogContent>
            {/* 하단 버튼 컨테이너  */}
            <Typography className={classes.smallPadding} />
            <div className={classes.buttonContainerBgColor}>
              <div className={classes.buttonContainer}>
                <Button
                  variant='contained'
                  color='default'
                  disableElevation
                  onClick={() => {
                    onNoticeDialogOpen({
                      title: '',
                      message: t('ICancleConfirm'),
                    }).then((result) => {
                      if (result.payload) {
                        handleCleanClose()
                      }
                    })
                  }}
                  className={classes.okButton}
                >
                  {t('ICancel')}
                </Button>
                <Typography className={classes.smallPadding} />
                <Button
                  variant='contained'
                  color='primary'
                  disableElevation
                  className={classes.okButtonColor}
                  type='submit'
                >
                  {t('IOk')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default PatientAddDialog
