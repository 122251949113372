import React, {useState, useEffect} from 'react'
import {useCreateDialog} from 'features/therapy/useTherapyDialog'
import CommonDialog from 'components/V2Dialog/Templates/CommonDialog/CommonDialog'
import {useTranslation} from 'react-i18next'
import {LedCareInfoModal} from 'components/V2Modal/Modal/Styles'
import {StyledButtonGroup} from 'components/V2Modal/Modal/Modal'
import NormalButton from 'components/V2Buttons/NormalButton/NormalButton'
import {
  DisabledButtonStyles,
  PrimaryButtonStyles,
  SecondaryButtonStyles,
} from 'components/V2Buttons/NormalButton/Styles'
import useTherapy from 'features/therapy/useTherapy'
import {useAppDispatch, useAppSelector} from 'hooks'
import {
  canPrescribe,
  GroupType,
  reInitializeState,
  selectGroups,
} from 'features/analysis/nirCareLedSlice'
import useFailureModal from 'features/modal/useFailureModal'
import Sidebar from './Sidebar'
import {
  FlexContainer,
  FlexItem,
  StyledBrainContainer,
  StyledBrainImgContainerDim,
  StyledBrainImgInwrapDim,
  StyledNoticeTextSpan,
  Text,
} from '../Style'
import MainContent from './MainContent'

import usePrescriptionViewModelV2 from './PrescriptionViewModel'
import useAuth from '../../../../features/auth/useAuth'
import therapyBrainDim from '../../../../assets/img/therapy_brain_fake.png'

interface TherapyId {
  therapyId: string
}

const TherapyPrescriptionDialog = ({therapyId}: TherapyId) => {
  const {user: currentUser} = useAuth()
  const Groups: GroupType[] = useAppSelector(selectGroups)
  const canPrescribePrescription: boolean = useAppSelector(canPrescribe)

  // const {customerId} = useTherapy()
  const {
    open,
    reservation: reservationList,
    onClose: closeHandler,
    uuid,
  } = useCreateDialog()

  const {onOpen: onOpenFailureModal} = useFailureModal()
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const {fetchGroupList, postPrescription} = usePrescriptionViewModelV2()

  const handleClose = () => {
    dispatch(reInitializeState({}))

    fetchGroupList(therapyId)
    closeHandler()
  }

  useEffect(() => {
    if (open) {
      fetchGroupList(therapyId)
    }
  }, [open])

  // eslint-disable-next-line consistent-return
  const handlePrescriptionClick = async () => {
    if (Groups.length === 0) {
      return onOpenFailureModal(t('IprescriptionEmpty'))
    }
    postPrescription(currentUser?.uid ?? '', therapyId, handleClose)
  }

  const isTherapyPreset = (): boolean => {
    if (therapyId != null) return true

    return false
  }

  const emptyTherapy = (
    <StyledBrainContainer>
      <StyledBrainImgContainerDim>
        <StyledBrainImgInwrapDim>
          <img src={therapyBrainDim} alt='therapy_brain_dim' />
          <StyledNoticeTextSpan>{t('INoTherapy')}</StyledNoticeTextSpan>
        </StyledBrainImgInwrapDim>
      </StyledBrainImgContainerDim>
    </StyledBrainContainer>
  )

  const body = (
    <FlexContainer height='470px'>
      <FlexItem flex={1} padding='0px 0px 10px 0px'>
        <Sidebar customerId={therapyId} />
      </FlexItem>
      <FlexItem flex={3} padding='20px' bgcolor='#F9F9F9'>
        <MainContent customerId={therapyId} />
      </FlexItem>
    </FlexContainer>
  )

  const footer = (
    <StyledButtonGroup>
      <NormalButton
        theme={{
          ...SecondaryButtonStyles,
          buttonBackgroundColor: '#FFF',
          buttonColor: '#4B4B4B',
        }}
        title={t('ICancel')}
        event={() => handleClose()}
        disabled={false}
      />
      <NormalButton
        theme={
          !canPrescribePrescription ? DisabledButtonStyles : PrimaryButtonStyles
        }
        title={t('IPrescription')}
        event={handlePrescriptionClick}
        submitForm='therapyEdit'
        disabled={!canPrescribePrescription}
      />
    </StyledButtonGroup>
  )

  return (
    <>
      <CommonDialog
        open={open}
        modalTitle={t('ITherapyReservationTitle')}
        handleClose={handleClose}
        theme={LedCareInfoModal}
        body={body}
        footer={footer}
        style={{
          opacity: !open ? 0 : 1,
          userSelect: !open ? 'none' : 'auto',
        }}
      />
    </>
  )
}

export default TherapyPrescriptionDialog
