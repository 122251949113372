import {
  AnalysisDivision,
  CodeType,
  CodeTypeMap,
  ReportType,
} from 'constants/AnalysisConstant'
import i18n from '../i18n'

export const isEEGNorm = (
  codeTypeDivision: AnalysisDivision,
  codeType: CodeType,
) => codeTypeDivision === 'EEG' && codeType === CodeTypeMap.Normative

export const availableStatusToI18nString = (
  status: 'SUCCESS' | 'FAIL' | 'PAIR',
) => {
  switch (status) {
    case 'SUCCESS':
      return 'IStatus_success'
    case 'FAIL':
      return 'IStatus_failure'
    case 'PAIR':
      return 'IStatus_failure'
    default:
      return 'IUnknown'
  }
}

export const statusToI18nString = (status: AnalysisStatus): string => {
  switch (status) {
    case '-':
      return '-'
    case 'READY':
      return 'IStatus_ready'
    case 'PROCESSING':
      return 'IStatus_processing'
    case 'SUCCESS':
      return 'IStatus_success'
    case 'ERROR':
      return 'IStatus_failure'
    case 'LACK_OF_MONEY':
      return 'IStatus_lackOfMoney'
    case 'ICA':
      return 'IStatus_ica'
    case 'TIME_REJECTION':
      return 'IStatus_tsr'
    case 'PARTIALLY_COMPLETED':
      return 'IStatus_PartiallyCompleted'
    default:
      return '-'
  }
}

export const isReadable = (status: AnalysisStatus) =>
  status === 'SUCCESS' || status === 'ICA' || status === 'TIME_REJECTION'

export const getEyeStatus = (status: string) => {
  switch (status) {
    case 'EO':
      return i18n.t('IEO')
    case 'EC':
      return i18n.t('IEC')
    case 'Eyes Open':
      return i18n.t('IEO')
    case 'Eyes Closed':
      return i18n.t('IEC')
    default:
      return '-'
  }
}

export const getTextHRVPremium = (reportType: ReportType) => {
  switch (reportType) {
    case 'P1':
      return '-'
    case 'P2':
      return i18n.t('IStatus_success')
    default:
      return '-'
  }
}

export const getValidValue = (data: any[]) => {
  const dataValid = data.filter(
    // eslint-disable-next-line eqeqeq
    (item) => item && (item != undefined || item != null),
  )
  const set = new Set(dataValid)
  return Array.from(set)
}

export const compareTwoArray = (arr1: string[], arr2: string[]) => {
  const sortedArr1 = arr1.sort()
  const sortedArr2 = arr2.sort()
  return (
    sortedArr1.length === sortedArr2.length &&
    sortedArr1.every((value, index) => value === sortedArr2[index])
  )
}
